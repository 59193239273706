/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instruction-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./instruction-page.component";
var styles_InstructionPageComponent = [i0.styles];
var RenderType_InstructionPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstructionPageComponent, data: {} });
export { RenderType_InstructionPageComponent as RenderType_InstructionPageComponent };
function View_InstructionPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["aria-live", "assertive"], ["class", "error"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "icon-sm"], ["height", "16"], ["viewBox", "0 0 16 16"], ["width", "16"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,12c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1 S8.6,12,8,12z M9,9H7V4h2V9z"], ["fill", "currentColor"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errortext; _ck(_v, 3, 0, currVal_0); }); }
export function View_InstructionPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstructionPageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "step-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " ", " ", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 4, "p", [["class", "sensory-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "abbr", [["class", "indicator"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["*"])), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errortext; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("SIDEBAR.instructions")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("INSTRUCTION_PAGE.step")); var currVal_3 = _co.total_pages; var currVal_4 = i1.ɵunv(_v, 7, 2, i1.ɵnov(_v, 9).transform("SIDEBAR.get_started")); _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("COMMON_WORDS.sensory_information")); _ck(_v, 13, 0, currVal_5); }); }
export function View_InstructionPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-instruction-page", [], null, null, null, View_InstructionPageComponent_0, RenderType_InstructionPageComponent)), i1.ɵdid(1, 114688, null, 0, i4.InstructionPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstructionPageComponentNgFactory = i1.ɵccf("app-instruction-page", i4.InstructionPageComponent, View_InstructionPageComponent_Host_0, { errortext: "errortext", total_pages: "total_pages" }, {}, []);
export { InstructionPageComponentNgFactory as InstructionPageComponentNgFactory };
