import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
  NgForm,
  FormBuilder
} from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ErrorStateMatcher, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {

  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.scss']
})
export class FollowUpComponent implements OnInit {
  allMonitorIds: any = [];
  dateToday = new Date();
  params: any;
  followupForm: FormGroup;
  Questions = [];
  questionLabels = {
    symptomsStart: 'What day did your symptoms start?',
    antiFeverMedicine: 'Did you take any anti-fever medicine?',
    medicines: 'What medication(s)?',
    symptomSevere: 'Are your symptoms severe?',
    healthCareFacility: 'Did you visit a healthcare facility?',
    typeOfhealthCareFacility: 'Type of healthcare facility',
    otherhealthCareFacility: 'Other',
    facilityName: 'Facility Name',
    lastVisitDate: 'Last visit date',
    sheltingHome: 'Do you live in a group setting, such as a shelter or nursing home?',
    nameOfSheltingHome: 'Name of group setting:',
    addressOfSheltingHome: 'Address of group setting:',
    typeOfResidence: 'In what type of residence do you live?',
    otherResidence: 'Other type of residence:',
    selfIsolating: 'Are you currently self-isolating?',
    whereIsolating: 'Where?',
    otherIsolating: 'Other',
    otherPeopleInHousehold: 'If home, do other people live in your household?',
    howManyPeopleInHousehold: 'If yes, how many?',
    tested: 'Have you been tested for COVID-19?',
    testType: 'What type of test?',
    pcrTest: 'PCR test results',
    pcrResultDate: 'PCR result date',
    igmTest: 'IgM test results',
    igmResultDate: 'IgM result date',
    iggTest: 'IgG test results',
    iggResultDate: 'IgG result date',
  }

  @Input('userLang') userLang = 'en';

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private snackbar: MatSnackBar,
    private router: Router,
    private translate: TranslateService
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = _.get(params, 'p');
      if (!this.params) {
        this.router.navigateByUrl('/');
      }
    });
    this.followupForm = this.formBuilder.group({});
  }

  feverMed: any = '';

  @ViewChild('temperature', { static: false }) temperature: any;
  typeofTemp: any = '';

  submitted: boolean = false;
  isFormSubmitted: boolean = false;
  hasErrorRecords: boolean = false;
  hasErrorRecordswithTemp: boolean = false;
  matcher = new MyErrorStateMatcher();
  temperatureNumbers = '([0-9]*[.])?[0-9]+';
  apiLoading = false;
  ngOnInit() {
    this.followupForm.addControl('fttemperature',
      new FormControl('', [
        //Validators.required,
        Validators.min(90.0),
        Validators.max(110.0),
        Validators.pattern(this.temperatureNumbers)
      ])
    );
    this.followupForm.addControl('typeofTemp', new FormControl('', Validators.required));
    this.followupForm.addControl('symptomsStart', new FormControl('', Validators.required));
    this.followupForm.addControl('antiFeverMedicine', new FormControl(''));
    this.followupForm.addControl('medicines', new FormControl(''));
    this.followupForm.addControl('symptomSevere', new FormControl(''));
    this.followupForm.addControl('healthCareFacility', new FormControl(''));
    this.followupForm.addControl('typeOfhealthCareFacility', new FormControl(''));
    this.followupForm.addControl('otherhealthCareFacility', new FormControl(''));
    this.followupForm.addControl('facilityName', new FormControl(''));
    this.followupForm.addControl('lastVisitDate', new FormControl(''));
    this.followupForm.addControl('sheltingHome', new FormControl(''));
    this.followupForm.addControl('nameOfSheltingHome', new FormControl(''));
    this.followupForm.addControl('addressOfSheltingHome', new FormControl(''));
    this.followupForm.addControl('typeOfResidence', new FormControl(''));
    this.followupForm.addControl('otherResidence', new FormControl(''));
    this.followupForm.addControl('selfIsolating', new FormControl(''));
    this.followupForm.addControl('whereIsolating', new FormControl(''));
    this.followupForm.addControl('otherIsolating', new FormControl(''));
    this.followupForm.addControl('otherPeopleInHousehold', new FormControl(''));
    this.followupForm.addControl('howManyPeopleInHousehold', new FormControl(''));
    this.followupForm.addControl('tested', new FormControl(''));
    this.followupForm.addControl('testType', new FormControl(''));
    this.followupForm.addControl('pcrTest', new FormControl(''));
    this.followupForm.addControl('pcrResultDate', new FormControl(''));
    this.followupForm.addControl('igmTest', new FormControl(''));
    this.followupForm.addControl('igmResultDate', new FormControl(''));
    this.followupForm.addControl('iggTest', new FormControl(''));
    this.followupForm.addControl('iggResultDate', new FormControl(''));
    this.followupForm.addControl('selectedMonitoringId', new FormControl(''));
    this.followupForm.controls.typeofTemp.setValue(this.typeofTemp);
    this.translate.use(this.userLang);
  }

  ngOnChanges(change) {
    if(change.userLang && change.currentValue !== change.previousValue) {
      this.translate.use(change.userLang)
    }
  }

  get f() {
    return null;
  }
  
  getFormValue(formcontrolName) {
    return this.followupForm.value[formcontrolName];
  }

  removeTep(event) {
    console.log('change');
    this.typeofTemp = event.value;
    if (this.typeofTemp == 'yes') {
      this.followupForm.addControl(
        'cstemperature',
        new FormControl('', [
          //Validators.required,
          Validators.min(32.0),
          Validators.max(44.0),
          Validators.pattern(this.temperatureNumbers)
        ])
      );
      this.followupForm.controls['fttemperature'].reset();
      this.followupForm.controls['fttemperature'].clearValidators();
      this.followupForm.removeControl('fttemperature');
    } else {
      this.followupForm.addControl(
        'fttemperature',
        new FormControl('', [
          //Validators.required,
          Validators.min(90.0),
          Validators.max(110.0),
          Validators.pattern(this.temperatureNumbers)
        ])
      );
      this.followupForm.controls['cstemperature'].reset();
      this.followupForm.controls['cstemperature'].clearValidators();
      this.followupForm.removeControl('cstemperature');
    }
  }

  submitFollowUp() {
    // alert(this.followupForm.get('selectedMonitoringId').value)
    // if(this.followupForm.get('selectedMonitoringId').value){
    //   if(this.followupForm.valid) {
    //     var test;
    //     if (this.followupForm.get('typeofTemp').value === 'yes') {
    //       test = (this.followupForm.get('cstemperature').value * 1.8 + 32).toFixed(
    //         2
    //       );
    //     } else {
    //       test = this.followupForm.get('fttemperature').value;
    //     }
    //     let formValue = this.followupForm.value;
    //     let responses = [];
    //     responses.push({
    //       question:
    //         "If you had fever, what's the highest temperature recorded with a thermometer? - Fahrenheit and Celsius - Temperature",
    //       answer: test + ' *F'
    //     });
    //     for (const key in formValue) {
    //       if (formValue.hasOwnProperty(key)) {
    //         let element = formValue[key];        
    //         if(element!= '' && this.questionLabels[key]) {
    //           if(typeof element == 'object') {
    //             element = `${element.getMonth() + 1}/${element.getDate()}/${element.getFullYear()}`;
    //           }
    //           responses.push({
    //             question: this.questionLabels[key],
    //             answer: element
    //           })
    //         }
    //       }
    //     }
    //     this.Questions = [{
    //       responses: responses
    //     }];
    //     if(responses.length != 0) {
    //       this.apiLoading = true;
    //       this.apiService.updatenewFollowUp(this.params, this.Questions).subscribe(
    //         (data: any) => {
    //           this.apiLoading = false;
    //           this.submitted = true;
    //           this.isFormSubmitted = false;
    //         },
    //         error => {
    //           this.apiLoading = false;
    //           console.log('error occured while sending the data');
    //         }
    //       );
    //     }
    //     console.log(this.Questions);
    //   } else {
    //     //this.snackbar.open("Temparature and symtom start date is required");
    //     if(!(this.followupForm.get('symptomsStart').valid)){
    //       this.snackbar.open(this.translate.instant('FOLLOW_UP.symtom_date_error'));        
    //     }else{
    //       this.snackbar.open(this.translate.instant('FOLLOW_UP.invalid_tmp'));
    //     }
    //   }        
    // }else{
    //   this.snackbar.open(this.translate.instant('FOLLOW_UP.name_error'));
    // }



  }

  public hasError = (controlName: string, errorName: string) => {
    return this.followupForm.controls[controlName].hasError(errorName);
  };
  public getFormData = () => {
    // return this.followupForm.value;

    // if(this.followupForm.valid) {
    let temp;
    if (this.followupForm.get('typeofTemp').value === 'yes') {
      temp = (this.followupForm.get('cstemperature').value * 1.8 + 32).toFixed(
        2
      );
    } else {
      temp = this.followupForm.get('fttemperature').value;
    }
    let formValue = this.followupForm.value;
    let responses = [];
    if(temp) {
      responses.push({
        question:
          "If you had fever, what's the highest temperature recorded with a thermometer? - Fahrenheit and Celsius - Temperature",
        answer: temp + ' *F'
      });
    }
    
    for (const key in formValue) {
      if (formValue.hasOwnProperty(key)) {
        let element = formValue[key];
        if (element != '' && this.questionLabels[key]) {
          if (typeof element == 'object') {
            element = `${element.getMonth() + 1}/${element.getDate()}/${element.getFullYear()}`;
          }
          responses.push({
            question: this.questionLabels[key],
            answer: element
          })
        }
      }
    }
    this.Questions = [{
      responses: responses
    }];
    // }
    return this.Questions;

  }
  public isFormValid = () => {
    return this.followupForm.valid;
  }
}
