import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';

const SIMPLE_DATE_FORMAT = 'MM/DD/YYYY';
const SIMPLE_TIME_FORMAT = 'hh:mm A';
const SIMPLE_DATETIME_FORMAT = 'MM/DD/YYYY hh:mm A';
const SHORT_DATETIME_FORMAT = 'MM/DD hh:mm A';
const MESSAGE_DATETIME_FORMAT = 'MMM DD hh:mm A';
const SQL_DATE = 'YYYY-MM-DD';
const TIMEZONE = 'America/New_York';

export interface firestoreBaseDate {
  _seconds?: number;
  _nanoseconds?: number;
  seconds?: number;
  nanoseconds?: number;
}

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
const MY_DATE_FORMATS = {
  parse: {
    dateInput: SIMPLE_DATE_FORMAT
  },
  display: {
    dateInput: SIMPLE_DATE_FORMAT,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const MOMENT_INPUT_DATE_PROVIDERS = [
  // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
  // `MatMomentDateModule` in your applications root module. We provide it at the component level
  // here, due to limitations of our example generation script.
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE]
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
];

const GUARDIAN_TYPES = [
  {
    value: 'parent',
    text: 'Parent'
  },
  {
    value: 'guardian',
    text: 'Guardian'
  },
  {
    value: 'others',
    text: 'Other'
  }
];

const TRANSPORT_STATUS_TEMP = [
  { value: 'Coordinating', timestamp: '' },
  { value: 'Transport Scheduled', timestamp: '' },
  { value: 'In Transport', timestamp: '' },
  { value: 'Transport Completed', timestamp: '' },
  { value: 'In Testing', timestamp: '' },
  { value: 'Result Completed', timestamp: '' }
];

export {
  MY_DATE_FORMATS,
  SIMPLE_DATE_FORMAT,
  SIMPLE_TIME_FORMAT,
  SIMPLE_DATETIME_FORMAT,
  SHORT_DATETIME_FORMAT,
  MESSAGE_DATETIME_FORMAT,
  TIMEZONE,
  MOMENT_INPUT_DATE_PROVIDERS,
  GUARDIAN_TYPES,
  TRANSPORT_STATUS_TEMP,
  SQL_DATE
};
