<form [formGroup]="guardianForm" (ngSubmit)="save();">

  <div mat-dialog-content>

    <h1 class="m-t-0 dialog-title">{{'GUARDIAN_DIALOG.add_guardian' | translate}}</h1>
    <p class="sensory-info"><abbr class="indicator">*</abbr> {{'COMMON_WORDS.sensory_information' |translate}} </p>

    <mat-form-field appearance="outline">
      <mat-label>{{'GUARDIAN_DIALOG.guardian_type' | translate}}</mat-label>
      <mat-select class="full" #type formControlName="type" required>
        <mat-option value="Parent">{{'GUARDIAN_DIALOG.parent' | translate}}</mat-option>
        <mat-option value="Guardian">{{'GUARDIAN_DIALOG.guardian' | translate}}</mat-option>
        <mat-option value="Other">{{'GUARDIAN_DIALOG.other' | translate}}</mat-option>
      </mat-select>
      <mat-error *ngIf="hasError('type', 'required')">{{'GUARDIAN_DIALOG.type_req' | translate}}</mat-error>
    </mat-form-field>

    <div class="row txt-small">
      <div class="col-sm-6">
        <mat-form-field appearance="outline">
          <mat-label>{{'GUARDIAN_DIALOG.first_name' | translate}}</mat-label>
          <input matInput placeholder="First name" #firstname formControlName="firstname" required>
          <mat-error *ngIf="hasError('firstname', 'required')">{{'GUARDIAN_DIALOG.first_name_req' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field appearance="outline">
          <mat-label>{{'GUARDIAN_DIALOG.last_name' | translate}}</mat-label>
          <input matInput placeholder="Last name" #lastname formControlName="lastname" required>
          <mat-error *ngIf="hasError('lastname', 'required')">{{'GUARDIAN_DIALOG.last_name_req' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <mat-form-field appearance="outline">
          <mat-label>{{'GUARDIAN_DIALOG.ext' | translate}}</mat-label>
          <mat-select id="primaryCellNumberext" formControlName="primaryCellNumberext">
            <mat-option *ngFor="let extension of countrycodes" [value]="extension">
              {{extension}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-9">
        <mat-form-field class="full" appearance="outline">
          <mat-label>{{'GUARDIAN_DIALOG.primary_mobile' | translate}}</mat-label>
          <input matInput placeholder="Primary mobile number" #primaryCellNumber formControlName="primaryCellNumber" maxlength="10" required>
          <mat-error *ngIf="hasError('primaryCellNumber', 'required')">{{'GUARDIAN_DIALOG.phone_req' | translate}}</mat-error>
          <mat-error *ngIf="hasError('primaryCellNumber', 'invalidNumber')">{{'GUARDIAN_DIALOG.only_num' | translate}}</mat-error>
          <mat-error *ngIf="hasError('primaryCellNumber', 'maxlength')">{{'GUARDIAN_DIALOG.max_char' | translate}}
          </mat-error>
          <!--
          <div *ngIf="primaryCellNumber.errors?.maxlength">
            Max of 15 characters allowed.
          </div>
          -->
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <mat-form-field appearance="outline">
          <mat-label>{{'GUARDIAN_DIALOG.ext' | translate}}</mat-label>
          <mat-select formControlName="landlineNumberext" id="landlineNumberext">
            <mat-option *ngFor="let extension of countrycodes" [value]="extension">
              {{extension}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-9">
        <mat-form-field class="full" appearance="outline">
          <mat-label>{{'GUARDIAN_DIALOG.landline_number' | translate}}</mat-label>
          <input matInput placeholder="Landline number" #landlineNumber formControlName="landlineNumber" maxlength="10">
          <mat-error *ngIf="hasError('landlineNumber', 'invalidNumber')">{{'GUARDIAN_DIALOG.only_num' | translate}}</mat-error>
          <mat-error *ngIf="hasError('landlineNumber', 'maxlength')">{{'GUARDIAN_DIALOG.max_char' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>

  <div class="footer-controls">
    <button mat-button (click)="onNoClick()">{{'COMMON_WORDS.cancel' | translate}}</button>
    <button mat-raised-button class="button-style" type="submit">{{'COMMON_WORDS.save' | translate}}</button>
  </div>

</form>
