import { moment } from 'src/app/common/timeutils';
import { FormControl, AbstractControl } from '@angular/forms';

const setPageTitle = (title: string, page: string = 'Intake Form') => {
  window.document.title = `${title} | ${page} ERM`;
};

// common standard date conversion for the entire codebase to save to one date format only
const getStandardDate = (date: any, format?: string) => {
  if (!!format) {
    return moment(date, format).utc().toDate();
  }

  return moment(date).utc().toDate();
};
const noWhitespaceValidator = (control: FormControl) => {
  let formvalue;
  if (control.value == null || control.value == undefined) {
    formvalue = '';
  } else {
    formvalue = control.value;
  }
  const isWhitespace = (formvalue || '').toString().trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
};
const countrycodes = [
  '+1',
  '+44',
  '+213',
  '+376',
  '+244',
  '+1264',
  '+1268',
  '+54',
  '+374',
  '+297',
  '+61',
  '+43',
  '+994',
  '+1242',
  '+973',
  '+880',
  '+1246',
  '+375',
  '+32',
  '+501',
  '+229',
  '+1441',
  '+975',
  '+591',
  '+387',
  '+267',
  '+55',
  '+673',
  '+359',
  '+226',
  '+257',
  '+855',
  '+237',
  '+1',
  '+238',
  '+1345',
  '+236',
  '+56',
  '+86',
  '+57',
  '+269',
  '+242',
  '+682',
  '+506',
  '+385',
  '+53',
  '+90',
  '+357',
  '+420',
  '+45',
  '+253',
  '+1809',
  '+1809',
  '+593',
  '+20',
  '+503',
  '+240',
  '+291',
  '+372',
  '+251',
  '+500',
  '+298',
  '+679',
  '+358',
  '+33',
  '+594',
  '+689',
  '+241',
  '+220',
  '+7880',
  '+49',
  '+233',
  '+350',
  '+30',
  '+299',
  '+1473',
  '+590',
  '+671',
  '+502',
  '+224',
  '+245',
  '+592',
  '+509',
  '+504',
  '+852',
  '+36',
  '+354',
  '+91',
  '+62',
  '+964',
  '+98',
  '+353',
  '+972',
  '+39',
  '+1876',
  '+81',
  '+962',
  '+7',
  '+254',
  '+686',
  '+850',
  '+82',
  '+965',
  '+996',
  '+856',
  '+371',
  '+961',
  '+266',
  '+231',
  '+218',
  '+417',
  '+370',
  '+352',
  '+853',
  '+389',
  '+261',
  '+265',
  '+60',
  '+960',
  '+223',
  '+356',
  '+692',
  '+596',
  '+222',
  '+269',
  '+52',
  '+691',
  '+373',
  '+377',
  '+976',
  '+1664',
  '+212',
  '+258',
  '+95',
  '+264',
  '+674',
  '+977',
  '+31',
  '+687',
  '+64',
  '+505',
  '+227',
  '+234',
  '+683',
  '+672',
  '+670',
  '+47',
  '+968',
  '+92',
  '+680',
  '+507',
  '+675',
  '+595',
  '+51',
  '+63',
  '+48',
  '+351',
  '+1787',
  '+974',
  '+262',
  '+40',
  '+7',
  '+250',
  '+378',
  '+239',
  '+966',
  '+221',
  '+381',
  '+248',
  '+232',
  '+65',
  '+421',
  '+386',
  '+677',
  '+252',
  '+27',
  '+34',
  '+94',
  '+290',
  '+1869',
  '+1758',
  '+597',
  '+249',
  '+268',
  '+46',
  '+41',
  '+963',
  '+886',
  '+992',
  '+66',
  '+228',
  '+676',
  '+1868',
  '+216',
  '+90',
  '+993',
  '+1649',
  '+688',
  '+256',
  '+380',
  '+971',
  '+598',
  '+998',
  '+678',
  '+379',
  '+58',
  '+84',
  '+1',
  '+1',
  '+681',
  '+969',
  '+967',
  '+260',
  '+263'
];
const removeSpacesEnforcer = (control: AbstractControl) => {
  const REGEXP = /^\s/g;

  // removes empty spaces from any string, anywhere, anytime
  if (control && control.value && REGEXP.test(control.value)) {
    let newValue = control.value;
    newValue = newValue.replace(REGEXP, '');
    control.setValue(newValue);
  }
  return null;
};

export {
  setPageTitle,
  getStandardDate,
  countrycodes,
  noWhitespaceValidator,
  removeSpacesEnforcer
};
