<div class="error" *ngIf="errortext" aria-live="assertive" role="alert">
    <svg class="icon-sm" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="currentColor" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,12c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1 S8.6,12,8,12z M9,9H7V4h2V9z"/>
  </svg> {{errortext}}
</div>

<div class="card-header">
    <h2 class="card-title">{{'SIDEBAR.instructions' | translate}} </h2>
    <div class="step-count">{{'INSTRUCTION_PAGE.step' | translate}} {{total_pages}} {{'SIDEBAR.get_started' | translate}}</div>
    <p class="sensory-info"><abbr class="indicator">*</abbr> {{'COMMON_WORDS.sensory_information' |translate}} </p>
</div>
