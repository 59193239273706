<mat-card class="guardian-card">
    <mat-card-header>
        <mat-card-title class="thin-weight capitalize">{{guardian.type}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-auto p-b">
                <div class="card-label">{{'GUARDIAN_INFO.first_name' | translate}}</div>
                <div class="card-para">{{guardian.firstName}}</div>
            </div>
            <div class="col-auto p-b">
                <div class="card-label">{{'GUARDIAN_INFO.last_name' | translate}}</div>
                <div class="card-para">{{guardian.lastName}}</div>
            </div>
            <div class="col-auto p-b">
                <div class="card-label">{{'GUARDIAN_INFO.phone_number' | translate}}</div>
                <div class="card-para">{{guardian.primaryCellNumber}}</div>
            </div>
            <div class="col-auto p-b">
                <div class="card-label">{{'GUARDIAN_INFO.alternate_number' | translate}}</div>
                <div class="card-para">{{guardian.landlineNumber}}</div>
            </div>
            <div class="card-controls">
                <button mat-button (click)="openConfirmationDialog()">
                    <mat-icon aria-hidden="false" attr.aria-label="{{guardian.firstName}} {{guardian.lastName}} {{guardian.type}} {{'COMMON_WORDS.delete_outline' | translate}}">delete_outline</mat-icon>
                </button>
                <button mat-button (click)="editGuardian()">
                    <mat-icon aria-hidden="false" attr.aria-label="{{guardian.firstName}} {{guardian.lastName}} {{guardian.type}} {{'COMMON_WORDS.edit' | translate}}">edit</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-content>

</mat-card>