import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDailogComponent } from 'src/app/components/confirmation-dailog/confirmation-dailog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-guardian-card',
  templateUrl: './guardian-card.component.html',
  styleUrls: ['./guardian-card.component.scss']
})
export class GuardianCardComponent implements OnInit {
  @Input() guardian;

  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  constructor(public dialog: MatDialog, private translate: TranslateService) {}

  editGuardian = () => {
    this.onEdit.emit(this.guardian);
  }

  deleteGuardian = () => {
    this.onDelete.emit(this.guardian);
  }

  ngOnInit() {}

  getGuardianType(type: string) {
    switch (type) {
      case 'Guardian':
        return this.translate.instant('GUARDIAN_INFO.guardian');
        break;  
      case 'Parent':
        return this.translate.instant('GUARDIAN_INFO.parent');
        break;  
      case 'Other':
        return this.translate.instant('GUARDIAN_INFO.other');
        break;  
      default:
        return this.translate.instant('GUARDIAN_INFO.guardian');
        break;
    }
  }

  openConfirmationDialog(): void {
    const body = this.translate.instant('GUARDIAN_INFO.confirm_delete') + ' ' +
                 this.getGuardianType(this.guardian.type) + ' ' +
                 this.guardian.firstName + ' ' +
                 this.guardian.lastName + ' ?'
    
    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      width: '90vw',
      data: { title: this.translate.instant('ADDRESS_COMMON.confirmation'),
              body: body,
              buttons: {
                yes: this.translate.instant('ADDRESS_COMMON.yes'),
                no: this.translate.instant('ADDRESS_COMMON.no')
              } }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.isConfirm) {
        this.deleteGuardian();
      }
    });
  }
}
