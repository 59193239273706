import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDailogComponent } from 'src/app/components/confirmation-dailog/confirmation-dailog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent implements OnInit {
  @Input() address;
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();


  constructor(public dialog: MatDialog, private translate: TranslateService) {}

  editAddress = () => {
    this.onEdit.emit(this.address);
  }

  deleteAddress = () => {
    this.onDelete.emit(this.address);
  }

  ngOnInit() {}

  openConfirmationDialog(): void {
    const body = this.address.type === 'current' ? this.translate.instant('ADDRESS_COMMON.confirm_current'): this.translate.instant('ADDRESS_COMMON.confirm_perm'); 
    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      width: '90vw',
      data: { title: this.translate.instant('ADDRESS_COMMON.confirmation'),
              body: body,
              buttons: {
                yes: this.translate.instant('ADDRESS_COMMON.yes'),
                no: this.translate.instant('ADDRESS_COMMON.no')
              } }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.isConfirm) {
        this.deleteAddress();
      }
    });
  }
}
