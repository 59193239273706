import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
  NgForm,
  FormBuilder
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { moment } from 'src/app/common/timeutils';
import firebase from 'firebase/app';
import '@firebase/firestore';
import * as _ from 'lodash';
import { fieldsOrder } from './helpers/form-fileds-order';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-symptoms-information',
  templateUrl: './symptoms-information.component.html',
  styleUrls: ['./symptoms-information.component.scss']
})
export class SymptomsInformationComponent implements OnInit {
  @Input() errortext: any;
  formClass = 'notsubmittedform';
  matcher = new MyErrorStateMatcher();
  @Input() total_pages;
  temperatureNumbers = '([0-9]*[.])?[0-9]+';
  anySymptomYes = false;
  typeofTemp: any = '';

  errortext1 = "";
  errortext2 = "";
  errortext3 = "";
  errortext4 = "";
  errortext5 = "";
  errortext6 = "";
  errortext7 = "";
  errortext8 = "";
  errortext9 = "";
  errortext10 = "";
  errortext11 = "";
  errortext12 = "";
  errortext13 = "";
  errortext14 = "";
  errortext15 = "";
  errortext16 = "";
  errortext17 = "";
  errortext18 = "";
  errortext19 = "";
  errortext20 = "";
  errortext21 = "";
  errortext22 = "";
  errortext23 = "";

  errortext24 = "";
  errortext25 = "";
  errortext26 = "";
  errortext27 = "";
  errortext28 = "";
  errortext29 = "";
  errortext30 = "";
  errortext31 = "";
  errortext32 = "";
  errortext33 = "";
  errortext34 = "";
  errortext35 = "";
  errortextNewFields = "";
  newFieldError = [];
  preConditionNewFields = [
    {key:'cancer',required:true},
    {key:'chronic_kidney',required:true},
    {key:'pulmonary_disease',required:true},
    {key:'heart_conditions',required:true},
    {key:'solid_organ_transplant',required:true},    
    {key:'cell_disease',required:true},
    {key:'type_2_diabetes',required:true},
    {key:'pregnancy',required:true},
    {key:'down_syndrome',required:true},
    {key:'asthma',required:true},
    {key:'cerebrovascular_disease',required:true},
    {key:'cystic_fibrosis',required:true},
    {key:'hypertension',required:true},
    {key:'immunocompromised_state',required:true},
    {key:'neurologic_condition',required:true},
    {key:'overweight',required:true},
    {key:'pulmonary_fibrosis',required:true},
    {key:'thalassemia',required:true},
    {key:'type_1_diabetes',required:true},
  ]


  isAnswerYes(val) {
    if (val == 'yes' || val == 'true') {
      return true;
    } else {
      false;
    }
  }
  isAnswerNull(val) {
    if (val) {
      return false;
    } else {
      return true;
    }
  }
  checkError(event) {
    this.newFieldError = [];
    const formValue = this.form.value;
    if (
      this.isAnswerNull(formValue.fever) ||
      this.isAnswerNull(formValue.chills) ||
      this.isAnswerNull(formValue.cough) ||
      // this.isAnswerNull(formValue.feverish) ||
      this.isAnswerNull(formValue.runnyNose) ||
      this.isAnswerNull(formValue.breath) ||
      this.isAnswerNull(formValue.sore) ||
      this.isAnswerNull(formValue.headache) ||
      this.isAnswerNull(formValue.muscleAche) ||
      this.isAnswerNull(formValue.abdominalPain) ||
      this.isAnswerNull(formValue.vomiting) ||
      this.isAnswerNull(formValue.diarrhea) ||
      this.isAnswerNull(formValue.contactWithSuspected) ||
      this.isAnswerNull(formValue.contact_with_confirmed_case) ||
      this.isAnswerNull(formValue.lungDisease) ||
      this.isAnswerNull(formValue.diabetes) ||
      this.isAnswerNull(formValue.heartDisease) ||
      // this.isAnswerNull(formValue.hypertension) ||
      this.isAnswerNull(formValue.kidneyDisease) ||
      this.isAnswerNull(formValue.liverDisease) ||
      this.isAnswerNull(formValue.weekImmuneSystem) ||
      this.isAnswerNull(formValue.obesity) ||
      this.isAnswerNull(formValue.highBloodPressure) ||
      this.isAnswerNull(formValue.smoking) ||

      this.isAnswerNull(formValue.Wheezing) ||
      this.isAnswerNull(formValue.sinusCongestion) ||
      this.isAnswerNull(formValue.chestPain) ||
      this.isAnswerNull(formValue.New_loss_of_appetite) ||
      this.isAnswerNull(formValue.New_loss_of_smell) ||
      this.isAnswerNull(formValue.New_loss_of_taste) ||
      this.isAnswerNull(formValue.Fatigue) ||
      this.isAnswerNull(formValue.Rigors) ||
      this.isAnswerNull(formValue.Other) ||
      this.isAnswerNull(formValue.cancer) ||
      this.isAnswerNull(formValue.chronic_kidney) ||
      this.isAnswerNull(formValue.pulmonary_disease) ||
      this.isAnswerNull(formValue.heart_conditions) ||
      this.isAnswerNull(formValue.solid_organ_transplant) ||
      this.isAnswerNull(formValue.cell_disease) ||
      this.isAnswerNull(formValue.type_2_diabetes) ||
      this.isAnswerNull(formValue.pregnancy) ||
      this.isAnswerNull(formValue.down_syndrome) ||
      this.isAnswerNull(formValue.asthma) ||
      this.isAnswerNull(formValue.cerebrovascular_disease) ||
      this.isAnswerNull(formValue.cystic_fibrosis) ||
      this.isAnswerNull(formValue.hypertension) ||
      this.isAnswerNull(formValue.immunocompromised_state) ||
      this.isAnswerNull(formValue.neurologic_condition) ||
      this.isAnswerNull(formValue.overweight) ||
      this.isAnswerNull(formValue.pulmonary_fibrosis) ||
      this.isAnswerNull(formValue.thalassemia) ||
      this.isAnswerNull(formValue.type_1_diabetes) ||
      (this.isAnswerYes(this.form.get('Other').value) &&  this.form.get('Other_Symptoms').value==="")     
    ) {
      this.errortext23 = 'SYMPTOMS_ERROR.please_fill';
      this.resetErrors();
      if (!this.form.value.register_vaccine) {
        if (this.isAnswerNull(formValue.fever))
          this.errortext1 = 'SYMPTOMS_ERROR.please_fill_fever';
        if (this.isAnswerNull(formValue.chills))
          this.errortext2 = 'SYMPTOMS_ERROR.please_fill_chills';
        if (this.isAnswerNull(formValue.cough))
          this.errortext3 = 'SYMPTOMS_ERROR.please_fill_cough';
        if (this.isAnswerNull(formValue.runnyNose))
          this.errortext4 = 'SYMPTOMS_ERROR.please_fill_runny_nose';
        if (this.isAnswerNull(formValue.breath))
          this.errortext5 = 'SYMPTOMS_ERROR.please_fill_breathing';
        if (this.isAnswerNull(formValue.sore))
          this.errortext6 = 'SYMPTOMS_ERROR.please_fill_throat';
        if (this.isAnswerNull(formValue.headache))
          this.errortext7 = 'SYMPTOMS_ERROR.please_fill_headache';
        if (this.isAnswerNull(formValue.muscleAche))
          this.errortext8 = 'SYMPTOMS_ERROR.please_fill_muscle_ache';
        if (this.isAnswerNull(formValue.abdominalPain))
          this.errortext9 = 'SYMPTOMS_ERROR.please_fill_pain';
        if (this.isAnswerNull(formValue.vomiting))
          this.errortext10 = 'SYMPTOMS_ERROR.please_fill_vomiting';
        if (this.isAnswerNull(formValue.diarrhea))
          this.errortext11 = 'SYMPTOMS_ERROR.please_fill_diarrhea';

        if (this.isAnswerNull(formValue.Wheezing))
          this.errortext24 = 'SYMPTOMS_ERROR.please_fill_Wheezing';
        if (this.isAnswerNull(formValue.sinusCongestion))
          this.errortext25 = 'SYMPTOMS_ERROR.please_fill_sinusCongestion';
        if (this.isAnswerNull(formValue.chestPain))
          this.errortext26 = 'SYMPTOMS_ERROR.please_fill_chestPain';
        if (this.isAnswerNull(formValue.New_loss_of_appetite))
          this.errortext27 = 'SYMPTOMS_ERROR.please_fill_New_loss_of_appetite';
        if (this.isAnswerNull(formValue.New_loss_of_smell))
          this.errortext28 = 'SYMPTOMS_ERROR.please_fill_New_loss_of_smell';
        if (this.isAnswerNull(formValue.New_loss_of_taste))
          this.errortext29 = 'SYMPTOMS_ERROR.please_fill_New_loss_of_taste';
        if (this.isAnswerNull(formValue.Fatigue))
          this.errortext30 = 'SYMPTOMS_ERROR.please_fill_Fatigue';
        if (this.isAnswerNull(formValue.Rigors))
          this.errortext31 = 'SYMPTOMS_ERROR.please_fill_Rigors';
        if (this.isAnswerNull(formValue.Other))
          this.errortext32 = 'SYMPTOMS_ERROR.please_fill_Other';
        if (this.isAnswerYes(formValue.Other) &&  formValue.Other_Symptoms=="")
          this.errortext35 = 'SYMPTOMS_ERROR.please_fill_Other';
      }
      if (this.isAnswerNull(formValue.contactWithSuspected))
        this.errortext12 = 'SYMPTOMS_ERROR.please_fill_suspect_covid';
      if (this.isAnswerNull(formValue.contact_with_confirmed_case))
        this.errortext13 = 'SYMPTOMS_ERROR.please_fill_confirmed_case';
      if (this.isAnswerNull(formValue.lungDisease))
        this.errortext14 = 'SYMPTOMS_ERROR.please_fill_lung_disease';
      if (this.isAnswerNull(formValue.diabetes))
        this.errortext15 = 'SYMPTOMS_ERROR.please_fill_diabetes';
      if (this.isAnswerNull(formValue.heartDisease))
        this.errortext16 = 'SYMPTOMS_ERROR.please_fill_heart';
      // if(this.isAnswerNull(formValue.hypertension))
      //   this.errortext+='Hypertension, ';
      if (this.isAnswerNull(formValue.kidneyDisease))
        this.errortext17 = 'SYMPTOMS_ERROR.please_fill_kidney';
      if (this.isAnswerNull(formValue.liverDisease))
        this.errortext18 = 'SYMPTOMS_ERROR.please_fill_liver';
      if (this.isAnswerNull(formValue.weekImmuneSystem))
        this.errortext19 = 'SYMPTOMS_ERROR.please_fill_immune';
      if (this.isAnswerNull(formValue.obesity))
        this.errortext20 = 'SYMPTOMS_ERROR.please_fill_obesity';
      if (this.isAnswerNull(formValue.highBloodPressure))
        this.errortext21 = 'SYMPTOMS_ERROR.please_fill_blood';
      if (this.isAnswerNull(formValue.smoking))
        this.errortext22 = 'SYMPTOMS_ERROR.please_fill_smoking';
      this.preConditionNewFields.forEach(field => {
        if (this.isAnswerNull(formValue[field.key])) {
          this.newFieldError.push('SYMPTOMS_PAGE.' + field.key);
        }
      });
      if (this.errortext != null) {
        //this.errortext=this.errortext.substring(0,this.errortext.length-2);
      }
      this.errortext += '';
    } else {
      this.errortext = null;
    }

    for (const key of fieldsOrder) {
      if(Object.keys(this.form.controls).includes(key)) {
        if (this.form.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(`[formcontrolname=${key}]`);
          if(_.get(invalidControl, "tagName") === 'MAT-RADIO-GROUP') {
            invalidControl.firstChild.focus();
          } else if(_.get(invalidControl, "tagName") === 'MAT-SELECT') {
            invalidControl.focused = true;
            invalidControl.focus();
          } else
            invalidControl.focus();
          break;
       }
      } 
    }
  }
  resetErrors() {
    this.errortext1 = "";
    this.errortext2 = "";
    this.errortext3 = "";
    this.errortext4 = "";
    this.errortext5 = "";
    this.errortext6 = "";
    this.errortext7 = "";
    this.errortext8 = "";
    this.errortext9 = "";
    this.errortext10 = "";
    this.errortext11 = "";
    this.errortext24 = "";
    this.errortext25 = "";
    this.errortext26 = "";
    this.errortext27 = "";
    this.errortext28 = "";
    this.errortext29 = "";
    this.errortext30 = "";
    this.errortext31 = "";
    this.errortext32 = "";
    this.errortext35 = "";
    this.errortext12 = "";
    this.errortext13 = "";
    this.errortext14 = "";
    this.errortext15 = "";
    this.errortext16 = "";
    this.errortext17 = "";
    this.errortext18 = "";
    this.errortext19 = "";
    this.errortext20 = "";
    this.errortext21 = "";
    this.errortext22 = "";
  }
  checkSymptom(event) {
    if (
      this.isAnswerYes(this.form.get('fever').value) ||
      this.isAnswerYes(this.form.get('chills').value) ||
      this.isAnswerYes(this.form.get('cough').value) ||
      // this.isAnswerYes(this.form.get('feverish').value) ||
      this.isAnswerYes(this.form.get('runnyNose').value) ||
      this.isAnswerYes(this.form.get('breath').value) ||
      this.isAnswerYes(this.form.get('sore').value) ||
      this.isAnswerYes(this.form.get('headache').value) ||
      this.isAnswerYes(this.form.get('muscleAche').value) ||
      this.isAnswerYes(this.form.get('abdominalPain').value) ||
      this.isAnswerYes(this.form.get('vomiting').value) ||
      this.isAnswerYes(this.form.get('diarrhea').value) ||

      this.isAnswerYes(this.form.get('Wheezing').value) ||
      this.isAnswerYes(this.form.get('sinusCongestion').value) ||
      this.isAnswerYes(this.form.get('chestPain').value) ||
      this.isAnswerYes(this.form.get('New_loss_of_appetite').value) ||
      this.isAnswerYes(this.form.get('New_loss_of_smell').value) ||
      this.isAnswerYes(this.form.get('New_loss_of_taste').value) ||
      this.isAnswerYes(this.form.get('Fatigue').value) ||
      this.isAnswerYes(this.form.get('Rigors').value) ||
      this.isAnswerYes(this.form.get('Other').value)

      
    ) {
      this.anySymptomYes = true;
      if (!this.form.value.register_vaccine) {
        this.form.controls.onset_date.setValidators([Validators.required]);
      }else{
        this.form.controls.onset_date.setValidators(null);
      }
    } else {
      this.anySymptomYes = false;
      this.form.controls.onset_date.setValidators(null);
    }

    if(this.isAnswerYes(this.form.get('Other').value)){
      this.form.controls.Other_Symptoms.setValidators([Validators.required]);
    }else{
      this.form.controls.Other_Symptoms.setValidators(null);
    }
    this.form.controls.Other_Symptoms.updateValueAndValidity();
    this.form.controls.onset_date.updateValueAndValidity();
  }
  @Input()
  form: FormGroup;
  maxDate = moment().utc().endOf('day').format('YYYY-MM-DD');
  minDate = moment().subtract(180, 'd').utc().endOf('day').format('YYYY-MM-DD');
  constructor(private cdref: ChangeDetectorRef, private el: ElementRef) {
    this.formClass = 'notsubmittedform';
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();

  }

  ngOnInit() {
    this.form.addControl('fever', new FormControl('', Validators.required));
    this.form.addControl('chills', new FormControl('', Validators.required));
    this.form.addControl('runnyNose', new FormControl('', Validators.required));
    this.form.addControl('cough', new FormControl('', Validators.required));
    this.form.addControl('breath', new FormControl('', Validators.required));
    this.form.addControl('sore', new FormControl('', Validators.required));
    this.form.addControl('headache', new FormControl('', Validators.required));
    this.form.addControl('muscleAche', new FormControl('', Validators.required));
    this.form.addControl('abdominalPain', new FormControl('', Validators.required));
    this.form.addControl('vomiting', new FormControl('', Validators.required));
    this.form.addControl('diarrhea', new FormControl('', Validators.required));

    this.form.addControl('Wheezing', new FormControl('', Validators.required));
    this.form.addControl('sinusCongestion', new FormControl('', Validators.required));
    this.form.addControl('chestPain', new FormControl('', Validators.required));
    this.form.addControl('New_loss_of_appetite', new FormControl('', Validators.required));
    this.form.addControl('New_loss_of_smell', new FormControl('', Validators.required));
    this.form.addControl('New_loss_of_taste', new FormControl('', Validators.required));
    this.form.addControl('Fatigue', new FormControl('', Validators.required));
    this.form.addControl('Rigors', new FormControl('', Validators.required));
    this.form.addControl('Other', new FormControl('', Validators.required));
    this.form.addControl('Other_Symptoms', new FormControl('', Validators.required));
    

    this.form.addControl('contactWithSuspected', new FormControl('', Validators.required));
    this.form.addControl('contact_with_confirmed_case', new FormControl('', Validators.required));
    this.form.addControl('lungDisease', new FormControl('', Validators.required));
    this.form.addControl('diabetes', new FormControl('', Validators.required));
    this.form.addControl('heartDisease', new FormControl('', Validators.required));
    this.form.addControl('obesity', new FormControl('', Validators.required));
    this.form.addControl('kidneyDisease', new FormControl('', Validators.required));
    this.form.addControl('liverDisease', new FormControl('', Validators.required));
    this.form.addControl('weekImmuneSystem', new FormControl('', Validators.required));
    this.form.addControl('highBloodPressure', new FormControl('', Validators.required));
    this.form.addControl('otherDisease', new FormControl(''));
    this.form.addControl('onset_date', new FormControl(''));
    this.form.addControl('fttemperature', new FormControl('', [Validators.min(90.0), Validators.max(110.0), Validators.pattern(this.temperatureNumbers)]));
    this.form.addControl('typeofTemp', new FormControl('', Validators.required));
    this.form.addControl('smoking', new FormControl('', Validators.required));
    this.form.addControl('register_vaccine', new FormControl(false, Validators.required));
    this.preConditionNewFields.forEach((element) => {
      this.form.addControl(element.key, new FormControl(null, [Validators.required]));      
    });
    this.form.controls.typeofTemp.setValue(this.typeofTemp);
  }

  public hasError = (controlName: string, errorName?: string) => {
    if(errorName)
      return this.form.controls[controlName].hasError(errorName);
    else  
      return !this.form.controls[controlName].valid;
  };

  addFormClass() {
    this.checkError(null);
    this.formClass = 'submittedform';
  }

  removeTep(event) {
    console.log('change');
    this.typeofTemp = event.value;
    if (this.typeofTemp == 'yes') {
      this.form.addControl(
        'cstemperature',
        new FormControl('', [
          Validators.min(32.0),
          Validators.max(44.0),
          Validators.pattern(this.temperatureNumbers)
        ])
      );
      this.form.removeControl('fttemperature');
    } else if (this.typeofTemp == 'no') {
      this.form.addControl(
        'fttemperature',
        new FormControl('', [
          Validators.min(90.0),
          Validators.max(110.0),
          Validators.pattern(this.temperatureNumbers)
        ])
      );
      this.form.removeControl('cstemperature');
    } else {
      this.form.removeControl('cstemperature');
      this.form.removeControl('fttemperature');
    }
  }

  changeFeverValue() {
    let isFever = false;
    // if (this.typeofTemp == 'yes') {
    //   isFever = parseFloat(this.form.value.cstemperature) >= 38.5;
    // } else if (this.typeofTemp == 'no') {
    //   isFever = parseFloat(this.form.value.fttemperature) >= 100.4;
    // }
    // this.form.patchValue({ fever: isFever ? 'yes' : 'no' });
    this.checkSymptom(null);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getData() {
    var test;
    var isfever;
    this.markFormGroupTouched(this.form);
    if (this.form.get('typeofTemp').value === 'yes') {
      test =
        (this.form.get('cstemperature').value * 1.8 + 32).toFixed(2) + ' *F';
    } else if (this.form.get('typeofTemp').value === 'no') {
      test = this.form.get('fttemperature').value + ' *F';
    } else {
      test = 'No';
    }
    if (this.form.get('fever').value == 'yes') {
      isfever = 'true';
    } else {
      isfever = 'false';
    }
    const { seconds, nanoseconds } = firebase.firestore.Timestamp.fromDate(
      moment.utc().toDate()
    );
    // to keep it consistent with firebase-admin
    var currTimeStamp = {
      _seconds: seconds,
      _nanoseconds: nanoseconds
    };

    console.log(currTimeStamp);

    var followups = [
      {
        timestamp: currTimeStamp,
        responses: []
      }
    ];

    if (!this.form.value.register_vaccine) {
      followups[0].responses = [
        {
          question: 'Temperature',
          answer: test
        },
        {
          question: 'Fever ?',
          answer: isfever
        },
        {
          question: 'Chills ?',
          answer: this.form.get('chills').value
        },
        {
          question: 'Cough ?',
          answer: this.form.get('cough').value
        },
        {
          question: 'Runny Nose ?',
          answer: this.form.get('runnyNose').value
        },
        {
          question: 'Difficulty breathing/Shortness of breath ?',
          answer: this.form.get('breath').value
        },
        {
          question: 'Sore throat ?',
          answer: this.form.get('sore').value
        },
        {
          question: 'Headache ?',
          answer: this.form.get('headache').value
        },
        {
          question: 'Abdominal Pain ?',
          answer: this.form.get('abdominalPain').value
        },
        {
          question: 'Muscle Aches ?',
          answer: this.form.get('muscleAche').value
        },
        {
          question: 'Vomiting ?',
          answer: this.form.get('vomiting').value
        },
        {
          question: 'Diarrhea ?',
          answer: this.form.get('diarrhea').value
        },

        
        //this.form.addControl('Wheezing', new FormControl('', Validators.required));
    // this.form.addControl('sinusCongestion', new FormControl('', Validators.required));
    // this.form.addControl('chestPain', new FormControl('', Validators.required));
    // this.form.addControl('New_loss_of_appetite', new FormControl('', Validators.required));
    // this.form.addControl('New_loss_of_smell', new FormControl('', Validators.required));
    // this.form.addControl('New_loss_of_taste', new FormControl('', Validators.required));
    // this.form.addControl('Fatigue', new FormControl('', Validators.required));
    // this.form.addControl('Rigors', new FormControl('', Validators.required));
    // this.form.addControl('Other', new FormControl('', Validators.required));
        {
          question: 'Wheezing ?',
          answer: this.form.get('Wheezing').value
        },

        {
          question: 'Sinus Congestion ?',
          answer: this.form.get('sinusCongestion').value
        },

        {
          question: 'Chest Pain ?',
          answer: this.form.get('chestPain').value
        },

        {
          question: 'Loss of Appetite ?',
          answer: this.form.get('New_loss_of_appetite').value
        },

        {
          question: 'Loss of Smell ?',
          answer: this.form.get('New_loss_of_smell').value
        },

        {
          question: 'Loss of Taste ?',
          answer: this.form.get('New_loss_of_taste').value
        },

        {
          question: 'Fatigue ?',
          answer: this.form.get('Fatigue').value
        },

        {
          question: 'Rigors ?',
          answer: this.form.get('Rigors').value
        },

        {
          question: 'Other ?',
          answer: this.form.get('Other').value
        },
        {
          question: 'Other Symptoms ?',
          answer: this.form.get('Other_Symptoms').value
        }
        
      ];
    }
    var symptomsData = {
      onset_date: this.form.get('onset_date').value,
      followupData: followups,
      register_vaccine: this.form.value.register_vaccine,
      preconditions: [
        {
          question:
            'Have you been in contact with someone who has a suspected case of COVID-19?',
          answer: this.form.get('contactWithSuspected').value,
          identifier: 'contactWithSuspected'
        },
        {
          question:
            'Have you been in contact with someone who has a laboratory-confirmed Case of COVID-19?',
          answer: this.form.get('contact_with_confirmed_case').value,
          identifier: 'contact_with_confirmed_case'
        },
        {
          question: 'Lung Disease ?',
          answer: this.form.get('lungDisease').value,
          identifier: 'lungDisease'
        },
        {
          question: 'Diabetes ?',
          answer: this.form.get('diabetes').value,
          identifier: 'diabetes'
        },
        {
          question: 'Heart Disease ?',
          answer: this.form.get('heartDisease').value,
          identifier: 'heartDisease'
        },
        {
          question: 'Kidney Disease ?',
          answer: this.form.get('kidneyDisease').value,
          identifier: 'kidneyDisease'
        },
        {
          question: 'Liver Disease ?',
          answer: this.form.get('liverDisease').value,
          identifier: 'liverDisease'
        },
        {
          question: 'Weak Immune System ?',
          answer: this.form.get('weekImmuneSystem').value,
          identifier: 'weekImmuneSystem'
        },
        {
          question: 'Obesity (BMI >30) ?',
          answer: this.form.get('obesity').value,
          identifier: 'obesity'
        },
        {
          question: 'High Blood Pressure ?',
          answer: this.form.get('highBloodPressure').value,
          identifier: 'highBloodPressure'
        },
        {
          question: 'Other ?',
          answer: this.form.get('otherDisease').value,
          identifier: 'otherDisease'
        },
        {
          question: 'Smoking',
          answer: this.form.get('smoking').value,
          identifier: 'smoking'
        },
        {
          identifier: 'cancer',
          answer: this.form.get('cancer').value,
          question: 'Cancer'
        },
        {
          identifier: 'chronic_kidney',
          answer: this.form.get('chronic_kidney').value,
          question: 'Chronic kidney disease'
        },
        {
          identifier: 'pulmonary_disease',
          answer: this.form.get('pulmonary_disease').value,
          question: 'Chronic obstructive pulmonary disease (COPD)'
        },
        {
          identifier: 'heart_conditions',
          answer: this.form.get('heart_conditions').value,
          question:
            'Heart conditions (heart failure, coronary artery disease, cardiomyopathy)'
        },
        {
          identifier: 'solid_organ_transplant',
          answer: this.form.get('solid_organ_transplant').value,
          question: 'Immunocompromised state from solid organ transplant'
        },       
        {
          identifier: 'cell_disease',
          answer: this.form.get('cell_disease').value,
          question: 'Sickle cell disease'
        },
        {
          identifier: 'type_2_diabetes',
          answer: this.form.get('type_2_diabetes').value,
          question: 'Type 2 diabetes'
        },
        {
          identifier: 'pregnancy',
          answer: this.form.get('pregnancy').value,
          question: 'Pregnancy'
        },
        {
          identifier: 'down_syndrome',
          answer: this.form.get('down_syndrome').value,
          question: 'Down syndrome'
        },
        {
          identifier: 'asthma',
          answer: this.form.get('asthma').value,
          question: 'Asthma'
        },
        {
          identifier: 'cerebrovascular_disease',
          answer: this.form.get('cerebrovascular_disease').value,
          question: 'Cerebrovascular disease (such as stroke or TIA/mini-stroke)'
        },
        {
          identifier: 'cystic_fibrosis',
          answer: this.form.get('cystic_fibrosis').value,
          question: 'Cystic fibrosis'
        },
        {
          identifier: 'hypertension',
          answer: this.form.get('hypertension').value,
          question: 'Hypertension (high blood pressure)'
        },
        {
          identifier: 'immunocompromised_state',
          answer: this.form.get('immunocompromised_state').value,
          question:
            'Immunocompromised state (weak immune system) from other transplants, immune deficiency, HIV, use of steroids or other immune weakening medications '
        },
        {
          identifier: 'neurologic_condition',
          answer: this.form.get('neurologic_condition').value,
          question: 'Neurologic condition, dementia'
        },
        {
          identifier: 'overweight',
          answer: this.form.get('overweight').value,
          question: 'Overweight (BMI between 25 and 30)'
        },
        {
          identifier: 'pulmonary_fibrosis',
          answer: this.form.get('pulmonary_fibrosis').value,
          question: 'Pulmonary fibrosis'
        },
        {
          identifier: 'thalassemia',
          answer: this.form.get('thalassemia').value,
          question: 'Thalassemia (a blood disorder)'
        },
        {
          identifier: 'type_1_diabetes',
          answer: this.form.get('type_1_diabetes').value,
          question: 'Type 1 diabetes'
        }
      ]
    };    
    console.log('symptomsData: ', symptomsData);
    return symptomsData;
  }

  removeValidatorsToForm() {
    this.form.controls.contactWithSuspected.setValidators(null);
    this.form.controls.contactWithSuspected.updateValueAndValidity();
    this.form.controls.contact_with_confirmed_case.setValidators(null);
    this.form.controls.contact_with_confirmed_case.updateValueAndValidity();
    this.form.controls.fever.setValidators(null);
    this.form.controls.fever.setValue('');
    this.form.controls.fever.updateValueAndValidity();
    this.form.controls.chills.setValidators(null);
    this.form.controls.chills.setValue('');
    this.form.controls.chills.updateValueAndValidity();
    this.form.controls.runnyNose.setValidators(null);
    this.form.controls.runnyNose.setValue('');
    this.form.controls.runnyNose.updateValueAndValidity();
    this.form.controls.cough.setValidators(null);
    this.form.controls.cough.setValue('');
    this.form.controls.cough.updateValueAndValidity();
    this.form.controls.breath.setValidators(null);
    this.form.controls.breath.setValue('');
    this.form.controls.breath.updateValueAndValidity();
    this.form.controls.sore.setValidators(null);
    this.form.controls.sore.setValue('');
    this.form.controls.sore.updateValueAndValidity();
    this.form.controls.headache.setValidators(null);
    this.form.controls.headache.setValue('');
    this.form.controls.headache.updateValueAndValidity();
    this.form.controls.muscleAche.setValidators(null);
    this.form.controls.muscleAche.setValue('');
    this.form.controls.muscleAche.updateValueAndValidity();
    this.form.controls.abdominalPain.setValidators(null);
    this.form.controls.abdominalPain.setValue('');
    this.form.controls.abdominalPain.updateValueAndValidity();
    this.form.controls.vomiting.setValidators(null);
    this.form.controls.vomiting.setValue('');
    this.form.controls.vomiting.updateValueAndValidity();
    this.form.controls.diarrhea.setValidators(null);
    this.form.controls.diarrhea.setValue('');
    this.form.controls.diarrhea.updateValueAndValidity();
    //this.form.controls.contactWithSuspected.setValidators(null);
    //this.form.controls.contactWithSuspected.setValue('');
    //this.form.controls.contactWithSuspected.updateValueAndValidity();
    //this.form.controls.contact_with_confirmed_case.setValidators(null);
    //this.form.controls.contact_with_confirmed_case.setValue('');
    //this.form.controls.contact_with_confirmed_case.updateValueAndValidity();
    try {
      this.form.controls.fttemperature.setValidators(null);
      this.form.controls.fttemperature.setValue('');
      this.form.controls.fttemperature.updateValueAndValidity();
    } catch (error) {
      console.log('catch_error', error);
    }
    
    this.form.controls.typeofTemp.setValidators(null);
    this.form.controls.typeofTemp.setValue('');
    this.form.controls.typeofTemp.updateValueAndValidity();
    this.form.controls.onset_date.setValidators(null);
    this.form.controls.onset_date.setValue('');
    this.form.controls.onset_date.updateValueAndValidity();

    this.form.controls.Wheezing.setValidators(null);
    this.form.controls.Wheezing.setValue('');
    this.form.controls.Wheezing.updateValueAndValidity();

    this.form.controls.sinusCongestion.setValidators(null);
    this.form.controls.sinusCongestion.setValue('');
    this.form.controls.sinusCongestion.updateValueAndValidity();

    this.form.controls.chestPain.setValidators(null);
    this.form.controls.chestPain.setValue('');
    this.form.controls.chestPain.updateValueAndValidity();

    this.form.controls.New_loss_of_appetite.setValidators(null);
    this.form.controls.New_loss_of_appetite.setValue('');
    this.form.controls.New_loss_of_appetite.updateValueAndValidity();

    this.form.controls.New_loss_of_smell.setValidators(null);
    this.form.controls.New_loss_of_smell.setValue('');
    this.form.controls.New_loss_of_smell.updateValueAndValidity();

    this.form.controls.New_loss_of_taste.setValidators(null);
    this.form.controls.New_loss_of_taste.setValue('');
    this.form.controls.New_loss_of_taste.updateValueAndValidity();

    this.form.controls.Fatigue.setValidators(null);
    this.form.controls.Fatigue.setValue('');
    this.form.controls.Fatigue.updateValueAndValidity();

    this.form.controls.Rigors.setValidators(null);
    this.form.controls.Rigors.setValue('');
    this.form.controls.Rigors.updateValueAndValidity();

    this.form.controls.Other.setValidators(null);
    this.form.controls.Other.setValue('');
    this.form.controls.Other.updateValueAndValidity(); 

    this.form.controls.Other_Symptoms.setValidators(null);
    this.form.controls.Other_Symptoms.setValue('');
    this.form.controls.Other_Symptoms.updateValueAndValidity();    
  }

  checkBoxChange(event) {
    console.log(event);
    if (event.checked) {
      this.removeValidatorsToForm();
    } else {
      this.addValidators();
    }
  }

  addValidators() {
    this.form.get('contactWithSuspected').setValidators([Validators.required]);
    this.form.get('contactWithSuspected').updateValueAndValidity();
    this.form.get('contact_with_confirmed_case').setValidators([Validators.required]);
    this.form.get('contact_with_confirmed_case').updateValueAndValidity();
    this.form.get('fever').setValidators([Validators.required]);
    this.form.get('fever').setValue('');
    this.form.get('fever').updateValueAndValidity();
    this.form.get('chills').setValidators([Validators.required]);
    this.form.get('chills').setValue('');
    this.form.get('chills').updateValueAndValidity();
    this.form.get('runnyNose').setValidators([Validators.required]);
    this.form.get('runnyNose').setValue('');
    this.form.get('runnyNose').updateValueAndValidity();
    this.form.get('cough').setValidators([Validators.required]);
    this.form.get('cough').setValue('');
    this.form.get('cough').updateValueAndValidity();
    this.form.get('breath').setValidators([Validators.required]);
    this.form.get('breath').setValue('');
    this.form.get('breath').updateValueAndValidity();
    this.form.get('sore').setValidators([Validators.required]);
    this.form.get('sore').setValue('');
    this.form.get('sore').updateValueAndValidity();
    this.form.get('headache').setValidators([Validators.required]);
    this.form.get('headache').setValue('');
    this.form.get('headache').updateValueAndValidity();
    this.form.get('muscleAche').setValidators([Validators.required]);
    this.form.get('muscleAche').setValue('');
    this.form.get('muscleAche').updateValueAndValidity();
    this.form.get('abdominalPain').setValidators([Validators.required]);
    this.form.get('abdominalPain').setValue('');
    this.form.get('abdominalPain').updateValueAndValidity();
    this.form.get('vomiting').setValidators([Validators.required]);
    this.form.get('vomiting').setValue('');
    this.form.get('vomiting').updateValueAndValidity();
    this.form.get('diarrhea').setValidators([Validators.required]);
    this.form.get('diarrhea').setValue('');
    this.form.get('diarrhea').updateValueAndValidity();
    this.form.get('contactWithSuspected').setValidators([Validators.required]);
    this.form.get('contactWithSuspected').setValue('');
    this.form.get('contactWithSuspected').updateValueAndValidity();
    this.form.get('contact_with_confirmed_case').setValidators([Validators.required]);
    this.form.get('contact_with_confirmed_case').setValue('');
    this.form.get('contact_with_confirmed_case').updateValueAndValidity();
    this.form.get('fttemperature').setValidators([Validators.min(90.0), Validators.max(110.0), Validators.pattern(this.temperatureNumbers)]);
    this.form.get('fttemperature').setValue('');
    this.form.get('fttemperature').updateValueAndValidity();
    this.form.get('typeofTemp').setValidators([Validators.required]);
    this.form.get('typeofTemp').setValue('');
    this.form.get('typeofTemp').updateValueAndValidity();
    this.form.get('onset_date').setValidators([Validators.required]);
    this.form.get('onset_date').setValue('');
    this.form.get('onset_date').updateValueAndValidity();

    this.form.get('Wheezing').setValidators([Validators.required]);
    this.form.get('Wheezing').setValue('');
    this.form.get('Wheezing').updateValueAndValidity();

    this.form.get('sinusCongestion').setValidators([Validators.required]);
    this.form.get('sinusCongestion').setValue('');
    this.form.get('sinusCongestion').updateValueAndValidity();

    this.form.get('chestPain').setValidators([Validators.required]);
    this.form.get('chestPain').setValue('');
    this.form.get('chestPain').updateValueAndValidity();

    this.form.get('New_loss_of_appetite').setValidators([Validators.required]);
    this.form.get('New_loss_of_appetite').setValue('');
    this.form.get('New_loss_of_appetite').updateValueAndValidity();

    this.form.get('New_loss_of_smell').setValidators([Validators.required]);
    this.form.get('New_loss_of_smell').setValue('');
    this.form.get('New_loss_of_smell').updateValueAndValidity();

    this.form.get('New_loss_of_taste').setValidators([Validators.required]);
    this.form.get('New_loss_of_taste').setValue('');
    this.form.get('New_loss_of_taste').updateValueAndValidity();

    this.form.get('Fatigue').setValidators([Validators.required]);
    this.form.get('Fatigue').setValue('');
    this.form.get('Fatigue').updateValueAndValidity();

    this.form.get('Rigors').setValidators([Validators.required]);
    this.form.get('Rigors').setValue('');
    this.form.get('Rigors').updateValueAndValidity();

    this.form.get('Other').setValidators([Validators.required]);
    this.form.get('Other').setValue('');
    this.form.get('Other').updateValueAndValidity();

    this.form.get('Other_Symptoms').setValidators([Validators.required]);
    this.form.get('Other_Symptoms').setValue('');
    this.form.get('Other_Symptoms').updateValueAndValidity();        
  }
}
