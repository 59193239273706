
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import * as BigNumber from 'big-number';

import {
  SIMPLE_DATETIME_FORMAT,
  SIMPLE_DATE_FORMAT,
  SIMPLE_TIME_FORMAT,
  SHORT_DATETIME_FORMAT,
  firestoreBaseDate
} from './constant';
import firebase from 'firebase/app';
import '@firebase/firestore';

// set this as default and change it when loaded from config
// TODO move this to config loading
const TIMEZONE = 'America/New_York';
moment.tz.setDefault(TIMEZONE);

// sets utc offset of moment to mid day -> 12 PM
const fixMidDayOffset = (date: moment.Moment): moment.Moment => {
  // we're doing this twice to avoid a date change in new york or india
  return moment(date)
    .set({ hours: 12, minutes: 0, seconds: 0, milliseconds: 0 })
    .utc()
    .set({ hours: 12, minutes: 0, seconds: 0, milliseconds: 0 });
};

// timestamp to -> MM/DD hh:mm A or empty for invalid and non existing stamp
const timestampToSimpleFormat = (date: firestoreBaseDate): string => {
  return formattedTimestamp(date, SIMPLE_DATETIME_FORMAT);
};

const timestampToShortFormat = (date: firestoreBaseDate): string => {
  return formattedTimestamp(date, SHORT_DATETIME_FORMAT);
};

// timestamp to -> MM/DD hh:mm A or empty for invalid and non existing stamp
const momentToShortFormat = (date: moment.Moment): string => {
  return moment(date).format(SHORT_DATETIME_FORMAT);
};

const formattedTimestamp = (
  date: firestoreBaseDate,
  format: string
): string => {
  // if the timestamp exists and is not empty
  if (!_.isEmpty(date)) {
    let _seconds = date._seconds || date.seconds;
    let _nanoseconds = date._nanoseconds || date.nanoseconds || 0;

    let unixString = BigNumber(_nanoseconds)
      .divide('1000000000')
      .add(_seconds)
      .toString();

    // this doesn't lack precision but we can live with it
    const momentDate = moment.unix(parseFloat(unixString));
    if (momentDate.isValid()) {
      return momentDate.format(format);
    } else {
      return '';
    }
  }

  return '';
};

// timestamp to -> MM/DD/YYYY or empty for invalid and non existing stamp
const timestampToSimpleDateFormat = (date: firestoreBaseDate): string => {
  // if the timestamp exists and is not empty
  if (!_.isEmpty(date)) {
    let _seconds = date._seconds || date.seconds;
    let _nanoseconds = date._nanoseconds || date.nanoseconds || 0;

    let unixString = BigNumber(_nanoseconds)
      .divide('1000000000')
      .add(_seconds)
      .toString();

    // this doesn't lack precision but we can live with it
    const momentDate = moment.unix(parseFloat(unixString));
    if (momentDate.isValid()) {
      return momentDate.format(SIMPLE_DATE_FORMAT);
    } else {
      return '';
    }
  }

  return '';
};

// timestamp to -> moment.Moment or empty for invalid and non existing stamp
const timestampToSimpleTime = (date: firestoreBaseDate): string => {
  // if the timestamp exists and is not empty
  if (!_.isEmpty(date)) {
    let _seconds = date._seconds || date.seconds;
    let _nanoseconds = date._nanoseconds || date.nanoseconds || 0;

    let unixString = BigNumber(_nanoseconds)
      .divide('1000000000')
      .add(_seconds)
      .toString();

    // this doesn't lack precision but we can live with it
    const momentDate = moment.unix(parseFloat(unixString));
    if (momentDate.isValid()) {
      return momentDate.format(SIMPLE_TIME_FORMAT);
    } else {
      return '';
    }
  }

  return '';
};

// simple date MM/DD/YYYY -> moment.Moment or empty for invalid and non existing date
const simpleDateToMoment = (date: string): moment.Moment=> {
  // if the moment is valid return it else empty
  const momentDate = moment(date, SIMPLE_DATE_FORMAT);
  if (!!date && momentDate.isValid()) {
    return momentDate;
  }

  return moment(null);
};

// simple date MM/DD/YYYY -> moment iso string
// fixMidDay fixes the final utc day to 12 PM at midday
const simpleDateToIsoString = (
  date: string,
  fixMidDay: boolean = false
): string => {
  return momentToIsoString(simpleDateToMoment(date), fixMidDay);
};

// simple date MM/DD/YYYY -> moment.Moment or empty for invalid and non existing date
const momentToSimpleDateFormat = (date: moment.Moment): string => {
  // if the moment is valid return it else empty
  if (!_.isEmpty(date) && date.isValid()) {
    return date.format(SIMPLE_DATE_FORMAT);
  }

  return '';
};

// converts moment to timestamp but in json, needs to converted to firestore timestamp on the server
const momentToTimestamp = (
  date: moment.Moment,
  fixMidDay: boolean = false
): firestoreBaseDate | string => {
  // if the moment is valid return it else empty
  if (!_.isEmpty(date) && date.isValid()) {
    if (fixMidDay) {
      date = fixMidDayOffset(date);
    }
    // to keep it consistent with firebase-admin
    // this lacks precision but we'll have to live with it
    const { seconds, nanoseconds } = firebase.firestore.Timestamp.fromDate(
      date.toDate()
    );
    return {
      _seconds: seconds,
      _nanoseconds: nanoseconds
    };
  }

  return '';
};

// converts firestore timestamp in string to a moment
const timestampToMoment = (date: firestoreBaseDate): moment.Moment | string => {
  // if the timestamp exists and is not empty
  if (!_.isEmpty(date)) {
    let _seconds = date._seconds || date.seconds;
    let _nanoseconds = date._nanoseconds || date.nanoseconds || 0;

    let unixString = BigNumber(_nanoseconds)
      .divide('1000000000')
      .add(_seconds)
      .toString();

    // this doesn't lack precision but we can live with it
    const momentDate = moment.unix(parseFloat(unixString));
    if (momentDate.isValid()) {
      return momentDate;
    } else {
      return '';
    }
  }

  return '';
};

// fixMidDay fixes the final utc day to 12 PM at midday
const momentToIsoString = (
  date: moment.Moment,
  fixMidDay: boolean = false
): string => {
  // if the moment is valid return it else empty
  if (!_.isEmpty(date) && date.isValid()) {
    if (fixMidDay) {
      date = fixMidDayOffset(date);
    }
    return date.toISOString();
  }

  return '';
};

const isoStringToMoment = (date: string): moment.Moment | string => {
  let momentDate = moment(date) as moment.Moment;
  // if the moment is valid return it else empty
  if (!_.isEmpty(date) && momentDate.isValid()) {
    return momentDate;
  }

  return '';
};

// converts MM/DD/YYYY-> firestore timestamp
const simpleDateToTimestamp = (date: moment.Moment, fixMidDay: boolean = false): firestoreBaseDate | string => {
  let momentDate = moment(date, SIMPLE_DATE_FORMAT) as moment.Moment;
  // if the moment is valid return it else empty
  if (!_.isEmpty(date) && momentDate.isValid()) {
    if (fixMidDay) {
      date = fixMidDayOffset(date);
    }
    return momentToTimestamp(momentDate);
  }

  return '';
};

// converts MM/DD/YYYY, hh:mm A -> firestore timestamp
const simpleDateTimeToTimestamp = (
  date: string,
  time: string
): firestoreBaseDate | string => {
  let momentDate = moment(
    `${date} ${time}`,
    SIMPLE_DATETIME_FORMAT
  ) as moment.Moment;
  // if the moment is valid return it else empty
  if (!_.isEmpty(date) && !_.isEmpty(time) && momentDate.isValid()) {
    return momentToTimestamp(momentDate);
  }

  return '';
};

// converts MM/DD/YYYY, hh:mm A -> moment
const simpleDateTimeToMoment = (date: string, time: string): moment.Moment | string => {
  let momentDate = moment(`${date} ${time}`, SIMPLE_DATETIME_FORMAT) as moment.Moment;
  // if the moment is valid return it else empty
  if (!_.isEmpty(date) && !_.isEmpty(time) && momentDate.isValid()) {
    return momentDate;
  }

  return '';
};

// returns the end of today in moment, and others
const momentNow: moment.Moment = moment();
const momentEndOfToday: moment.Moment = momentNow.endOf('day');
const momentStartOfToday: moment.Moment = momentNow.startOf('day');

// calculate the age from an iso string
const isoStringToAge = (date: string): string => {
  if (!_.isEmpty(date)) {
    return momentNow.diff(isoStringToMoment(date), 'years') + ' years';
  }

  return '';
};

const diffInDaysFromISOString = (date: string): string => {
  if (!_.isEmpty(date)) {
    return momentNow.diff(isoStringToMoment(date), 'days').toString();
  }

  return '';
};
// calculate the age from a moment
const momentToAge = (date: moment.Moment): string => {
  if (!_.isEmpty(date) && date.isValid()) {
    return momentNow.diff(date, 'years') + ' years';
  }

  return '';
};

// converts iso string -> MM/DD/YYYY format
const isoStringToSimpleDate = (date: string): string => {
  let momentDate = moment(date) as moment.Moment;
  // if the moment is valid return it else empty
  if (!_.isEmpty(date) && momentDate.isValid()) {
    return momentDate.format(SIMPLE_DATE_FORMAT);
  }

  return '';
};

export {
  simpleDateToMoment,
  simpleDateToIsoString,
  simpleDateToTimestamp,
  simpleDateTimeToTimestamp,
  simpleDateTimeToMoment,
  moment,
  momentNow,
  momentToAge,
  momentStartOfToday,
  momentEndOfToday,
  momentToTimestamp,
  momentToIsoString,
  momentToSimpleDateFormat,
  momentToShortFormat,
  timestampToMoment,
  timestampToSimpleTime,
  timestampToSimpleDateFormat,
  timestampToSimpleFormat,
  timestampToShortFormat,
  isoStringToMoment,
  isoStringToSimpleDate,
  isoStringToAge,
  diffInDaysFromISOString,
  formattedTimestamp,
  fixMidDayOffset
};
