import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'mtx-erm-demo';

  iconList = [
    'Bitmap',
    'buzz',
    'c-check',
    'Check2',
    'c-question',
    'circle-09',
    'Check',
    'folder-15',
    'Hamburger',
    'License',
    'man',
    'mtx',
    'pin-4',
    'User'
  ];

  // add the collection of mat icons
  // constructor(private translate: TranslateService) {
  //   translate.setDefaultLang('en');
  // }
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('en');

    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      const mainHeader = document.querySelector('#main-content');
      if (mainHeader) {
        mainHeader['focus']();
      }
    });
    for (const icon of this.iconList) {
      this.matIconRegistry.addSvgIcon(
        `icon_${icon}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `../assets/${icon}.svg`
        )
      );
    }
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

}
