import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
//NG_Translate import funstions
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
const ɵ0 = { duration: 3000 }, ɵ1 = { showError: true };
export class AppModule {
    constructor(matIconRegistry, domSanitizer) {
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.matIconRegistry.addSvgIcon(`mtx_logo`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/mtx.svg'));
    }
}
export { ɵ0, ɵ1 };
