import { Component, OnInit, Inject, ViewChild, Input, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { ChicagoCounties } from '../../enums/chicago-counties.enum';
import { LocateRelations } from 'src/app/enums/locate-relations.enum';
import { UsStates } from 'src/app/enums/us-states.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  countrycodes,
  noWhitespaceValidator,
  removeSpacesEnforcer
} from 'src/app/common/util';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-address-dialog',
  templateUrl: './add-address-dialog.component.html',
  styleUrls: ['./add-address-dialog.component.scss']
})
export class AddAddressDialogComponent implements OnInit {
  @Input()
  addressObject: any;
  countrycodes = countrycodes;
  states = Object.keys(UsStates).filter((state) => {
    return isNaN(Number(state));
  });

  locateRelations = Object.keys(LocateRelations).filter((relation) => {
    return isNaN(Number(relation));
  });

  filteredStates = this.states;
  invalidZip = false;
  invalidMemberCount = false;
  invalidCity = false;
  invalidState = false;
  selectedType: string = 'current';
  selectedState: string;
  addressTypes = [];
  addressed: any = {};
  isEdit = false;
  isEditName = 'ADDRESS_COMMON.add_an';
  currentID = null;
  isAddError = false;
  errorMsg1 = '';
  errorMsg2 = '';
  errorMsg3 = '';
  errorMsg4 = '';
  errorMsg5 = '';
  errorMsg6 = '';

  addressForm: FormGroup;
  isAddressRequired = true;
  addressType = ''
  @ViewChild('type', { static: true }) type: any;
  @ViewChild('firstName', { static: false }) firstName: any;
  @ViewChild('lastName', { static: false }) lastName: any;
  @ViewChild('relation', { static: false }) relation: any;
  @ViewChild('phoneNumber', { static: false }) phoneNumber: any;
  @ViewChild('address', { static: true }) address: any;
  @ViewChild('city', { static: true }) city: any;
  @ViewChild('state', { static: true }) state: any;
  @ViewChild('country', { static: true }) country: any;
  @ViewChild('zip', { static: true }) zip: any;
  selectedTypeLabel = {
    current: this.translate.instant('ADDRESS_COMMON.current_address'),
    permanent: this.translate.instant('ADDRESS_COMMON.permanent_address')
  };
  constructor(
    public dialogRef: MatDialogRef<AddAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private el: ElementRef
  ) {
    this.addressTypes = data.addressTypes;
    if(data.addressTypes){
      this.addressType = data.addressTypes[0].value;
      this.selectedType = data.addressTypes[0].value;
    }
    if (data.currentAddress) {
      this.addressed = data.currentAddress;
      this.isEdit = true;
      this.isEditName = 'COMMON_WORDS.edit';
      this.currentID = data.currentAddress.id;
      this.selectedType = data.currentAddress.type; //data.currentAddress.type === 'Current' ? 'Current' : 'Permanent';
    }
    this.addressForm = new FormGroup({
      type: new FormControl(this.addressType, []),
      emergencycheckbox: new FormControl(false),
      address: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
        removeSpacesEnforcer
      ]),
      city: new FormControl('', [Validators.required, removeSpacesEnforcer, Validators.pattern('^[a-zA-Z ]*$')]),
      state: new FormControl(
        {
          value: this.addressType =='current' ?'Illinois' :'',
          disabled: this.addressType =='current' ? true : false
        },
        [Validators.required,removeSpacesEnforcer,Validators.pattern('^[a-zA-Z ]*$')]
      ),
      country: new FormControl({
        value: 'United States',
        disabled: this.addressType =='current' ? true : false
      }, [
        removeSpacesEnforcer
      ]),
      zip: new FormControl('', [
        Validators.required,
        this.numberValidator.bind(this),
        Validators.minLength(5),
        removeSpacesEnforcer

      ]),

      total_members_in_household: new FormControl('', [
        Validators.required, 
        Validators.min(1), 
        Validators.minLength(1), 
        Validators.maxLength(2), 
        this.numberValidator.bind(this),
        removeSpacesEnforcer
      ])

    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  // Validates numbers
  numberValidator(number): any {
    const NUMBER_REGEXP = /^[\d.]+$/;
    if (number.value == '' || NUMBER_REGEXP.test(number.value)) {
      return null;
    }
    return {
      invalidNumber: true
    };
  }

  ngOnInit() {}

  ngAfterContentInit() {
    if (this.isEdit) {
      this.addressForm.patchValue({
        type: this.addressed.type,
        address: this.addressed.address,
        city: this.addressed.city,
        state: this.addressed.state,
        country: this.addressed.country, 
        zip: this.addressed.zip,
        total_members_in_household: this.addressed.total_members_in_household,
        emergencycheckbox: this.addressed.emergencycheckbox
      });
      if(this.addressed.type == 'current'){
        this.addressForm.get('state').disable();
        this.addressForm.get('country').disable();

      }
    }
    if(this.addressed.type == 'permanent') {
      this.addressForm.get('state').enable();
    }
  }
  onStateChange(value: any) {
    this.selectedState = value;
  }
  onTypeChange(value: string) {
    this.selectedType = value;
    if (this.selectedType == 'current') {
      this.addressForm.get('state').setValue('Illinois');
      this.addressForm.get('state').disable();
      this.addressForm.patchValue({ country: 'United States' });
      this.addressForm.get('country').disable();
      this.addressForm.controls.country.updateValueAndValidity();
    } else {
      this.addressForm.get('country').enable();
      this.addressForm.controls.country.updateValueAndValidity();
      this.addressForm.get('state').setValue('');
      this.addressForm.get('state').enable();
      this.addressForm.controls.state.updateValueAndValidity();
    }
  }
  private _filterArray(filterValue: string, values: string[]): string[] {
    filterValue = filterValue.toLowerCase();

    return values.filter(
      (value) => value.toLowerCase().indexOf(filterValue) !== -1
    );
  }

  get f() {
    return this.addressForm.controls;
  }

  save() {
    var count = 0;
    this.invalidZip = false;
    this.invalidMemberCount = false;
    this.invalidCity = false;
    this.invalidState = false;
    this.isAddError = false;
    //this.erroMsg = '';
    if (this.f.address.value == '') {
      this.isAddError = true;
      this.errorMsg1 = 'ADDRESS_COMMON.street_address';
      count++;
    }
    if (this.f.city.value == '') {
      this.isAddError = true;
      this.errorMsg2= 'ADDRESS_COMMON.city' ;
      count++;
    }

    if (this.f.state.value == '') {
      this.isAddError = true;
      this.errorMsg3 = 'ADDRESS_COMMON.state';
      count++;
    }
    if (this.f.zip.value == '') {
      this.isAddError = true;
      this.errorMsg4 = 'ADDRESS_COMMON.zip';
      count++;
    }
    if (this.f.total_members_in_household.value == '' || this.f.total_members_in_household.value == null) {
      this.isAddError = true;
      this.errorMsg5 = 'ADDRESS_COMMON.total_members_in_household';
      count++;
    }
    //this.erroMsg =  this.erroMsg.replace(/,\s*$/, "");
    this.errorMsg6 = count > 1 ? 'ADDRESS_COMMON.are_required' : 'ADDRESS_COMMON.is_required';

    if (this.f.total_members_in_household.value < 0) {
      this.invalidMemberCount = true;
      return;
    }

    var numericCheck=new RegExp('^[a-zA-Z ]*$');
    if(!numericCheck.test(this.f.city.value)) {
      this.invalidCity=true;
      return;
    }
    if(!numericCheck.test(this.f.state.value)) {
      this.invalidState=true;
      return;
    }
    /*if (
      this.f.address.value != '' && this.f.state.value != '' &&
      (this.f.city.value == '' ||
        this.f.total_members_in_household.value == '' ||
        this.f.zip.value == '')
    ) {
      this.isAddError = true;
      this.erroMsg = 'City, Zip and Household Members Counts are required.';
      //return;
    }  if (
      this.f.address.value == '' || this.f.state.value == '' ||
      (this.f.city.value == '' ||
        this.f.state.value == '' ||
        this.f.total_members_in_household.value == '' ||
        this.f.zip.value == '')
    ) {
      this.isAddError = true;
      this.erroMsg = 'Address, City, State, Zip and Household Members Counts are required.';
      //return;
    }*/

    Object.keys(this.addressForm.controls).forEach((field) => {
      const control = this.addressForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    for (const key of Object.keys(this.addressForm.controls)) {
      if (this.addressForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        if(_.get(invalidControl, "tagName") === 'MAT-RADIO-GROUP') {
          invalidControl.firstChild.focus();
        } else if(_.get(invalidControl, "tagName") === 'MAT-SELECT') {
          invalidControl.focused = true;
          invalidControl.focus();
        } else
          invalidControl.focus();
        break;
     }
    }

    if (this.f.zip.status == 'INVALID') {
      this.invalidZip = true;
      return;
    }

    const address: any = {
      type: this.f.type.value,
      address: this.f.address.value,
      city: this.f.city.value,
      state: this.f.state.value,
      zip: this.f.zip.value,
      country: this.f.country.value,
      total_members_in_household: this.f.total_members_in_household.value,
      isEdit: this.isEdit,
      emergencycheckbox: this.f.emergencycheckbox.value
    };
    if (!this.isEdit) {
      address.id = '_' + Math.random().toString(36).substr(2, 9);
    } else {
      address.id = this.currentID;
    }
    if (!this.isAddError) {
      this.dialogRef.close(address);
    } else {
      window.scrollTo(0, 0);
    }
  }

  doFilter(value) {
    this.filteredStates= this.states
    console.log(value)
    this.filteredStates =  this.filteredStates.filter(state =>
      state.toLowerCase().indexOf(value.toLowerCase()) === 0);
      console.log(this.filteredStates)
  }

  public hasError = (controlName: string, erroName: string) => {
    return this.addressForm.controls[controlName].hasError(erroName);
  };

}
