/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guardian-information.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/guardian-card/guardian-card.component.ngfactory";
import * as i3 from "../../../components/guardian-card/guardian-card.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./guardian-information.component";
var styles_GuardianInformationComponent = [i0.styles];
var RenderType_GuardianInformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuardianInformationComponent, data: {} });
export { RenderType_GuardianInformationComponent as RenderType_GuardianInformationComponent };
function View_GuardianInformationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["aria-live", "assertive"], ["class", "error"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errortext; _ck(_v, 2, 0, currVal_0); }); }
function View_GuardianInformationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guardian-card", [], null, [[null, "onDelete"], [null, "onEdit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDelete" === en)) {
        var pd_0 = (_co.deleteGuardian($event) !== false);
        ad = (pd_0 && ad);
    } if (("onEdit" === en)) {
        var pd_1 = (_co.editGuardian($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_GuardianCardComponent_0, i2.RenderType_GuardianCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.GuardianCardComponent, [i4.MatDialog, i5.TranslateService], { guardian: [0, "guardian"] }, { onEdit: "onEdit", onDelete: "onDelete" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GuardianInformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "step-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " ", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 12, "div", [["class", "card-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuardianInformationComponent_1)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "alert info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuardianInformationComponent_2)), i1.ɵdid(15, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["class", "m-b-md"], ["color", "primary"], ["mat-stroked-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(18, 0, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.errortext; _ck(_v, 9, 0, currVal_3); var currVal_5 = _co.guardians; _ck(_v, 15, 0, currVal_5); var currVal_8 = "primary"; _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("GUARDIAN_INFO.guardian_info")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("GUARDIAN_INFO.step_4")); var currVal_2 = _co.total_pages; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("GUARDIAN_INFO.minor")); _ck(_v, 12, 0, currVal_4); var currVal_6 = (i1.ɵnov(_v, 17).disabled || null); var currVal_7 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("GUARDIAN_INFO.add_guardian")); _ck(_v, 18, 0, currVal_9); }); }
export function View_GuardianInformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guardian-information", [], null, null, null, View_GuardianInformationComponent_0, RenderType_GuardianInformationComponent)), i1.ɵdid(1, 114688, null, 0, i11.GuardianInformationComponent, [i4.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuardianInformationComponentNgFactory = i1.ɵccf("app-guardian-information", i11.GuardianInformationComponent, View_GuardianInformationComponent_Host_0, { errortext: "errortext", total_pages: "total_pages" }, {}, []);
export { GuardianInformationComponentNgFactory as GuardianInformationComponentNgFactory };
