import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
  NgForm,
  FormBuilder
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  ErrorStateMatcher,
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS
} from '@angular/material/core';
import moment from 'moment';
import {
  getStandardDate,
  countrycodes,
  noWhitespaceValidator
} from 'src/app/common/util';
import {
  momentStartOfToday,
  momentEndOfToday,
  fixMidDayOffset
} from 'src/app/common/timeutils';
import { MY_DATE_FORMATS, SQL_DATE } from 'src/app/common/constant';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _ from 'lodash';
import { getTreeControlMissingError } from '@angular/cdk/tree';
/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-passenger-information',
  templateUrl: './passenger-information.component.html',
  styleUrls: ['./passenger-information.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class PassengerInformationComponent implements OnInit {
  errortext1 = "";
  errortext2 = "";
  errortext3 = "";
  errortext4 = "";
  errortext5 = "";
  errortext6 = "";
  errortext7 = "";
  errortext8 = "";


  childFormName = 'passengerInformationComponent';
  @Input() total_pages: any;
  @Input() errortext: any;
  computedAge: any = 0;
  ageUnits: any = 'years';
  currentDateTime: moment.Moment = moment();

  @Input()
  form: FormGroup;
  matcher = new MyErrorStateMatcher();
  isFemale = false;
  isOther: boolean = false;
  //areYouOther = false;
  formClass = 'notsubmittedform';
  countrycodes = countrycodes;
  constructor(private el: ElementRef) {
    this.formClass = 'notsubmittedform';
    //console.log("errortext",this.errortext);
  }

  isAnswerNull(val) {
    if(typeof val === 'boolean') {
      return val === true || val === false ? false : true;
    } else {
      if (val) {
        return false;
      } else {
        return true;
      }
    }
  }
  checkError(event) {
   if (
      this.isAnswerNull(this.form.get('firstName').value) ||
      this.isAnswerNull(this.form.get('lastName').value) ||
      this.isAnswerNull(this.form.get('gender').value) ||
      this.isAnswerNull(this.form.get('phone').value) ||
      this.isAnswerNull(this.form.get('Race').value) ||
      this.isAnswerNull(this.form.get('Ethnicity').value) ||
      this.isAnswerNull(this.form.get('essentialEmployee').value)
    ) {
      this.errortext1='ERROR_MESSAGES.please_fill';
      if(this.isAnswerNull(this.form.get('gender').value) )
      this.errortext2='ERROR_MESSAGES.please_fill_sex';
    if(this.isAnswerNull(this.form.get('phone').value))
      this.errortext3='ERROR_MESSAGES.please_fill_no';
      if(this.isAnswerNull(this.form.get('Race').value))
      this.errortext4='ERROR_MESSAGES.please_fill_race';
    if(this.isAnswerNull(this.form.get('Ethnicity').value))
      this.errortext5='ERROR_MESSAGES.please_fill_ethnicity';
    if(this.isAnswerNull(this.form.get('essentialEmployee').value))
      this.errortext6='ERROR_MESSAGES.please_fill_essential';
    if(this.isAnswerNull(this.form.get('firstName').value))
      this.errortext7='ERROR_MESSAGES.first_name';
    if(this.isAnswerNull(this.form.get('lastName').value))
      this.errortext8='ERROR_MESSAGES.last_name';

    if(this.errortext!=null){
    //this.errortext=this.errortext.substring(0,this.errortext.length-2);
    }

    this.errortext+='';
    } else{
      this.errortext=null;
    }

    const formControlsOrder = [
      'firstName',
      'lastName',
      'dateOfBirth',
      'gender',
      'phone',
      'landline',
      'email',
      'Race',
      'Ethnicity',
      'essentialEmployee',
      'medical_condition_one',
      'medical_condition_two'
    ];

    for (const key of formControlsOrder) {
      if (this.form.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        if(_.get(invalidControl, "tagName") === 'MAT-RADIO-GROUP') {
          invalidControl.firstChild.focus();
        } else if(_.get(invalidControl, "tagName") === 'MAT-SELECT') {
          invalidControl.focused = true;
          invalidControl.focus();
        } else
          invalidControl.focus();
        break;
     }
    }
    
  }

  dateToday: moment.Moment = momentEndOfToday;
  dateStartToday: moment.Moment = momentStartOfToday;

  validateOnlyNumber(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  validateOnlyString(event) {
    var theEvent = event || window.event;
    if (theEvent.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /^[a-zA-Z(), ]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  computeAge(event: MatDatepickerInputEvent<Date>) {
    let birthdate = moment(event.value);
    let today = moment();
    let units = this.ageUnits;
    function getAge() {
      return today.diff(birthdate, units);
    }
    let age = getAge();
    this.computedAge = age;
    this.form.patchValue({ ageDisplay: `${age}` });
  }

  // determine the age from the date
  getAge() {
    if (this.getValueFromFormControl('dateOfBirth') !== '') {
      return this.currentDateTime.diff(moment(this.form.controls.dateOfBirth.value), 'years');
    }
    return null;
  }

  showPregnancy() {
    if(this.isFemale) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    //console.log("errortext",this.errortext);
    this.form.addControl('firstName', new FormControl('', []));
    this.form.addControl('middleName', new FormControl(''));
    this.form.addControl('lastName', new FormControl('', []));
    this.form.addControl(
      'dateOfBirth',
      new FormControl('', [this.pastDateValidator.bind(this)])
    );
    this.form.addControl('gender', new FormControl('', Validators.required));
    this.form.addControl('pregnancy', new FormControl(null));
    this.form.addControl(
      'phone',
      new FormControl('', [
        Validators.maxLength(15),
        this.numberValidator.bind(this)
      ])
    );
    this.form.addControl(
      'phoneext',
      new FormControl('+1', [Validators.required, noWhitespaceValidator])
    );
    this.form.addControl('contactMethod', new FormControl(''));
    this.form.addControl(
      'alternatePhone',

      new FormControl('', this.numberValidator.bind(this))
    );
    this.form.addControl('email', new FormControl('', [Validators.email]));
    this.form.addControl(
      'landline',
      new FormControl('', [
        Validators.maxLength(15),
        this.numberValidator.bind(this)
      ])
    );
    this.form.addControl(
      'landlineext',
      new FormControl('+1', [Validators.required, noWhitespaceValidator])
    );
    this.form.addControl(
      'essentialEmployee',
      new FormControl('', Validators.required)
    );
    this.form.addControl(
      'healthcareWorker',
      new FormControl(true)
    );
    this.form.addControl(
      'firstResponder',
      new FormControl(true)
    );
    this.form.addControl(
      'alternateLandline',
      new FormControl('', this.numberValidator.bind(this))
    );
    this.form.addControl('language', new FormControl('English'));
    //this.form.addControl('jobStatus', new FormControl(''));
    this.form.addControl('employer_or_school_name', new FormControl(''));
    this.form.addControl('Race', new FormControl('', Validators.required));
    this.form.addControl('Ethnicity', new FormControl('', Validators.required));
    this.form.addControl(
      'MonitoringStartDate',

      new FormControl(
        {
          value: this.dateToday,
          disabled: true
        },
        [this.pastDateValidator.bind(this), noWhitespaceValidator]
      )
    );
    this.form.addControl('schoolStudentK12', new FormControl(''));
    this.form.addControl('studentCollege', new FormControl(''));
    this.form.addControl('daycare', new FormControl(''));
    this.form.addControl('healthcare', new FormControl(''));
    this.form.addControl('healthCare', new FormControl(''));
    this.form.addControl('corrections', new FormControl(''));
    this.form.addControl(
      'ageDisplay',
      new FormControl('', [
        // Validators.required,
        // this.numberValidator.bind(this)
      ])
    );
    this.form.addControl('Other_Gender', new FormControl(''));
    this.form.addControl('occupation', new FormControl(''));

    this.form.addControl('longTermCare', new FormControl(''));
    this.form.addControl('childCare', new FormControl(''));
    this.form.addControl('correctionalFacility', new FormControl(''));
    //this.form.addControl('firstResponder', new FormControl(''));
    this.form.addControl('contactWithPeople', new FormControl(''));
    this.form.addControl('industry_one', new FormControl(''));
    this.form.addControl('industry_two', new FormControl(''));
    this.form.addControl('medical_condition_one', new FormControl('', Validators.required));
    this.form.addControl('medical_condition_two', new FormControl('', Validators.required));
  }

  /*checkAreYouValue() {
    this.areYouOther = this.getValueFromFormControl('jobStatus') === 'other';
  }*/
  checkGenderValue() {
    this.isFemale = this.getValueFromFormControl('gender') === 'F';
  //   this.isOther = this.getValueFromFormControl('gender') === 'X';
  //   if (!this.isOther) {
  //     this.form.get('Other_Gender').setValue('');
  //   }

  //   let selectedGender = this.form.controls.gender.value;
  //   if (selectedGender == 'X') {
  //     this.form.controls.Other_Gender.setValidators([Validators.required]);
  //   }else{
  //     this.form.controls.Other_Gender.clearValidators();
  //   }
  //  this.form.controls.Other_Gender.updateValueAndValidity();
  }
  getValueFromFormControl(controlName) {

    return this.form.get(controlName).value;
  }

  isOtherGenderNeeded() {
    var selectedGender = this.form.controls.gender.value;
    if (selectedGender == 'X') {
      return true;
    }
    return false;
  }

  pastDateValidator(date) {
    if (moment().diff(moment(date.value)) < 1) {
      return { dateInFuture: true };
    }
    return null;
  }
  // Validates numbers
  numberValidator(number): any {
    const NUMBER_REGEXP = /^[\d.]+$/;
    if (number.value == '' || NUMBER_REGEXP.test(number.value)) {
      return null;
    }
    return {
      invalidNumber: true
    };
  }
  getData(): object {
    return {
      first_name: this.getValueFromFormControl('firstName'),
      middle_name: this.getValueFromFormControl('middleName'),
      last_name: this.getValueFromFormControl('lastName'),
      dob: this.getValueFromFormControl('dateOfBirth'),
      age: this.getValueFromFormControl('ageDisplay'),
      gender: this.getValueFromFormControl('gender'),
      other_gender: this.getValueFromFormControl('Other_Gender'),
      pregnant: this.showPregnancy()
        ? this.getValueFromFormControl('pregnancy')
        : false,
      mobile:
        this.getValueFromFormControl('phoneext') +
        '' +
        this.getValueFromFormControl('phone'),
      email: this.getValueFromFormControl('email'),
      landline:
        this.getValueFromFormControl('landline') !== ''
          ? this.getValueFromFormControl('landlineext') +
            '' +
            this.getValueFromFormControl('landline')
          : '',
      language: this.getValueFromFormControl('language'),
      //jobStatus: this.getValueFromFormControl('jobStatus'),
      is_essential_employee: this.getValueFromFormControl('essentialEmployee'),
      is_first_responder: this.getValueFromFormControl('firstResponder'),
      is_healthcare_worker: this.getValueFromFormControl('healthcareWorker'),
      occupation: this.getValueFromFormControl('occupation'),
      work_or_volunteer: {
        Healthcare: this.getValueFromFormControl('healthCare'),
        'Long Term Care': this.getValueFromFormControl('longTermCare'),
        'Child Care': this.getValueFromFormControl('childCare'),
        'Correctional Facility': this.getValueFromFormControl(
          'correctionalFacility'
        ),
        'As a First Responder': this.getValueFromFormControl('firstResponder'),
        'A Setting With Lots of Contact With People': this.getValueFromFormControl(
          'contactWithPeople'
        )
      },

      /*schoolStudentK12: this.getValueFromFormControl('schoolStudentK12'),
      studentCollege: this.getValueFromFormControl('studentCollege'),
      daycare: this.getValueFromFormControl('daycare'),
      healthcare: this.getValueFromFormControl('healthcare'),
      corrections: this.getValueFromFormControl('corrections'),*/

      employer_or_school_name: this.getValueFromFormControl('employer_or_school_name'),
      race: this.getValueFromFormControl('Race'),
      ethnicity: this.getValueFromFormControl('Ethnicity'),
      industry_one: this.getValueFromFormControl('industry_one'),
      industry_two: this.getValueFromFormControl('industry_two'),
      medical_condition_one: this.getValueFromFormControl(
        'medical_condition_one'
      ),
      medical_condition_two: this.getValueFromFormControl(
        'medical_condition_two'
      ),
      start_date:
        moment(this.getValueFromFormControl('MonitoringStartDate')).format(SQL_DATE)
    };
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  addFormClass() {
    this.checkError(null);
    this.formClass = 'submittedform';
  }

  onRaceChange(event){
    let selectValues = event.value;
    const activeEvent = event.source._keyManager;
    const value = activeEvent._activeItem.value;
    if(value=='Unknown' || value=='Other'){
      if(selectValues.includes(value)){        
        event.source.value = [value];
        this.form.get('Race').setValue([value]);
      }
    }else{
      _.remove(selectValues, function(currentObject) {
        return (currentObject == 'Other' || currentObject == 'Unknown');
      });
      this.form.get('Race').setValue(selectValues);
    }
  }
}
