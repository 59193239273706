import {
  Component,
  OnInit,
  Inject,
  Output,
  Type,
  EventEmitter,
  Input
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import _ from 'lodash';
import { AddAddressDialogComponent } from '../../../components/add-address-dialog/add-address-dialog.component';
import {
  FormGroup,
  ValidatorFn,
  AbstractControl,
  FormControl,
  Validators
} from '@angular/forms';
export interface AddDialogData {
  type: string;
  street: string;
  city: string;
  state: string;
  county: string;
  zip: string;
  addressTypes: [];
}

@Component({
  selector: 'app-address-page',
  templateUrl: './address-page.component.html',
  styleUrls: ['./address-page.component.scss']
})
export class AddressPageComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input() total_pages: any;
  @Input() errortext: any;
  @Output() onAddressUpdattion: EventEmitter<any>;
  displayedColumns: string[] = [
    'action',
    'type',
    'street',
    'city',
    'county',
    'state',
    'zip'
  ];
  containsLocation = false;
  permanentaddressAvailable = false;

  similarCheckboxdisabled = false;
  addresses = [];
  checkPP = false;

  addressTypes = [
    {
      label: 'ADDRESS_COMMON.current',
      value: 'current',
      isVisible: true
    },
    {
      label: 'ADDRESS_COMMON.permanent',
      value: 'permanent',
      isVisible: true
    }
  ];
  defaultAddressTypes = this.addressTypes;
  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.form.addControl(
      'checkbox',
      new FormControl('', this.addressesValidator.bind(this))
    );
  }

  addressesValidator(control: AbstractControl): { [key: string]: any } | null {
    if (this.addresses && this.addresses.length > 0) {
      const hasPermanentAddress = this.addresses.find(address => {
        return address.type === 'permanent';
      });
      const hasLocateAddress = this.addresses.find(address => {
        return address.type === 'current';
      });
      return hasPermanentAddress && hasLocateAddress
        ? null
        : !hasPermanentAddress &&
          hasLocateAddress &&
          !this.form.get('checkbox').value
        ? { error: 'ADDRESS_INFO.select_checkbox' }
        : hasPermanentAddress &&
          !hasLocateAddress &&
          !this.form.get('checkbox').value
        ? { error: 'ADDRESS_INFO.current_add' }
        : !hasPermanentAddress && !hasLocateAddress
        ? { error: 'ADDRESS_INFO.current_premanent' }
        : null;
    } else {
      return { error: 'ADDRESS_INFO.one' };
    }
  }

  filterf = () => {
    var updatedAddressTypes = [];
    this.containsLocation = false;
    var locationaddressAvailable = false;
    if (_.find(this.addresses, { type: 'current' })) {
      locationaddressAvailable = true;
      this.containsLocation = true;
    }
    this.permanentaddressAvailable = false;
    if (_.find(this.addresses, { type: 'permanent' })) {
      this.permanentaddressAvailable = true;
    }
    console.log(locationaddressAvailable,
      this.permanentaddressAvailable);

    this.similarCheckboxdisabled = false;
    if (locationaddressAvailable && this.permanentaddressAvailable) {
      this.checkPP = false;
      this.similarCheckboxdisabled = true;
      this.form.get('checkbox').setValue(false);
      // show msg that seperate address are available
    } else if (!locationaddressAvailable && this.permanentaddressAvailable) {
      if (!this.checkPP) {
        updatedAddressTypes.push({
          label: 'ADDRESS_COMMON.current',
          value: 'current',
          isVisible: true
        });
      }
    } else if (locationaddressAvailable && !this.permanentaddressAvailable) {
      if (!this.checkPP) {
        updatedAddressTypes.push({
          label: 'ADDRESS_COMMON.permanent',
          value: 'permanent',
          isVisible: true
        });
      }
    } else if (!locationaddressAvailable && !this.permanentaddressAvailable) {
      updatedAddressTypes.push({
        label: 'ADDRESS_COMMON.current',
        value: 'current',
        isVisible: true
      });
      updatedAddressTypes.push({
        label: 'ADDRESS_COMMON.permanent',
        value: 'permanent',
        isVisible: true
      });
    }

    this.addressTypes = [...updatedAddressTypes];
  };

  toggleCheckBoxPP = event => {
    if (event.checked) {
      this.checkPP = true;
    } else {
      this.checkPP = false;
    }
    this.filterf();
  };

  clickCheckBoxPP = event => {
      if(_.find(this.addresses, {type: "current"}) && _.find(this.addresses, {type: "permanent"})) {
        //this.errortext = "You have added different permanent and location address. Hence, they cannot be same.";
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
  }

  openDialog(): void {
    this.filterf();
    const dialogRef = this.dialog.open(AddAddressDialogComponent, {
      width: '90vw',
      // data: { addressTypes: 'current' }
      data: { addressTypes: this.addressTypes }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && !result.isEdit) {
        this.addresses.push(result);
        this.addresses = [...this.addresses];
      }
      this.filterf();
      this.form.controls.checkbox.updateValueAndValidity();
    });
  }
  stepperChanged(event: any, source: string) {
    //console.log('Check');
  }

  deleteAddress = address => {
    this.addresses = _.filter(this.addresses, function(a) {
      return a.id !== address.id;
    });
    this.filterf();
    this.form.controls.checkbox.updateValueAndValidity();
  };

  editAddress = address => {
    address.isEdit = true;
    this.filterf();
    const dialogRef = this.dialog.open(AddAddressDialogComponent, {
      width: '600px',
      data: { currentAddress: address }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isEdit) {
        var searchedIndex = _.findIndex(this.addresses, function(a) {
          return a.id == result.id;
        });
        this.addresses[searchedIndex] = result;
        this.addresses = [...this.addresses];
      }
      this.filterf();
      this.form.controls.checkbox.updateValueAndValidity();
    });
  };

  get isAddButtonDisable() {
    return this.addresses.length == 2 || (this.checkPP && this.addresses.length != 0);
  }

  getData() {
    console.log('this is in getDAta');
    
    return this.addresses;
  }
}
