import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';
import config from '../app.config';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  accessToken = '';
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  getAccessToken() {
    if (this.localStorageService.get('accessToken')) {
      this.accessToken = this.localStorageService.get('accessToken')['id'];
    }
    return this.accessToken;
  }

  signUp(user) {
    const url = environment.backendUrl + '/contacts';
    return this.http.post(url, user);
  }

  login(user) {
    const url = environment.backendUrl + '/contacts/login?include=user';
    return this.http.post(url, user);
  }

  logout() {
    const url = environment.backendUrl + '/contacts/logout';
    return this.http.get(url, { params: { access_token: this.accessToken } });
  }

  reset(accessToken, data) {
    const url = environment.backendUrl + '/contacts/reset-contact-password';
    return this.http.post(url, data, { params: { access_token: accessToken } });
  }

  forgot(data) {
    const url = environment.backendUrl + '/contacts/reset';
    return this.http.post(url, data);
  }

  async savePassengerInformation(data: object, captchaResponse) {
    const url = environment.backendUrl + '/intake';
    return this.http.post(url, data, {
      headers: {
        authorization: captchaResponse
      }
    }).toPromise();

  }

  sendVerificationSms(data: object) {
    const url = environment.backendUrl + '/send-verification-code';
    return this.http.post(url, data);
  }

  updatenewFollowUp(questions) {
    const url =
      environment.backendUrl + '/intake/update-followups';
    return this.http.put(url, questions);
  }

  verifyCode(data: object) {
    const url = environment.backendUrl + '/verify-code';
    return this.http.post(url, data);
  }

  checkMobileDOB(data) {
    const url = environment.backendUrl + '/intake/check-mobile-dob-unique';
    return this.http.post(url, data);
  }

  async getConfigdata() {
    const url = `${environment.backendUrl}/config/intake`;
    return this.http.get(url).toPromise();
  }

  async sendConsent(data, captchaResponse) {
    const url = `${environment.backendUrl}/text-messaging/intake`;
    console.log('Consent URL: ' + url);
    return this.http.post(url, data, {
      headers: {
        authorization: captchaResponse
      }
    }).toPromise();
  }

  getTravellerLanguage(id) {
    const url = `${environment.backendUrl}/intake/lang/`+id;
    return this.http.get(url);
  }

}
