<div [formGroup]="form">

    <div class="card-header">
        <h2 class="card-title"> {{'ADDRESS_INFO.address_info' | translate }} </h2>
        <div class="step-count">{{'ADDRESS_INFO.step' | translate }} {{total_pages}}, {{'ADDRESS_INFO.step_closer' | translate }}</div>
        <div class="step-count">{{'ADDRESS_INFO.address_desc' | translate }}</div>
    </div>

    <div class="card-content">
        <div class="error" *ngIf="errortext && !isAddButtonDisable" aria-live="assertive" role="alert">
            <div class="description">{{errortext | translate}}</div>
        </div>

        <app-address-card (onDelete)="deleteAddress($event)" (onEdit)="editAddress($event)" *ngFor="let address of addresses" [address]="address"></app-address-card>

        <div *ngIf="form.errors">{{'PERSONAL_INFO.oops_error' | translate }}</div>

        <div class="p-b-md" *ngIf="containsLocation || permanentaddressAvailable">
            <mat-checkbox [disabled]="similarCheckboxdisabled" color="primary" (click)="clickCheckBoxPP($event)" (change)="toggleCheckBoxPP($event)" formControlName="checkbox">
                {{permanentaddressAvailable ? ('ADDRESS_COMMON.permanent_current_same' | translate):('ADDRESS_COMMON.current_same_permanent' | translate)}}
            </mat-checkbox>
            <!-- <mat-error *ngIf="similarCheckboxdisabled">Permanent and Location addresses already present. Please delete one to check this chechbox.</mat-error> -->
        </div>




        <button [disabled]="isAddButtonDisable" (click)="openDialog()" color="primary" mat-stroked-button class="m-b-md">
            {{'ADDRESS_INFO.add_address' | translate }}
        </button>
    </div>

    <div>