<a class="skip-link" role="link" tab-index="0" href="/intake-form#main-content">Skip to the main content</a>

<div class="page-wrapper">
    <div class="loading-spinner-div" *ngIf="loadingApplication">
        <mat-spinner></mat-spinner>
    </div>
    <header role="banner">
        <app-toolbar></app-toolbar>
        <app-information-timeline></app-information-timeline>
    </header>
    <div class="container-lg main-wrap">
        <div class="content-container sidebar-layout has-banner">
            <div *ngIf="true; then stepperBlock"></div>
            <div *ngIf="true; then intakeBlock"></div>
        </div>
    </div>

    <ng-template #stepperBlock>
        <nav class="sidebar" role="navigation">
            <mat-vertical-stepper [linear]="true" [disableRipple]="true" [selectedIndex]="selectedIndex" #stepperPane (selectionChange)="stepperChanged($event, 'content')">
                <!-- Custom icon with a context variable. -->
                <ng-template matStepperIcon="edit" let-index="index">
                    {{index + 1}}
                </ng-template>

                <mat-step *ngFor="let step of steps; let i=index" [aria-label]="getAriaLabelForStepper(i)">
                    <ng-template matStepLabel>{{step | translate}}</ng-template>
                </mat-step>

            </mat-vertical-stepper>
        </nav>
    </ng-template>

    <ng-template #intakeBlock>
        <main class="padder" role="main" id="main-content">
            <div class="content stepper-content" >
                <mat-vertical-stepper [linear]="true" (selectionChange)="stepperChanged($event, 'content')" #stepperContent>

                    <mat-step>
                        <ng-template matStepLabel>{{'SIDEBAR.instructions' |translate}}</ng-template>

                        <div class="card">
                            <app-instruction-page [errortext]="stepError" [total_pages]="total_pages">
                            </app-instruction-page>
                            <div class="card-content">
                                <div class="intro-block">

                                    <p class="intro" [innerHTML]="'INSTRUCTION_PAGE.interest' | translate">
                                    </p>

                                    <!-- <h3>{{'INSTRUCTION_PAGE.feeling_sick' | translate }}</h3> -->
                                    <!-- <h3>Please select from the following options ?</h3> -->
                                    <div class="border-block" role="group" attr.aria-label="{{'INSTRUCTION_PAGE.select_options' | translate }}">
                                        <div class="field-label">{{'INSTRUCTION_PAGE.select_options' | translate }}</div>
                                        <mat-checkbox class="" (change)="checkSymptomOption($event,'symptomOption1')">
                                            <p class="m-t-0 " [innerHTML]="'INSTRUCTION_PAGE.select_options_1' | translate"></p>
                                            <p *ngIf="selectedSymptomOption.indexOf('symptomOption1') !== -1" class="m-b-0 instructions-checkbox" [innerHTML]="'INSTRUCTION_PAGE.option_1_help' | translate "></p>
                                        </mat-checkbox>

                                        <mat-checkbox class="" (change)="checkSymptomOption($event,'symptomOption2')">
                                            <p class="m-t-0 " [innerHTML]="'INSTRUCTION_PAGE.select_options_2' | translate"></p>
                                        </mat-checkbox>

                                        <mat-checkbox class="" (change)="checkSymptomOption($event,'symptomOption3')">
                                            <p class="m-t-0 " [innerHTML]="'INSTRUCTION_PAGE.select_options_3' | translate"></p>
                                        </mat-checkbox>

                                        <mat-checkbox class="" (change)="checkSymptomOption($event,'symptomOption4')">
                                            <p class="m-t-0 " [innerHTML]="'INSTRUCTION_PAGE.select_options_4' | translate"></p>
                                        </mat-checkbox>
                                    </div>
                                    <!--<p class="intro">Please use the Symptom Checker to learn more about COVID-19 from the Chicago Department of Public Health.</p>-->
                                    <!--<p class="intro">Answer questions designed by the Chicago Department of Public Health (CDPH) about you and your health. You’ll get text messages based on your answers to check up on how you’re feeling, info about tests, and you'll be preregistered for the CDPH COVID vaccine when available.</p>
                                    <p>If you have any additional questions, please email us at <a href="mailto:coronavirus@chicago.gov">coronavirus@chicago.gov</a> or call <a href="tel: 312-746-4835">312-746-4835</a>. </p>
                                    <p>If you don’t have a health care provider or medical insurance, there are approximately 165 community health centers throughout Chicago, so find one that is close to you at <a target="_blank" href="https://findahealthcenter.hrsa.gov">findahealthcenter.hrsa.gov</a>.
                                        Call ahead and they will work with you to coordinate appropriate care.
                                    </p>-->

                                    <p class="intro">
                                        {{'INSTRUCTION_PAGE.first' | translate }} </p>
                                    <ul>
                                        <li [innerHTML]="'INSTRUCTION_PAGE.warning_sign1' | translate"></li>
                                        <li [innerHTML]="'INSTRUCTION_PAGE.warning_sign2' | translate"></li>
                                        <li [innerHTML]="'INSTRUCTION_PAGE.warning_sign3' | translate"></li>
                                        <li [innerHTML]="'INSTRUCTION_PAGE.warning_sign4' | translate"></li>
                                    </ul>

                                    <h2 class="intro">
                                        {{'INSTRUCTION_PAGE.when_get_tester' | translate }} </h2>
                                    <ul>
                                        <li [innerHTML]="'INSTRUCTION_PAGE.when_tested_1' | translate"></li>
                                        <li [innerHTML]="'INSTRUCTION_PAGE.when_tested_2' | translate"></li>
                                    </ul>


                                    <!-- <p>
                                        {{'INSTRUCTION_PAGE.notify' | translate }}
                                    </p> -->
                                    <p>
                                        {{'INSTRUCTION_PAGE.link' | translate }} <a target="_blank" aria-label="findahealthcenter.hrsa.gov Link opens in a new tab" href="https://findahealthcenter.hrsa.gov">findahealthcenter.hrsa.gov</a>. {{'INSTRUCTION_PAGE.call_care' | translate
                                        }}
                                    </p>
                                    <p class="m-b-0" [innerHTML]="'INSTRUCTION_PAGE.add_ques' | translate "></p>

                                </div>
                                <div class="border-block">
                                    <div class="field-label">{{'INSTRUCTION_PAGE.review' | translate }}</div>
                                    <mat-checkbox class="" (change)="checkUncheckTermsCheckBox($event)" aria-required="true" required>
                                        <p class="m-t-0 ">
                                            {{'INSTRUCTION_PAGE.i_understand' | translate}}
                                            <a href='mailto:coronavirus@chicago.gov' >coronavirus@chicago.gov </a>
                                            {{'INSTRUCTION_PAGE.or_call' | translate}}
                                            <a href='tel: 312-746-4835' >312-746-4835 </a>.
                                            <abbr class="indicator">*</abbr>
                                        </p>
                                        <!-- <p class="m-b-0">To read the City of Chicago’s Privacy Policy, please visit <a class="txt-underline" href="https://www.chicago.gov/city/en/general/privacy.html" target="_blank">The City of Chicago’s Privacy Policy</a>. I also consent
                                            to receive text and/or email messages.</p> -->
                                            <p class="m-b-0" [innerHTML]="'INSTRUCTION_PAGE.read_privacy' | translate "></p>        
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="controls">
                                <div class="secondary"></div>
                                <div class="primary d-flex">
                                    <button mat-button mat-flat-button color="primary" (click)="checkStepValidation(0)" [disabled]="isEnableInstructionPageButton">{{ 'NAVIGATION_BUTTON.continue' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [stepControl]="passengerInformation">
                        <ng-template matStepLabel>Personal Information</ng-template>
                        <form [formGroup]="passengerInformation" (ngSubmit)="checkStepValidation(1)">
                            <div class="card">
                                <app-passenger-information [errortext]="stepError" [total_pages]="total_pages" [form]="passengerInformation" #passengerInformationComponent>
                                </app-passenger-information>
                                <div class="controls">
                                    <div class="secondary"></div>
                                    <div class="primary d-flex">
                                        <button class="order-last" mat-button mat-flat-button type="submit" color="primary">{{ 'NAVIGATION_BUTTON.continue' | translate }}</button>
                                        <button mat-button mat-flat-button type="button" matStepperPrevious (click)="changeMobileNav(1)">{{ 'NAVIGATION_BUTTON.previous' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-step>


                    <mat-step [stepControl]="addressInformation">
                        <ng-template matStepLabel>Address Information</ng-template>
                        <form [formGroup]="addressInformation">
                            <div class="card">
                                <app-address-page [errortext]="stepError" [total_pages]="total_pages" [form]="addressInformation" (onAddressUpdation)="onAddressUpdation($event)" #addressPageComponent>
                                </app-address-page>
                                <div class="controls">
                                    <div class="secondary"></div>
                                    <div class="primary d-flex">
                                        <button class="order-last" type="submit" mat-button mat-flat-button color="primary" (click)="checkStepValidation(2)">{{ 'NAVIGATION_BUTTON.continue' | translate }}</button>
                                        <button type="button" mat-button mat-flat-button matStepperPrevious (click)="changeMobileNav(2)">{{ 'NAVIGATION_BUTTON.previous' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-step>

                    <mat-step *ngIf="showGuardianInfo">
                        <ng-template matStepLabel>Gaurdian Information</ng-template>
                        <div class="card">
                            <app-guardian-information [total_pages]="total_pages" [errortext]="stepError" #guardianInformationComponent>
                            </app-guardian-information>
                            <div class="controls">
                                <div class="secondary"></div>
                                <div class="primary d-flex">
                                    <button class="order-last" mat-button mat-flat-button color="primary" [disabled]="loadingApplication" (click)="checkStepValidation(3)">{{ 'NAVIGATION_BUTTON.continue' | translate }}</button>
                                    <button mat-button mat-flat-button matStepperPrevious (click)="changeMobileNav(3)">{{ 'NAVIGATION_BUTTON.previous' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Symptoms Information</ng-template>
                        <form [formGroup]="symptomsInformation" (ngSubmit)="stpe4Submit();">
                            <div class="card">
                                <app-symptoms-information [errortext]="stepError" [total_pages]="total_pages" [form]="symptomsInformation" #symptomsInformationComponent></app-symptoms-information>
                                <div class="controls">
                                    <div class="secondary"></div>
                                    <div class="primary d-flex">
                                        <button class="order-last" mat-button mat-flat-button color="primary" type="submit">{{ 'NAVIGATION_BUTTON.submit' | translate }}</button>
                                        <button mat-button mat-flat-button matStepperPrevious (click)="changeMobileNav(4)">{{ 'NAVIGATION_BUTTON.previous' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-step>


                </mat-vertical-stepper>
            </div>
        </main>
    </ng-template>
    <div class="recaptcha">
        <re-captcha #captchaRef="reCaptcha" [badge]="deviceDetector.isMobile() ? 'inline' : ''" siteKey="{{ captchaKey }}" lang="fr" size="invisible" (resolved)="$event && captchaSubmit($event)"></re-captcha>

    </div>
</div>
<div role="contentinfo">
    <app-footer></app-footer>
</div>