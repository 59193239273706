<mat-card class="address-card">
    <mat-card-header>
        <mat-card-title class="thin-weight capitalize">
            {{(address.type == 'current')?('ADDRESS_COMMON.current' | translate):('ADDRESS_COMMON.permanent' | translate)}}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-sm-9">
                <div class="row horizontal-overflow-hidden">
                    <div class="col-auto p-b">
                        <div class="card-label">{{'ADDRESS_COMMON.street_address_html' | translate}}</div>
                        <div class="card-para">{{address.address}}</div>
                    </div>
                    <div class="col-auto p-b">
                        <div class="card-label">{{'ADDRESS_COMMON.city_html' | translate}}</div>
                        <div class="card-para">{{address.city}}</div>
                    </div>
                    <div class="col-auto p-b">
                        <div class="card-label">{{'ADDRESS_COMMON.state_html' | translate}}</div>
                        <div class="card-para">{{address.state}}</div>
                    </div>
                    <div class="col-auto p-b" *ngIf="address.county">
                        <div class="card-label">{{'ADDRESS_COMMON.country_html' | translate}}</div>
                        <div class="card-para">{{address.county}}</div>
                    </div>
                    <div class="col-auto p-b">
                        <div class="card-label">{{'ADDRESS_COMMON.country_html' | translate}}</div>
                        <div class="card-para">{{address.country}}</div>
                    </div>
                    <div class="col-auto p-b">
                        <div class="card-label">{{'ADDRESS_COMMON.zip_html' | translate}}</div>
                        <div class="card-para">{{address.zip}}</div>
                    </div>
                    <div class="col-auto p-b">
                        <div class="card-label">{{'ADDRESS_COMMON.no_of' | translate}}</div>
                        <div class="card-para">{{address.total_members_in_household}}</div>
                    </div>
                </div>
            </div>
            <div class="card-controls">
                <button mat-button (click)="openConfirmationDialog()">
                    <mat-icon aria-hidden="false" attr.aria-label="{{(address.type == 'current')?('ADDRESS_COMMON.current_address' | translate):('ADDRESS_COMMON.permanent_address' | translate)}} {{'COMMON_WORDS.delete_outline' | translate}}">delete_outline</mat-icon>
                </button>
                <button mat-button (click)="editAddress()">
                    <mat-icon aria-hidden="false" attr.aria-label="{{(address.type == 'current')?('ADDRESS_COMMON.current_address' | translate):('ADDRESS_COMMON.permanent_address' | translate)}} {{'COMMON_WORDS.edit' | translate}}">edit</mat-icon>
                </button>
            </div>

            <!--
            <div class="col-sm-12 col-xs-12" *ngIf="address.emergencycheckbox">
                <div class="card-label">This is a Emergency/Temporary Address.</div>
            </div>
            -->
        </div>
    </mat-card-content>

</mat-card>