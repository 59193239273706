export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAsPfCcdMRzty2T9F6ZB7gzc_bQKtlCHgQ",
    authDomain: "cdph-erm.firebaseapp.com",
    databaseURL: "https://cdph-erm.firebaseio.com",
    projectId: "cdph-erm",
    storageBucket: "cdph-erm.appspot.com",
    messagingSenderId: "210426268619",
    appId: "1:210426268619:web:33b4a3c81ae5d46ea0044e"
  },
  backendUrl: 'https://api-server.cdph-erm.erm.mtxb2b.com',
  logoutUrl: '',
  activeSessionTimeout: 3600, // in seconds
  activeSessionTimeoutWarning: 3540, // in seconds, must be less than "activeSessionTimeout",
  authSamlProvider: 'saml.iop',
  isAdvancedUploader: true,
  googleMapApi: '',
  recaptchaKey: '6LeN5sEZAAAAAFx4o27LpnSBVB9l72i0Y86OEULf'
};