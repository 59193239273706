<form [formGroup]="followupForm">
    <div *ngIf="!submitted">
        <div class="p-b">
            <div class="form-element-container m-t">
                <div class="field-label">{{'FOLLOW_UP.symptom_start' | translate}}</div>
                <mat-form-field appearance="outline" class="input-group-small" required>
                    <mat-label>{{'FOLLOW_UP.symotom_date' | translate}}</mat-label>
                    <input matInput [max]="dateToday" [matDatepicker]="symtompicker" formControlName="symptomsStart" required attr.aria-label="{{'FOLLOW_UP.symptom_start_date' | translate }}"/>
                    <mat-datepicker-toggle matSuffix [for]="symtompicker"></mat-datepicker-toggle>
                    <mat-datepicker #symtompicker></mat-datepicker>
                    <mat-error>{{'FOLLOW_UP.date_req' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="form-element-container m-t">
                <div class="field-label">{{'FOLLOW_UP.fever' | translate}} <abbr class='indicator' aria-hidden='true'>*</abbr></div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.fever' | translate}}" formControlName="typeofTemp" (change)="removeTep($event)">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="no">
                                {{'FOLLOW_UP.temp_f' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="yes">
                                {{'FOLLOW_UP.temp_c' | translate}}</mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>

                <mat-form-field *ngIf="typeofTemp=='no'" appearance="outline" class="input-group-small">
                    <mat-label><span class="sr-only">{{'SYMPTOMS_PAGE.wat_was_the' | translate }} </span>{{'FOLLOW_UP.temp' | translate}}</mat-label>
                    <input matInput placeholder="{{'SYMPTOMS_PAGE.wat_was_the' | translate}}{{'FOLLOW_UP.temp' | translate}}" formControlName="fttemperature" [errorStateMatcher]="matcher">
                    <mat-error *ngIf="followupForm.hasError">{{'FOLLOW_UP.f_value' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="typeofTemp=='yes'" appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.temp' | translate}}</mat-label>
                    <input matInput placeholder="{{'SYMPTOMS_PAGE.wat_was_the' | translate}}{{'FOLLOW_UP.temp' | translate}}" formControlName="cstemperature" [errorStateMatcher]="matcher">
                    <mat-error *ngIf="followupForm.hasError">{{'FOLLOW_UP.c_value' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="form-element-container m-t">
                <div class="field-label">{{'FOLLOW_UP.anti_fever' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.anti_fever' | translate}}" formControlName="antiFeverMedicine">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="yes">
                                {{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no"> {{'COMMON_WORDS.no' | translate}}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('antiFeverMedicine') == 'yes'">
                <div class="field-label">{{'FOLLOW_UP.medication' | translate}}</div>
                <mat-form-field attr.aria-label="{{'FOLLOW_UP.medication' | translate}}" appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.medicine' | translate}}</mat-label>
                    <input matInput placeholder="{{'FOLLOW_UP.medication' | translate}}" formControlName="medicines" [errorStateMatcher]="matcher">
                </mat-form-field>
            </div>
            <div class="form-element-container m-t">
                <div class="field-label">{{'FOLLOW_UP.symptoms_severe' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.symptoms_severe' | translate}}" formControlName="symptomSevere">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="yes">
                                {{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no"> {{'COMMON_WORDS.no' | translate}}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t">
                <div class="field-label">{{'FOLLOW_UP.did_healthcare' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.did_healthcare' | translate}}" formControlName="healthCareFacility">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="yes">
                                {{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no"> {{'COMMON_WORDS.no' | translate}}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('healthCareFacility') == 'yes'">
                <div class="field-label">{{'FOLLOW_UP.healthcare_facility' | translate}}</div>
                <mat-form-field appearance="outline" >
                    <mat-label>{{'FOLLOW_UP.healthcare_facility_type' | translate}}</mat-label>
                    <mat-select formControlName="typeOfhealthCareFacility" attr.aria-label="{{'FOLLOW_UP.healthcare_facility' | translate}}" role="combobox">
                        <mat-option role="option" value="ER/Hospital">{{'FOLLOW_UP.Hospital' | translate}}</mat-option>
                        <mat-option role="option" value="Urgent Care">{{'FOLLOW_UP.urgent_care' | translate}}</mat-option>
                        <mat-option role="option" value="Doctor's Office">{{'FOLLOW_UP.doc_off' | translate}}</mat-option>
                        <mat-option role="option" value="Other">{{'FOLLOW_UP.anti_fever' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('typeOfhealthCareFacility') == 'Other'">
                <div class="field-label">{{'FOLLOW_UP.other' | translate}}</div>
                <mat-form-field appearance="outline" attr.aria-label="{{'FOLLOW_UP.other' | translate}}" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.facility_type' | translate}}</mat-label>
                    <input matInput placeholder="{{'FOLLOW_UP.other' | translate}}" formControlName="otherhealthCareFacility" [errorStateMatcher]="matcher">
                </mat-form-field>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('healthCareFacility') == 'yes'">
                <div class="field-label">{{'FOLLOW_UP.facility_name' | translate}}</div>
                <mat-form-field appearance="outline" class="input-group-small" attr.aria-label="{{'FOLLOW_UP.facility_name' | translate}}">
                    <mat-label>{{'FOLLOW_UP.facility_name' | translate}}</mat-label>
                    <input matInput placeholder="{{'FOLLOW_UP.facility_name' | translate}}" formControlName="facilityName" [errorStateMatcher]="matcher">
                </mat-form-field>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('healthCareFacility') == 'yes'">
                <div class="field-label">{{'FOLLOW_UP.last_visit_date' | translate}}</div>
                <mat-form-field appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.last_visit_date' | translate}}</mat-label>
                    <input matInput [max]="dateToday" [matDatepicker]="picker" attr.aria-label="{{'FOLLOW_UP.last_visit_date_format' | translate}}" formControlName="lastVisitDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-element-container m-t">
                <div class="field-label">{{'FOLLOW_UP.group' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.group' | translate}}" formControlName="sheltingHome">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="yes">
                                {{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no"> {{'COMMON_WORDS.no' | translate}}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('sheltingHome') == 'yes'">
                <div class="field-label">{{'FOLLOW_UP.name_group' | translate}}:</div>
                <mat-form-field appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.name_group' | translate}}</mat-label>
                    <input matInput placeholder="{{'FOLLOW_UP.name_group' | translate}}" formControlName="nameOfSheltingHome" [errorStateMatcher]="matcher">
                </mat-form-field>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('sheltingHome') == 'yes'">
                <div class="field-label">{{'FOLLOW_UP.address_group' | translate}}:</div>
                <mat-form-field appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.address_group' | translate}}</mat-label>
                    <textarea matInput placeholder="{{'FOLLOW_UP.address_group' | translate}}" formControlName="addressOfSheltingHome" [errorStateMatcher]="matcher"></textarea>
                </mat-form-field>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('sheltingHome') == 'no'">
                <div class="field-label">{{'FOLLOW_UP.residence' | translate}}</div>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="typeOfResidence" attr.aria-label="{{'FOLLOW_UP.residence' | translate}}" role="combobox">
                        <mat-option role="option" value="Single-family home">{{'FOLLOW_UP.single_family' | translate}}
                        </mat-option>
                        <mat-option role="option" value="Multi-family home">{{'FOLLOW_UP.mutli_family' | translate}}
                        </mat-option>
                        <mat-option role="option" value="Townhouse">{{'FOLLOW_UP.townhouse' | translate}}</mat-option>
                        <mat-option role="option" value="Condominium">{{'FOLLOW_UP.condominium' | translate}}</mat-option>
                        <mat-option role="option" value="Apartment building">{{'FOLLOW_UP.apartment' | translate}} building
                        </mat-option>
                        <mat-option role="option" value="Co-op">{{'FOLLOW_UP.co_op' | translate}}-op</mat-option>
                        <mat-option role="option" value="Other">{{'FOLLOW_UP.other' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('typeOfResidence') == 'Other'">
                <div class="field-label">{{'FOLLOW_UP.other_type' | translate}}</div>
                <mat-form-field appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.other_residence' | translate}}</mat-label>
                    <input matInput placeholder="Other residence" formControlName="otherResidence" [errorStateMatcher]="matcher">
                </mat-form-field>
            </div>
            <div class="form-element-container m-t">
                <div class="field-label">{{'FOLLOW_UP.self_isolating' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.self_isolating' | translate}}" formControlName="selfIsolating">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="yes">
                                {{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no"> {{'COMMON_WORDS.no' | translate}}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('selfIsolating') == 'yes'">
                <div class="field-label">{{'FOLLOW_UP.where' | translate}}</div>
                <mat-form-field appearance="outline">
                    <mat-label>{{'FOLLOW_UP.where' | translate}}</mat-label>
                    <mat-select formControlName="whereIsolating" attr.aria-label="{{'FOLLOW_UP.where' | translate}}" role="combobox">
                        <mat-option role="option" value="Home">{{'FOLLOW_UP.home' | translate}}</mat-option>
                        <mat-option role="option" value="Hospital">{{'FOLLOW_UP.hospital' | translate}}</mat-option>
                        <mat-option role="option" value="Other">{{'FOLLOW_UP.other' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('whereIsolating') == 'Other'">
                <div class="field-label">{{'FOLLOW_UP.other' | translate}}</div>
                <mat-form-field appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.other' | translate}}</mat-label>
                    <input matInput placeholder="Other" formControlName="otherIsolating" [errorStateMatcher]="matcher">
                </mat-form-field>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('selfIsolating') == 'yes' && getFormValue('whereIsolating') == 'Home'">
                <div class="field-label">{{'FOLLOW_UP.if_home' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.if_home' | translate}}" formControlName="otherPeopleInHousehold">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="yes">
                                {{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no"> {{'COMMON_WORDS.no' | translate}}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('whereIsolating') == 'Home' && getFormValue('otherPeopleInHousehold') == 'yes'">
                <div class="field-label">{{'FOLLOW_UP.how_many' | translate}}</div>
                <mat-form-field appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.pls_enter' | translate}}</mat-label>
                    <input matInput placeholder="Please enter" formControlName="howManyPeopleInHousehold" [errorStateMatcher]="matcher">
                </mat-form-field>
            </div>
            <div class="form-element-container m-t">
                <div class="field-label">{{'FOLLOW_UP.tested_covid' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.tested_covid' | translate}}" formControlName="tested">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="Yes">
                                {{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="No">{{'COMMON_WORDS.no' | translate}}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('tested') == 'Yes'">
                <div class="field-label">{{'FOLLOW_UP.type_of_test' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.type_of_test' | translate}}" formControlName="testType">
                        <div class="row">
                            <mat-radio-button class="col-auto p-b" value="PCR">{{'FOLLOW_UP.pcr' | translate}}
                            </mat-radio-button>
                            <mat-radio-button class="col-auto p-b" value="Serology">
                                {{'FOLLOW_UP.serology' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto p-b" value="Doesn't Know">
                                {{'FOLLOW_UP.dont_know' | translate}}</mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('testType') == 'PCR'">
                <div class="field-label">{{'FOLLOW_UP.pcr_test' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.pcr_test' | translate}}" formControlName="pcrTest">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="Positive">
                                {{'FOLLOW_UP.positive' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="Negative">
                                {{'FOLLOW_UP.negative' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="Doesn't Know">
                                {{'FOLLOW_UP.dont_know' | translate}}</mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('testType') == 'PCR'">
                <div class="field-label">{{'FOLLOW_UP.pcr_date' | translate}}</div>
                <mat-form-field appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.pcr_date' | translate}}</mat-label>
                    <input matInput [max]="dateToday" [matDatepicker]="picker" attr.aria-label="{{'FOLLOW_UP.pcr_date_format' | translate}}" formControlName="pcrResultDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('testType') == 'Serology'">
                <div class="field-label">{{'FOLLOW_UP.igm_test' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.igm_test' | translate}}" formControlName="igmTest">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="Positive">
                                {{'FOLLOW_UP.positive' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="Negative">
                                {{'FOLLOW_UP.negative' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="Doesn't Know">
                                {{'FOLLOW_UP.dont_know' | translate}}</mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('testType') == 'Serology'">
                <div class="field-label">{{'FOLLOW_UP.igm_date' | translate}}</div>
                <mat-form-field appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.igm_date' | translate}}</mat-label>
                    <input matInput [max]="dateToday" [matDatepicker]="picker" attr.aria-label="{{'FOLLOW_UP.igm_date_format' | translate}}" formControlName="igmResultDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('testType') == 'Serology'">
                <div class="field-label">{{'FOLLOW_UP.igg_test' | translate}}</div>
                <div class="p-b">
                    <mat-radio-group attr.aria-label="{{'FOLLOW_UP.igg_test' | translate}}" formControlName="iggTest">
                        <div class="row">
                            <mat-radio-button class="col-auto" value="Positive">
                                {{'FOLLOW_UP.positive' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="Negative">
                                {{'FOLLOW_UP.negative' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="Doesn't Know">
                                {{'FOLLOW_UP.dont_know' | translate}}</mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-element-container m-t" *ngIf="getFormValue('testType') == 'Serology'">
                <div class="field-label">{{'FOLLOW_UP.igg_date' | translate}}</div>
                <mat-form-field appearance="outline" class="input-group-small">
                    <mat-label>{{'FOLLOW_UP.igg_date' | translate}}</mat-label>
                    <input matInput [max]="dateToday" [matDatepicker]="picker" attr.aria-label="{{'FOLLOW_UP.igg_date_format' | translate}}" formControlName="iggResultDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <!-- <div class="p-y">
                <button class="m-b-lg" mat-button mat-flat-button color="primary"
                    type="submit">{{'FOLLOW_UP.submit' | translate}}</button>
            </div> -->
        </div>
    </div>
</form>