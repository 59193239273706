import * as i0 from "@angular/core";
export class DataService {
    constructor() {
        this.dictionary = {};
    }
    setData(key, value) {
        this.dictionary[key] = value;
        return { [key]: value };
    }
    getData(key) {
        return Object.assign({}, this.dictionary[key]) || null;
    }
    getAll() {
        return Object.assign({}, this.dictionary);
    }
    deleteData(key) {
        return delete this.dictionary[key];
    }
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
