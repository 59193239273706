<div class="card-header">
  <h2 class="card-title">{{'GUARDIAN_INFO.guardian_info' | translate}} </h2>
  <div class="step-count">{{'GUARDIAN_INFO.step_4' | translate}} {{total_pages}}</div>
</div>

<div class="card-content">
    <div class="error" *ngIf="errortext" aria-live="assertive" role="alert">
        <div class="description">{{errortext}}</div>
    </div>
    <div class="alert info">
        <div class="description">{{'GUARDIAN_INFO.minor' | translate}}</div>
    </div>
    <app-guardian-card (onDelete)="deleteGuardian($event)" (onEdit)="editGuardian($event)" *ngFor="let guardian of guardians" [guardian]="guardian"></app-guardian-card>
    <button (click)="openDialog()" color="primary" mat-stroked-button class="m-b-md">{{'GUARDIAN_INFO.add_guardian' | translate}}</button>
</div>
