<a class="skip-link" role="link" tab-index="0" href="/thankyou#main-content">Skip to the main content</a>

<header role="banner">
    <app-toolbar></app-toolbar>
</header>

<svg xmlns="http://www.w3.org/2000/svg" style="display:none;">
    <defs>
        <symbol id="check" viewBox="0 0 16 16">
            <path fill="currentColor"
                d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z">
            </path>
        </symbol>
    </defs>
</svg>

<main class="login-splash" role="main" id="main-content">
    <div class="container-lg">
        <div class="intro-block">
            <div class="intro">
                <div class="success-block">
                    <svg class="icon success-icon" aria-hidden="true" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <use xlink:href="#check"></use>
                    </svg>
                    <div class="title">
                        <h1>{{'THANK_YOU.thank_you' | translate }} !</h1>
                    </div>
                </div>
                <div class="p-r-lg" aria-live="assertive" role="alert">
                    <h2 class="description">{{'THANK_YOU.thanks_chi' | translate }}</h2>

                    <div *ngIf="params== 2">
                        <p [innerHTML]="'THANK_YOU.right_away' | translate">

                        </p>
                        <p [innerHTML]="'THANK_YOU.provider_unable' | translate">

                        </p>

                    </div>
                    <div *ngIf="params== 3" class="p-r-lg" aria-live="assertive" role="alert">
                        <!-- <h1 class="description">{{'THANK_YOU.enrole_in_contact' | translate }}</h1> -->
                        <p [innerHTML]="'THANK_YOU.enrole_in_contact2' | translate"></p>
                        <p [innerHTML]="'THANK_YOU.enrole_in_contact3' | translate"></p>
                        <p [innerHTML]="'THANK_YOU.enrole_in_contact4' | translate"></p>
                    </div>

                    <!-- If answer is 3 with symptoms yes -->
                    <div *ngIf="params== 4" class="p-r-lg" aria-live="assertive" role="alert">
                        <p [innerHTML]="'THANK_YOU.right_away' | translate"></p>
                        <p [innerHTML]="'THANK_YOU.enrole_in_contact3' | translate"></p>
                    </div>

                    <!-- If answer is 3 with symptoms no -->
                    <div *ngIf="params== 5" class="p-r-lg" aria-live="assertive" role="alert">
                        <!-- <h1 class="description">{{'THANK_YOU.enrole_in_contact' | translate }}</h1> -->
                        <p [innerHTML]="'THANK_YOU.enrole_in_contact2' | translate"></p>
                        <p [innerHTML]="'THANK_YOU.enrole_in_contact3' | translate"></p>
                    </div>

                    <!-- If answer is 4 with symptoms yes -->

                    <div *ngIf="params== 6" class="p-r-lg" aria-live="assertive" role="alert">
                        <h2 class="description">{{'THANK_YOU.right_away' | translate }}</h2>
                        <p [innerHTML]="'THANK_YOU.enrole_in_contact3' | translate"></p>
                    </div>


                    <!-- If answer is 4 with symptoms no -->

                    <div *ngIf="params== 7" class="p-r-lg" aria-live="assertive" role="alert">
                        <!-- <h1 class="description">{{'THANK_YOU.enrole_in_contact' | translate }}</h1> -->
                        <p [innerHTML]="'THANK_YOU.answer_4_no_1' | translate"></p>
                        <p [innerHTML]="'THANK_YOU.enrole_in_contact3' | translate"></p>
                    </div>
                    <p>{{'THANK_YOU.review' | translate }} <a class="txt-underline" href="mailto:coronavirus@chicago.gov">coronavirus@chicago.gov</a> {{'THANK_YOU.or_call' | translate }} <a href="tel: 312-746-4835">312-746-4835</a>.

                    </p>
                    <div [innerHTML]="'THANK_YOU.meantime' | translate"></div>
                    <h2 class="description">{{'THANK_YOU.helpful_link_heading' | translate}}</h2>
                    <div [innerHTML]="'THANK_YOU.helpful_desc' | translate"></div>
                    <p>{{'THANK_YOU.covid_support' | translate}}</p>
                    <ul>
                        <li [innerHTML]="'THANK_YOU.covid_support_list' | translate"></li>
                        <ul>
                            <li [innerHTML]="'THANK_YOU.covid_support_list_1' | translate"></li>
                            <li [innerHTML]="'THANK_YOU.covid_support_list_2' | translate"></li>
                        </ul>

                    </ul>
                </div>

                <!-- If answer is 2 with symptoms yes -->
                <!-- <div *ngIf="params=='2'" class="p-r-lg" aria-live="assertive" role="alert">
                    <p [innerHTML]="'THANK_YOU.right_away' | translate">
                        
                    </p>
                    <p [innerHTML]="'THANK_YOU.provider_unable' | translate">

                    </p>
                    
                </div> -->
                <!-- If answer is 2 with symptoms no -->
                <!-- <div *ngIf="params=='3'" class="p-r-lg" aria-live="assertive" role="alert">
                    <h1 class="description">{{'THANK_YOU.enrole_in_contact' | translate }}</h1>
                    <p [innerHTML]="'THANK_YOU.enrole_in_contact2' | translate"></p>
                    <p [innerHTML]="'THANK_YOU.enrole_in_contact3' | translate"></p>
                    <p [innerHTML]="'THANK_YOU.enrole_in_contact4' | translate"></p>
                </div> -->
                <!-- If answer is 3 with symptoms yes -->
                <!-- <div *ngIf="params=='4'" class="p-r-lg" aria-live="assertive" role="alert">
                    <p [innerHTML]="'THANK_YOU.right_away' | translate"></p>
                    <p [innerHTML]="'THANK_YOU.enrole_in_contact3' | translate"></p>
                </div> -->
                <!-- If answer is 3 with symptoms no -->
                <!-- <div *ngIf="params=='5'" class="p-r-lg" aria-live="assertive" role="alert">
                    <h1 class="description">{{'THANK_YOU.enrole_in_contact' | translate }}</h1>
                    <p [innerHTML]="'THANK_YOU.enrole_in_contact2' | translate"></p>
                    <p [innerHTML]="'THANK_YOU.enrole_in_contact3' | translate"></p>
                </div> -->

                <!-- If answer is 4 with symptoms yes -->

                <!-- <div *ngIf="params=='6'" class="p-r-lg" aria-live="assertive" role="alert">
                    <h1 class="description">{{'THANK_YOU.right_away' | translate }}</h1>
                    <p [innerHTML]="'THANK_YOU.enrole_in_contact3' | translate"></p>
                </div> -->


                <!-- If answer is 4 with symptoms no -->

                <!-- <div *ngIf="params=='7'" class="p-r-lg" aria-live="assertive" role="alert">
                    <h1 class="description">{{'THANK_YOU.enrole_in_contact' | translate }}</h1>
                    <p [innerHTML]="'THANK_YOU.answer_4_no_1' | translate"></p>
                    <p [innerHTML]="'THANK_YOU.answer_4_no_2' | translate"></p>
                </div> -->

                <div class="actions">
                    <a mat-flat-button color="primary" routerLink="/" class="m-t">{{'THANK_YOU.home_page' | translate }}</a>
                </div>
            </div>
        </div>
        <!-- <div class="col-sm-5 image-panel">
            <img class="splash-img" src="../../../assets/illustrations/success_illustration.svg" alt="">
        </div> -->
    </div>
</main>
<div role="contentinfo">
    <app-footer></app-footer>
</div>