import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ConfirmationButtons {
  yes: string;
  no: string;
}

@Component({
  selector: 'app-confirmation-dailog',
  templateUrl: './confirmation-dailog.component.html',
  styleUrls: ['./confirmation-dailog.component.scss']
})
export class ConfirmationDailogComponent implements OnInit {
  title: string = 'Confirmation';
  body: string = 'Are you sure ?';
  buttons: ConfirmationButtons = {
    yes: 'Yes',
    no: 'No'
  };

  constructor(public dialogRef: MatDialogRef<ConfirmationDailogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.body = data.body; 
    this.buttons = data.buttons;
  }

  ngOnInit() {
  }

  onCancelClick(): void {
    this.dialogRef.close({isConfirm: false});
  }

  onConfirmClick(): void {
    this.dialogRef.close({isConfirm: true});
  }

}
