import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class SidenavServiceService {
    constructor() {
        this.isOpen = false;
        this.changeToggleStatus = new EventEmitter();
    }
    toggle(isOpen) {
        this.isOpen = isOpen !== undefined ? isOpen : !this.isOpen;
        this.changeToggleStatus.emit(this.isOpen);
    }
}
SidenavServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SidenavServiceService_Factory() { return new SidenavServiceService(); }, token: SidenavServiceService, providedIn: "root" });
