import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-instruction-page',
  templateUrl: './instruction-page.component.html',
  styleUrls: ['./instruction-page.component.scss']
})
export class InstructionPageComponent implements OnInit {
  
  @Input() errortext: any;
  @Input() total_pages: any;
  
  constructor() {
    // console.log("errortext",this.errortext);
   }

  ngOnInit() {
  }

}
