import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SidenavServiceService } from 'src/app/services/sidenav-service.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { setPageTitle } from 'src/app/common/util';

export interface Sidebar {
  route: string;
  icon: string;
  text: string;
  alt: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('sideNav', { read: MatSidenav, static: true })
  public sidenav: MatSidenav;
  isOpen: boolean = false;
  loggedIn: boolean = true;
  sidebarToggle: boolean = false;
  sidebars: Sidebar[] = [
    {
      route: '/intake-form',
      icon: 'Agency',
      text: 'Intake Form',
      alt: 'Intake Form'
    },
    {
      route: '/helpful-resources',
      icon: 'Invite',
      text: 'Helpful Resources',
      alt: 'Helpful Resources'
    },
    {
      route: '/cdc-information',
      icon: 'Log a Case',
      text: 'CDC Information',
      alt: 'CDC Information'
    }
  ];

  constructor(
    private sidebarService: SidenavServiceService,
    private sidenavService: SidenavServiceService,
    public router: Router,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.localStorageService.get('user')
      ? (this.loggedIn = true)
      : (this.loggedIn = false);
    this.router.navigateByUrl('/');
    setPageTitle('Chi COVID Coach');
  }

  ngAfterViewInit() {
    this.sidebarService.changeToggleStatus.subscribe((isOpen: boolean) => {
      this.sidenav.toggle(isOpen);
      this.isOpen = isOpen;
    });
  }

  onToggle(event: Event) {
    this.sidebarToggle = !this.sidebarToggle;
    this.sidenavService.toggle(this.sidebarToggle);
  }

  goToPage(page) {
    if (this.isOpen) {
      this.router.navigateByUrl(page);
      this.sidenav.close();
      this.isOpen = false;
    } else {
      this.sidenav.open();
      this.isOpen = true;
    }
    this.sidenavService.toggle(this.isOpen);
  }

  backdropClicked(event: any) {
    this.isOpen = false;
    this.sidenavService.toggle(this.isOpen);
  }

  goToHome() {
    this.router.navigateByUrl('/intake-form');
  }
}
