import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {
  NG_VALIDATORS,
  ReactiveFormsModule,
  FormsModule
} from '@angular/forms';

// Material Components
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LocalStorageModule } from 'angular-2-local-storage';
import { InlineSVGModule } from 'ng-inline-svg';
import { RecaptchaFormsModule, RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
// App components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './routes/auth/login/login.component';
import { SignupComponent } from './routes/auth/signup/signup.component';
import { ForgotPasswordComponent } from './routes/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './routes/auth/reset-password/reset-password.component';
import { PageNotFoundComponent } from './routes/page-not-found/page-not-found.component';
import { HomeComponent } from './routes/home/home.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatGridListModule,
  MatGridTile,
  MatProgressSpinner,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule
} from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { IntakePageComponent } from './routes/home/intake-page/intake-page.component';
import { InstructionBlockComponent } from './components/instruction-block/instruction-block.component';

import { PassengerInformationComponent } from './routes/home/passenger-information/passenger-information.component';
import { InstructionPageComponent } from './routes/home/instruction-page/instruction-page.component';
import { AddressPageComponent } from './routes/home/address-page/address-page.component';
import { GuardianInformationComponent } from './routes/home/guardian-information/guardian-information.component';
import { TravelInformationComponent } from './routes/home/travel-information/travel-information.component';
import { SymptomsInformationComponent } from './routes/home/symptoms-information/symptoms-information.component';
import { InformationTimelineComponent } from './components/information-timeline/information-timeline.component';
import { BackLinkComponent } from './components/back-link/back-link.component';
import { FollowUpComponent } from './components/follow-up/follow-up.component';
import { ConsentComponent } from './routes/home/consent/consent.component';
import { HelpOnWayComponent } from './routes/home/help-on-way/help-on-way.component';
import { HelpfulResourcesComponent } from './components/helpful-resources/helpful-resources.component';
import { TempApplicationpageComponent } from './components/temp-applicationpage/temp-applicationpage.component';
import { HelpfulResourcespageComponent } from './routes/home/helpful-resourcespage/helpful-resourcespage.component';
import { ReviewSectionComponent } from './routes/home/review-section/review-section.component';
import { AddAddressDialogComponent } from './components/add-address-dialog/add-address-dialog.component';
import { AddGuardianDialogComponent } from './components/add-guardian-dialog/add-guardian-dialog.component';
import { AddressCardComponent } from './components/address-card/address-card.component';
import { GuardianCardComponent } from './components/guardian-card/guardian-card.component';
import { ConsentSmsVerificationDialogComponent } from './components/consent-sms-verification-dialog/consent-sms-verification-dialog.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import {A11yModule} from '@angular/cdk/a11y';

//NG_Translate import funstions

import {  HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MultipleFollowUpComponent } from './routes/home/multiple-follow-up/multiple-follow-up.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmationDailogComponent } from './components/confirmation-dailog/confirmation-dailog.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    HomeComponent,
    ToolbarComponent,
    FooterComponent,
    ActionBarComponent,
    IntakePageComponent,
    InstructionBlockComponent,
    PassengerInformationComponent,
    InstructionPageComponent,
    AddressPageComponent,
    GuardianInformationComponent,
    TravelInformationComponent,
    SymptomsInformationComponent,
    InformationTimelineComponent,
    BackLinkComponent,
    FollowUpComponent,
    ConsentComponent,
    HelpOnWayComponent,
    HelpfulResourcesComponent,
    TempApplicationpageComponent,
    HelpfulResourcespageComponent,
    ReviewSectionComponent,
    AddAddressDialogComponent,
    AddGuardianDialogComponent,
    AddressCardComponent,
    GuardianCardComponent,
    ConsentSmsVerificationDialogComponent,
    ThankyouComponent,
    MultipleFollowUpComponent,
    ConfirmationDailogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    LocalStorageModule.forRoot({
      prefix: 'gl',
      storageType: 'localStorage'
    }),
    InlineSVGModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    MatCardModule,
    MatTabsModule,
    MatDialogModule,
    MatListModule,
    MatTooltipModule,
    MatStepperModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatPaginatorModule,
    MatExpansionModule,
    A11yModule,
    TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  })
  ],
  providers: [
    // {
    //   provide: RECAPTCHA_LANGUAGE,
    //   useValue: 'es',
    // },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3000 }
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    DeviceDetectorService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddAddressDialogComponent,
    AddGuardianDialogComponent,
    ConsentSmsVerificationDialogComponent,
    ConfirmationDailogComponent
  ]
})


export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      `mtx_logo`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/mtx.svg')
    );
  }
}
