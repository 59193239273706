import { Component, OnInit, Inject, Input } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import _ from 'lodash';
import { AddGuardianDialogComponent } from '../../../components/add-guardian-dialog/add-guardian-dialog.component';
export interface AddGuardianData {
  type: string;
  name: string;
  phonenumber: string;
  alternumber: string;
}

@Component({
  selector: 'app-guardian-information',
  templateUrl: './guardian-information.component.html',
  styleUrls: ['./guardian-information.component.scss']
})
export class GuardianInformationComponent implements OnInit {
  displayedColumns: string[] = [
    'action',
    'type',
    'name',
    'number',
    'alterNumber'
  ];
  guardians = [];
  @Input() errortext: any;
  @Input() total_pages: any;
  constructor(public dialog: MatDialog) {}

  ngOnInit() {}
  openDialog(): void {
    const dialogRef = this.dialog.open(AddGuardianDialogComponent, {
      width: '600px', data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result  && !result.isEdit) {
        this.guardians.push(result);
        this.guardians = [...this.guardians];
      }
    });
  }

  deleteGuardian = guardian => {
    console.log(guardian);
    this.guardians = _.filter(this.guardians, function (a) { return a.id !== guardian.id; });
  }

  editGuardian = guardian => {
    guardian.isEdit = true;
    const dialogRef = this.dialog.open(AddGuardianDialogComponent, {
      width: '600px',
      data: {currentGuardian: guardian}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isEdit) {
        var searchedIndex = _.findIndex(this.guardians, function(a) { return a.id == result.id; });
        this.guardians[searchedIndex] = result;
        this.guardians = [...this.guardians];
      }
    });
  }

  getData() {
    let guardian = [];
    this.guardians.map((item)=>{
      delete item.landlineNumberext;
      delete item.primaryCellNumberext;
      delete item.id;
      guardian.push({
        type: item.type,
        first_name: item.firstName,
        last_name: item.lastName,
        mobile: item.primaryCellNumber,
        landline: item.landlineNumber
      });
    });
    return guardian;
  }
}
