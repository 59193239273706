import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { moment } from 'src/app/common/timeutils';
import * as _ from 'lodash';

import { countrycodes, noWhitespaceValidator } from 'src/app/common/util';
@Component({
  selector: 'app-add-guardian-dialog',
  templateUrl: './add-guardian-dialog.component.html',
  styleUrls: ['./add-guardian-dialog.component.scss']
})
export class AddGuardianDialogComponent implements OnInit {
  public guardianForm: FormGroup;

  selectedType: string;
  selectedState: string;
  guardianed = {
    type: '',
    firstName: '',
    lastName: '',
    primaryCellNumberext: '',
    primaryCellNumber: '',
    landlineNumberext: '',
    landlineNumber: ''
  };
  isEdit = false;
  currentID = null;
  countrycodes = countrycodes;

  @ViewChild('type', { static: true }) type: any;

  @ViewChild('firstname', { static: true }) firstname: any;
  @ViewChild('lastname', { static: true }) lastname: any;

  @ViewChild('primaryCellNumber', { static: true }) primaryCellNumber: any;
  @ViewChild('primaryCellNumberext', { static: true })
  primaryCellNumberext: any;
  @ViewChild('landlineNumber', { static: true }) landlineNumber: any;
  @ViewChild('landlineNumberext', { static: true }) landlineNumberext: any;
  constructor(
    public dialogRef: MatDialogRef<AddGuardianDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private el: ElementRef
  ) {
    if (data.currentGuardian) {
      this.guardianed = data.currentGuardian;
      this.isEdit = true;
      this.currentID = data.currentGuardian.id;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.guardianForm.valid) {
      const guardian: any = {
        type: this.guardianForm.get('type').value,
        firstName: this.firstname.nativeElement.value,
        lastName: this.lastname.nativeElement.value,
        primaryCellNumberext: this.guardianForm.get('primaryCellNumberext')
          .value,
        primaryCellNumber:
          this.guardianForm.get('primaryCellNumberext').value +
          '' +
          this.primaryCellNumber.nativeElement.value,
        landlineNumberext: this.guardianForm.get('landlineNumberext').value,
        landlineNumber:
          this.guardianForm.get('landlineNumber').value !== ''
            ? this.guardianForm.get('landlineNumberext').value +
              '' +
              this.landlineNumber.nativeElement.value
            : ' ',
        isEdit: this.isEdit
      };
      if (!this.isEdit) {
        guardian.id = '_' + Math.random().toString(36).substr(2, 9);
      } else {
        guardian.id = this.currentID;
      }
      this.dialogRef.close(guardian);
    } else {
      for (const key of Object.keys(this.guardianForm.controls)) {
        if (this.guardianForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          if(_.get(invalidControl, "tagName") === 'MAT-RADIO-GROUP') {
            invalidControl.firstChild.focus();
          } else if(_.get(invalidControl, "tagName") === 'MAT-SELECT') {
            invalidControl.focused = true;
            invalidControl.focus();
          } else
            invalidControl.focus();
          break;
       }
      }
    }
  }

  // Validates numbers
  numberValidator(number): any {
    const NUMBER_REGEXP = /^[\d.]+$/;
    if (number.value == '' || NUMBER_REGEXP.test(number.value)) {
      return null;
    }
    return {
      invalidNumber: true
    };
  }
  ngOnInit() {
    this.guardianForm = new FormGroup({
      type: new FormControl(this.guardianed ? this.guardianed.type : '', [
        Validators.required,
        noWhitespaceValidator
      ]),
      firstname: new FormControl(
        this.guardianed ? this.guardianed.firstName : '',
        [Validators.required, noWhitespaceValidator]
      ),
      lastname: new FormControl(
        this.guardianed ? this.guardianed.lastName : '',
        [Validators.required, noWhitespaceValidator]
      ),
      primaryCellNumber: new FormControl(
        this.guardianed
          ? this.guardianed.primaryCellNumber.substring(
              this.guardianed.primaryCellNumber.indexOf(
                this.guardianed.primaryCellNumberext
              ) + this.guardianed.primaryCellNumberext.length
            )
          : '',
        [
          Validators.required,
          Validators.maxLength(15),
          this.numberValidator.bind(this),
          noWhitespaceValidator
        ]
      ),
      primaryCellNumberext: new FormControl(
        this.guardianed.primaryCellNumberext != ''
          ? this.guardianed.primaryCellNumberext
          : '+1',
        [Validators.required, noWhitespaceValidator]
      ),
      landlineNumber: new FormControl(
        this.guardianed
          ? this.guardianed.landlineNumber.substring(
              this.guardianed.landlineNumber.indexOf(
                this.guardianed.landlineNumberext
              ) + this.guardianed.landlineNumberext.length
            )
          : '',
        [Validators.maxLength(15), this.numberValidator.bind(this)]
      ),
      landlineNumberext: new FormControl(
        this.guardianed.landlineNumberext != ''
          ? this.guardianed.landlineNumberext
          : '+1',
        [Validators.required, noWhitespaceValidator]
      )
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.guardianForm.controls[controlName].hasError(errorName);
  };
}
