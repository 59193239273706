export const fieldsOrder: string[] = [
    'typeofTemp',
    'fttemperature',
    'cstemperature',
    'fever',
    'chills',
    'cough',
    'sore',
    'breath',
    'Wheezing',
    'runnyNose',
    'vomiting',
    'sinusCongestion',
    'chestPain',
    'New_loss_of_appetite',
    'New_loss_of_smell',
    'New_loss_of_taste',
    'Fatigue',
    'Rigors',
    'abdominalPain',
    'diarrhea',
    'muscleAche',
    'headache',
    'Other',
    'Other_Symptoms',
    'contactWithSuspected',
    'contact_with_confirmed_case',
    'cancer',
    'chronic_kidney',
    'pulmonary_disease',
    'heart_conditions',
    'solid_organ_transplant',
    'cell_disease',
    'type_2_diabetes',
    'pregnancy',
    'down_syndrome',
    'asthma',
    'cerebrovascular_disease',
    'cystic_fibrosis',
    'hypertension',
    'immunocompromised_state',
    'neurologic_condition',
    'overweight',
    'pulmonary_fibrosis',
    'thalassemia',
    'type_1_diabetes',
    'lungDisease',
    'diabetes',
    'heartDisease',
    'kidneyDisease',
    'liverDisease',
    'weekImmuneSystem',
    'obesity',
    'highBloodPressure',
    'otherDisease',
    'smoking',
    'onset_date'
]