import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-2-local-storage";
import * as i3 from "@angular/router";
export class ApiService {
    constructor(http, localStorageService, router) {
        this.http = http;
        this.localStorageService = localStorageService;
        this.router = router;
        this.accessToken = '';
    }
    getAccessToken() {
        if (this.localStorageService.get('accessToken')) {
            this.accessToken = this.localStorageService.get('accessToken')['id'];
        }
        return this.accessToken;
    }
    signUp(user) {
        const url = environment.backendUrl + '/contacts';
        return this.http.post(url, user);
    }
    login(user) {
        const url = environment.backendUrl + '/contacts/login?include=user';
        return this.http.post(url, user);
    }
    logout() {
        const url = environment.backendUrl + '/contacts/logout';
        return this.http.get(url, { params: { access_token: this.accessToken } });
    }
    reset(accessToken, data) {
        const url = environment.backendUrl + '/contacts/reset-contact-password';
        return this.http.post(url, data, { params: { access_token: accessToken } });
    }
    forgot(data) {
        const url = environment.backendUrl + '/contacts/reset';
        return this.http.post(url, data);
    }
    savePassengerInformation(data, captchaResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = environment.backendUrl + '/intake';
            return this.http.post(url, data, {
                headers: {
                    authorization: captchaResponse
                }
            }).toPromise();
        });
    }
    sendVerificationSms(data) {
        const url = environment.backendUrl + '/send-verification-code';
        return this.http.post(url, data);
    }
    updatenewFollowUp(questions) {
        const url = environment.backendUrl + '/intake/update-followups';
        return this.http.put(url, questions);
    }
    verifyCode(data) {
        const url = environment.backendUrl + '/verify-code';
        return this.http.post(url, data);
    }
    checkMobileDOB(data) {
        const url = environment.backendUrl + '/intake/check-mobile-dob-unique';
        return this.http.post(url, data);
    }
    getConfigdata() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${environment.backendUrl}/config/intake`;
            return this.http.get(url).toPromise();
        });
    }
    sendConsent(data, captchaResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${environment.backendUrl}/text-messaging/intake`;
            console.log('Consent URL: ' + url);
            return this.http.post(url, data, {
                headers: {
                    authorization: captchaResponse
                }
            }).toPromise();
        });
    }
    getTravellerLanguage(id) {
        const url = `${environment.backendUrl}/intake/lang/` + id;
        return this.http.get(url);
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.Router)); }, token: ApiService, providedIn: "root" });
