import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { moment } from 'src/app/common/timeutils';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-consent-sms-verification-dialog',
  templateUrl: './consent-sms-verification-dialog.component.html',
  styleUrls: ['./consent-sms-verification-dialog.component.scss']
})
export class ConsentSmsVerificationDialogComponent implements OnInit {
  @ViewChild('otp', { static: true }) otp: any;

  constructor(
    public dialogRef: MatDialogRef<ConsentSmsVerificationDialogComponent>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    var payload = {
      phoneNumber: this.data.phoneNumber
    };
    this.apiService.sendVerificationSms(payload).subscribe(
      (data: any) => {},
      (error) => {
        alert('Unable to send Otp');
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close({
      otpentered: false
    });
  }

  save() {
    this.verifyCode();
  }

  verifyCode() {
    var payload = {
      phoneNumber: this.data.phoneNumber,
      verificationCode: this.otp.nativeElement.value
    };
    this.apiService.verifyCode(payload).subscribe(
      (data: any) => {
        this.dialogRef.close({
          otpentered: true,
          otp: this.otp.value
        });
      },
      (error) => {
        alert('Otp Verification failed!');
      }
    );
  }
}
