<a class="skip-link" role="link" tab-index="0" href="/#main-content">Skip to the main content</a>
<header role="banner">
    <app-toolbar></app-toolbar>
</header>
<main class="login-splash" role="main" id="main-content" >
    <div class="container-lg">
        <div class="row">
            <div class="col-sm-12 intro-block">
                <div class="intro" >
                    <h1 class="splash-title"> {{'HOME_PAGE.thank_you' | translate }}</h1>
                    <div class="description" [innerHTML]="'HOME_PAGE.register' | translate">
                    </div>

                    <h2 class="splash-title"> {{'HOME_PAGE.vaccination_heading' | translate }}</h2>
                    <div class="description" [innerHTML]="'HOME_PAGE.vaccination_desc' | translate">
                    </div>

                    <h2 class="splash-title splash-sub-title">{{'HOME_PAGE.symptom_coach' | translate }}</h2>
                    <div class="description" [innerHTML]="'HOME_PAGE.symptom_coach_1' | translate">
                    </div>

                    <!-- 
                    <h2 class="splash-title splash-sub-title">{{'HOME_PAGE.testing_information' | translate }}</h2>
                    <div class="description">
                        {{'HOME_PAGE.testing_information_1' | translate }}
                    </div>


                    <h2 class="splash-title splash-sub-title">{{'HOME_PAGE.vaccination_registration' | translate }}</h2>
                    <div class="description">
                        {{'HOME_PAGE.vaccination_registration_1' | translate }}
                    </div> -->

                    <div class="description"><strong>{{'HOME_PAGE.click_start' | translate }}</strong>
                        <div class="info">{{'HOME_PAGE.no_IE' | translate }}</div>
                    </div>
                    <div class="actions">
                        <strong>{{'HOME_PAGE.for_updates' | translate }}</strong>
                        <div>
                            <a class="txt-underline" href="https://chicago.gov/coronavirus">https://chicago.gov/coronavirus</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="col-sm-6 image-panel">
                <img class="splash-img" src="../../../assets/illustrations/landing_illustration.svg" alt="">
            </div>-->
        </div>
    </div>
    <div class="login-form">
        <div class="container-lg">
            <div class="auth-block">
                <button mat-raised-button color="primary" (click)="goToHome()">{{'HOME_PAGE.button_startcoach' | translate }}</button>
            </div>
        </div>
    </div>
</main>
<div role="contentinfo">
    <app-footer></app-footer>
</div>