<div [formGroup]="form" class="{{ formClass }}">
    <div class="card-header">
        <h2 class="card-title">{{'SYMPTOMS_PAGE.symptom_information' | translate }}</h2>
        <div class="step-count">{{'SYMPTOMS_PAGE.all_most_ready' | translate }}
        </div>
        <p class="sensory-info"><abbr class="indicator">*</abbr> {{'COMMON_WORDS.sensory_information' |translate}} </p>
    </div>
    <div class="card-content lined-rows">
        <div class="row">
            <div class="col-sm-12">
                <div class="field-label">
                    <mat-checkbox formControlName="register_vaccine" (change)="checkBoxChange($event)"> {{'SYMPTOMS_PAGE.skip_symptoms' | translate }} </mat-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div role="group" attr.aria-label="{{'SYMPTOMS_PAGE.ans_questions' | translate }}">
        <div class="card-content lined-rows">
            <div class="alert info content-fit" *ngIf="!form.value.register_vaccine">
                <div class="description"> {{'SYMPTOMS_PAGE.ans_questions' | translate }}</div>
            </div>

            <!-- <div class="error" *ngIf="errortext" aria-live="assertive" role="alert" aria-atomic=”true”>
                <div class="description">{{errortext23 | translate}}
                    <ng-container *ngIf="!form.value.register_vaccine">
                        {{errortext1 | translate}} {{errortext2 | translate}}{{errortext3 | translate}} {{errortext4 | translate}} {{errortext5 | translate}} {{errortext6 | translate}} {{errortext7 | translate}} {{errortext8 | translate}} {{errortext9 | translate}} {{errortext10
                        | translate}} {{errortext11 | translate}} {{errortext24 | translate}} {{errortext25 | translate}} {{errortext26 | translate}} {{errortext27 | translate}} {{errortext28 | translate}} {{errortext29 | translate}} {{errortext30 | translate}}
                        {{errortext31 | translate}} {{errortext32 | translate}} {{errortext33 | translate}} {{errortext34 | translate}} {{errortext35 | translate}} {{errortext12 | translate}} {{errortext13 | translate}} {{errortext22 | translate}}
                    </ng-container>
                    {{errortext14 | translate}} {{errortext15 | translate}} {{errortext16 | translate}} {{errortext17 | translate}} {{errortext18 | translate}} {{errortext19 | translate}} {{errortext20 | translate}} {{errortext21 | translate}}
                    <ng-container *ngFor="let err of newFieldError">{{err | translate}},</ng-container>
                </div>
            </div> -->

            <!-- <div *ngIf="form.errors">{{'PERSONAL_INFO.oops_error' | translate }}</div> -->

            <fieldset *ngIf="!form.value.register_vaccine">
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">
                            {{'SYMPTOMS_PAGE.temp' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr>
                        </legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group attr.aria-label="{{'SYMPTOMS_PAGE.temp' | translate }}" class="row radio-group" formControlName="typeofTemp" required (change)="removeTep($event)">
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.yes' | translate }},{{'SYMPTOMS_PAGE.fahrenheit' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}, {{'SYMPTOMS_PAGE.celsius' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="No">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </fieldset>

            <div *ngIf="typeofTemp != '' && typeofTemp != 'No' && !form.value.register_vaccine">
                <div class="row">
                    <div class="col-sm-5">
                        <div class="field-label">{{'SYMPTOMS_PAGE.wat_temp' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></div>
                    </div>
                    <div class="col-sm-7">
                        <mat-form-field *ngIf="typeofTemp == 'no'" appearance="outline" class="temp-field">
                            <mat-label><span class="sr-only">{{'SYMPTOMS_PAGE.wat_was_the' | translate }} </span>{{'SYMPTOMS_PAGE.temperature' | translate }}</mat-label>
                            <input matInput formControlName="fttemperature" [errorStateMatcher]="matcher" (keyup)="changeFeverValue()" required>
                            <mat-error *ngIf="hasError('fttemperature')">{{'SYMPTOMS_PAGE.temp_mandatory_f' | translate }}</mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="typeofTemp == 'yes'" appearance="outline" class="temp-field">
                            <mat-label><span class="sr-only">{{'SYMPTOMS_PAGE.wat_was_the' | translate }} </span>{{'SYMPTOMS_PAGE.temperature' | translate }}</mat-label>
                            <input matInput formControlName="cstemperature" [errorStateMatcher]="matcher" (keyup)="changeFeverValue()" required>
                            <mat-error *ngIf="hasError('cstemperature')">{{'SYMPTOMS_PAGE.temp_mandatory_c' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!form.value.register_vaccine">
        <div role="group" attr.aria-label="{{'SYMPTOMS_PAGE.sick' | translate }}">
            <div class="card-header">
                <h3 class="card-title">{{'SYMPTOMS_PAGE.sick' | translate }}</h3>
            </div>
            <div class="card-content lined-rows">
                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.fever' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.fever' | translate }}" formControlName="fever" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.fever.touched && hasError('fever', 'required')">{{'SYMPTOMS_PAGE.if_fever' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.chills' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.chills' | translate }}" formControlName="chills" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.chills.touched && hasError('chills', 'required')">{{'SYMPTOMS_PAGE.if_chills' | translate }}</mat-label>

                        </div>
                    </div>
                </fieldset>

                <!--<div class="row">
                    <div class="col-sm-5">
                        <div class="field-label">Are you Feverish?<abbr class="indicator" aria-hidden="true">*</abbr></div>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" aria-label="Select an option" formControlName="feverish" (change)="checkSymptom($event)" required>
                            <mat-radio-button class="col-auto" value="yes">Yes</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>-->
                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.cough' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.cough' | translate }}" formControlName="cough" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.cough.touched && hasError('cough', 'required')">{{'SYMPTOMS_PAGE.if_cough' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.sore_throat' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.sore_throat' | translate }}" formControlName="sore" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.sore.touched && hasError('sore', 'required')">{{'SYMPTOMS_PAGE.if_sore_throat' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.breathing' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.breathing' | translate }}" formControlName="breath" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.breath.touched && hasError('breath', 'required')">{{'SYMPTOMS_PAGE.if_difficulty_breathing' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.Wheezing' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.Wheezing' | translate }}" formControlName="Wheezing" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.Wheezing.touched && hasError('Wheezing', 'required')">{{'SYMPTOMS_PAGE.if_Wheezing' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.runny_nose' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.runny_nose' | translate }}" formControlName="runnyNose" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.runnyNose.touched && hasError('runnyNose', 'required')">{{'SYMPTOMS_PAGE.if_runny_nose' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.Nausea' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.Nausea' | translate }}" formControlName="vomiting" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.vomiting.touched && hasError('vomiting', 'required')">{{'SYMPTOMS_PAGE.if_vomiting' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.Sinus_Congestion' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.Sinus_Congestion' | translate }}" formControlName="sinusCongestion" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.sinusCongestion.touched && hasError('sinusCongestion', 'required')">{{'SYMPTOMS_PAGE.if_Sinus_Congestion' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.Chest_Pain' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.Chest_Pain' | translate }}" formControlName="chestPain" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.chestPain.touched && hasError('chestPain', 'required')">{{'SYMPTOMS_PAGE.if_Chest_Pain' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.Loss_of_Appetite' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.Loss_of_Appetite' | translate }}" formControlName="New_loss_of_appetite" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.New_loss_of_appetite.touched && hasError('New_loss_of_appetite', 'required')">{{'SYMPTOMS_PAGE.if_Loss_of_Appetite' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.Loss_of_Smell' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.Loss_of_Smell' | translate }}" formControlName="New_loss_of_smell" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.New_loss_of_smell.touched && hasError('New_loss_of_smell', 'required')">{{'SYMPTOMS_PAGE.if_Loss_of_Smell' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>


                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.Loss_of_Taste' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.Loss_of_Taste' | translate }}" formControlName="New_loss_of_taste" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.New_loss_of_taste.touched && hasError('New_loss_of_taste', 'required')">{{'SYMPTOMS_PAGE.if_Loss_of_Taste' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.Fatigue' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.Fatigue' | translate }}" formControlName="Fatigue" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.Fatigue.touched && hasError('Fatigue', 'required')">{{'SYMPTOMS_PAGE.if_Fatigue' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.Rigors' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.Rigors' | translate }}" formControlName="Rigors" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.Rigors.touched && hasError('Rigors', 'required')">{{'SYMPTOMS_PAGE.if_Rigors' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.abnormal_pain' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.abnormal_pain' | translate }}" formControlName="abdominalPain" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.abdominalPain.touched && hasError('abdominalPain', 'required')">{{'SYMPTOMS_PAGE.if_abnormal_pain' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.diarrhea' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.diarrhea' | translate }}" formControlName="diarrhea" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.diarrhea.touched && hasError('diarrhea', 'required')">{{'SYMPTOMS_PAGE.if_diarrhea' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.muscleache' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.muscleache' | translate }}" formControlName="muscleAche" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.muscleAche.touched && hasError('muscleAche', 'required')">{{'SYMPTOMS_PAGE.if_muscleache' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.headache' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.headache' | translate }}" formControlName="headache" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.headache.touched && hasError('headache', 'required')">{{'SYMPTOMS_PAGE.if_headache' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.other' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>
                        <div class="col-sm-7">
                            <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.other' | translate }}" formControlName="Other" (change)="checkSymptom($event)" required>
                                <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                                <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            </mat-radio-group>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.Other.touched && hasError('Other', 'required')">{{'SYMPTOMS_PAGE.if_other' | translate }}</mat-label>
                        </div>
                    </div>
                </fieldset>
                <fieldset *ngIf="form.value.Other=='yes'">
                    <div class="row">
                        <div class="col-sm-5">
                            <legend class="field-label">{{'SYMPTOMS_PAGE.other_symptoms' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                        </div>

                        <div class="col-sm-7">
                            <mat-form-field appearance="outline" class="full">
                                <mat-label>{{'SYMPTOMS_PAGE.other_symptoms' | translate }}</mat-label>
                                <input matInput formControlName="Other_Symptoms" attr.aria-label="{{'SYMPTOMS_PAGE.other_symptoms' | translate }}" [errorStateMatcher]="matcher" appearance="outline" required/>
                                <mat-error *ngIf="hasError('Other_Symptoms', 'required')">{{'SYMPTOMS_PAGE.mandatory_other_symptoms' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </fieldset>







                <!--
                    <div class="row">
                        <label class="col-lg-2">Abdominal pain*</label>
                        <mat-radio-group aria-label="Select an option" formControlName="abdominal" (change)="checkSymptom($event)" required>
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    -->
            </div>
        </div>
    </ng-container>

    <div role="group" *ngIf="!form.value.register_vaccine" attr.aria-label="{{'SYMPTOMS_PAGE.diagnosed_with' | translate }}">
        <div class="card-header">
            <h3 class="card-title">{{'SYMPTOMS_PAGE.diagnosed_with' | translate }}</h3>
        </div>
        <div class="card-content lined-rows">

            <fieldset>
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.not_tested' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.not_tested' | translate }}" formControlName="contactWithSuspected" required>
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="unsure">{{'SYMPTOMS_PAGE.dont_know' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.contactWithSuspected.touched && hasError('contactWithSuspected', 'required')">{{'SYMPTOMS_PAGE.suspected_case' | translate }}</mat-label>
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.contact_with_someone_positive' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.contact_with_someone_positive' | translate }}" formControlName="contact_with_confirmed_case" required (change)="checkSymptom($event)">
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="unsure">{{'SYMPTOMS_PAGE.dont_know' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.contact_with_confirmed_case.touched && hasError('contact_with_confirmed_case', 'required')"> {{'SYMPTOMS_PAGE.confirmed_case' | translate }} </mat-label>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div role="group" attr.aria-label="{{'SYMPTOMS_PAGE.following_condition' | translate }}">
        <div class="card-content lined-rows">
            <fieldset class="mar-10">
                <div class="row">
                    <div class="col-sm-12">
                        <legend class="field-label">
                            <h4>{{'SYMPTOMS_PAGE.following_condition' | translate }}</h4>
                        </legend>
                    </div>
                </div>
            </fieldset>

            <fieldset *ngFor="let item of preConditionNewFields">
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.'+item.key | translate }}<abbr class="indicator" aria-hidden="true" *ngIf="item.required">*</abbr></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.'+item.key | translate }}" attr.formControlName="{{item.key}}" formControlName="{{item.key}}" [required]="item.required">
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <!-- <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls[item.key].touched && hasError(item.key, 'required')">{{'SYMPTOMS_PAGE.if_lung_disease' | translate }}</mat-label> -->
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls[item.key].touched && hasError(item.key, 'required')">{{'SYMPTOMS_PAGE.please_specify' | translate }} {{'SYMPTOMS_PAGE.'+item.key | translate }}</mat-label>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.lung_disease' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr><small class="d-block txt-regular txt-light">{{'SYMPTOMS_PAGE.eg_lung_disease' | translate }}</small></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.lung_disease' | translate }}" formControlName="lungDisease" required>
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.lungDisease.touched && hasError('lungDisease', 'required')">{{'SYMPTOMS_PAGE.if_lung_disease' | translate }}</mat-label>
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.diabetes' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.diabetes' | translate }}" formControlName="diabetes" required>
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.diabetes.touched && hasError('diabetes', 'required')">{{'SYMPTOMS_PAGE.if_diabetes' | translate }}</mat-label>
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.heart_disease' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.heart_disease' | translate }}" formControlName="heartDisease" required>
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.heartDisease.touched && hasError('heartDisease', 'required')">{{'SYMPTOMS_PAGE.if_heart_disease' | translate }}</mat-label>
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.kidney_disease' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.kidney_disease' | translate }}" formControlName="kidneyDisease" required>
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.kidneyDisease.touched && hasError('kidneyDisease', 'required')">{{'SYMPTOMS_PAGE.if_kidney_disease' | translate }}</mat-label>
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.liver_disease' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.liver_disease' | translate }}" formControlName="liverDisease" required>
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.liverDisease.touched && hasError('liverDisease', 'required')">{{'SYMPTOMS_PAGE.if_liver_disease' | translate }}</mat-label>
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.weak_immune' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.weak_immune' | translate }}" formControlName="weekImmuneSystem" required>
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.weekImmuneSystem.touched && hasError('weekImmuneSystem', 'required')">{{'SYMPTOMS_PAGE.if_weak_immune' | translate }}m</mat-label>
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.obesity' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.obesity' | translate }}" formControlName="obesity" required>
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.obesity.touched && hasError('obesity', 'required')">{{'SYMPTOMS_PAGE.if_obesity' | translate }}</mat-label>
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <div class="row">
                    <div class="col-sm-5">
                        <legend class="field-label">{{'SYMPTOMS_PAGE.high_blood_pressure' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></legend>
                    </div>
                    <div class="col-sm-7">
                        <mat-radio-group class="row" attr.aria-label="{{'SYMPTOMS_PAGE.high_blood_pressure' | translate }}" formControlName="highBloodPressure" required>
                            <mat-radio-button class="col-auto" value="yes">{{'COMMON_WORDS.yes' | translate }}</mat-radio-button>
                            <mat-radio-button class="col-auto" value="no">{{'COMMON_WORDS.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.highBloodPressure.touched && hasError('highBloodPressure', 'required')">{{'SYMPTOMS_PAGE.if_heart_disease' | translate }}</mat-label>
                    </div>
                </div>
            </fieldset>


            <div class="row">
                <div class="col-sm-5">
                    <div class="field-label">{{'SYMPTOMS_PAGE.other_cond' | translate }} <small class="d-block txt-regular txt-light">{{'SYMPTOMS_PAGE.eg_others' | translate }}</small></div>
                </div>
                <div class="col-sm-7">
                    <mat-form-field appearance="outline" class="full">
                        <mat-label>{{'SYMPTOMS_PAGE.pls_describe' | translate }}</mat-label>
                        <input matInput formControlName="otherDisease" attr.aria-label="{{'SYMPTOMS_PAGE.other_cond' | translate }}" [errorStateMatcher]="matcher" appearance="outline" />
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-5">
                    <div class="field-label">{{'SYMPTOMS_PAGE.smoking_status' | translate }}<abbr class="indicator" aria-hidden="true">*</abbr></div>
                </div>
                <div class="col-sm-7">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'SYMPTOMS_PAGE.smoking_status' | translate }}</mat-label>
                        <mat-select formControlName="smoking" required attr.aria-label="{{'SYMPTOMS_PAGE.smoking_status' | translate }}" role="combobox">
                            <mat-option role="option" value="currentSmoker">{{'SYMPTOMS_PAGE.current_smoker' | translate }}</mat-option>
                            <mat-option role="option" value="formerSmoker">{{'SYMPTOMS_PAGE.former_smoker' | translate }}</mat-option>
                            <mat-option role="option" value="nonSmoker">{{'SYMPTOMS_PAGE.non_smoker' | translate }}</mat-option>
                        </mat-select>

                        <mat-error>
                            <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.smoking.touched && hasError('smoking', 'required')">{{'SYMPTOMS_PAGE.if_smoking' | translate }}</mat-label>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="anySymptomYes && !form.value.register_vaccine">
                <div class="col-sm-5">
                    <div class="field-label">{{'SYMPTOMS_PAGE.symptom_start_date' | translate }}</div>
                </div>
                <div class="col-sm-7">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'SYMPTOMS_PAGE.onset_date' | translate }} <span class="sr-only">{{'SYMPTOMS_PAGE.date' | translate }}</span></mat-label>
                        <input matInput [min]="minDate" [max]="maxDate" formControlName="onset_date" [matDatepicker]="picker" required="{{ anySymptomYes }}" appearance="outline" attr.aria-label="{{'SYMPTOMS_PAGE.date' | translate }}"/>
                        <!--<mat-error *ngIf="hasError('onset_date', 'required')">Onset Date is required</mat-error>-->
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="hasError('onset_date', 'required')">{{'SYMPTOMS_PAGE.manifested_recently' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

</div>