import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { FollowUpComponent } from '../../../components/follow-up/follow-up.component';
import { setPageTitle } from 'src/app/common/util';

@Component({
  selector: 'app-multiple-follow-up',
  templateUrl: './multiple-follow-up.component.html',
  styleUrls: ['./multiple-follow-up.component.scss']
})
export class MultipleFollowUpComponent implements OnInit {
  params: any;
  apiLoading = false;
  allMonitoredUsers: any = [];
  submitted = false;
  selectedLang = 'en';
  skipLinkURL: string = '';

  @ViewChildren('symptomsMultipleInformationComponent')
  symptomsMultipleInformationComponent: QueryList<FollowUpComponent>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    private elRef: ElementRef
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = _.get(params, 'p');
      if (!this.params) {
        this.router.navigateByUrl('/');
      }
    });
  }

  ngOnInit() {
    setPageTitle('Follow Ups', 'Chi COVID Coach')
    this.getTravellerData();
    if(!window.location.href.includes('#main-content'))
      this.skipLinkURL = window.location.href + '#main-content';
    else
      this.skipLinkURL = window.location.href;
  }

  getTravellerData() {
    this.apiLoading = true;
    this.apiService.getTravellerLanguage(this.params).subscribe(
      (response: any) => {
        this.selectedLang =
          response.data && response.data.lang
            ? response.data.lang == 'English'
              ? 'en'
              : 'es'
            : 'en';
        const allMonitorIds = response.allMonitorIds
          ? response.allMonitorIds
          : [];
        this.allMonitoredUsers = allMonitorIds.map((item) => {
          return {
            ...item,
            isSelected: false
          };
        });
        this.translate.use(this.selectedLang);
        this.apiLoading = false;
      },
      (error) => {
        this.apiLoading = false;
        console.log('error occured while sending the data', error);
        this.snackbar.open(this.translate.instant('FOLLOW_UP.system_error'));
      }
    );
  }

  submitFollowUp() {
    const submittedUsers = [];
    const symptomsForms = this.symptomsMultipleInformationComponent.toArray();
    let errorMessage = '';
    // user array for message
    let errorMessageForUser = [];
    this.allMonitoredUsers.forEach((user, index) => {
      if (user.isSelected) {
        const formFilledData = symptomsForms[index].getFormData()[0].responses;
        const checkYesItem = formFilledData.filter((item) => {
          return item.answer.toLowerCase() === 'yes';
        });
        const checkNoItem = formFilledData.filter((item) => {
          return item.answer.toLowerCase() === 'no';
        });
        if (checkYesItem.length > 0) {
          console.log('symptomsForms[index].followupForm.valid',symptomsForms[index].followupForm.valid)
          if(!symptomsForms[index].followupForm.valid){
            let name = user.name.charAt(0).toUpperCase() + user.name.slice(1);
            errorMessageForUser.push(name);
            if (!symptomsForms[index].followupForm.get('symptomsStart').valid) {
              errorMessage = 'FOLLOW_UP.symtom_date_error';
            } else {
              errorMessage = 'FOLLOW_UP.invalid_tmp';
            }
          }
          else {
            submittedUsers.push({
              monitoringId: user.id,
              symptom: user.symptom,
              responses: symptomsForms[index].getFormData()[0].responses
            });
          }
        } else if (checkNoItem.length > 0) {
          submittedUsers.push({
            monitoringId: user.id,
            symptom: user.symptom,
            responses: symptomsForms[index].getFormData()[0].responses
          });
        } else {
          if(!symptomsForms[index].followupForm.valid){
            let name = user.name.charAt(0).toUpperCase() + user.name.slice(1);
            errorMessageForUser.push(name);
            const expansionPanel = this.elRef.nativeElement.querySelector(`#mat-expansion-panel-header-${index}`);
            if (!symptomsForms[index].followupForm.get('symptomsStart').valid) {
              const inputElement = expansionPanel.nextSibling.querySelector("[formcontrolname='symptomsStart']")
              inputElement.focus()
              errorMessage = 'FOLLOW_UP.symtom_date_error';
            } else {
              const inputElement = expansionPanel.nextSibling.querySelector("[formcontrolname='typeofTemp']")
              inputElement.querySelector("[value='no']").focus();
              errorMessage = 'FOLLOW_UP.invalid_tmp';
            }
          }
          else {
            submittedUsers.push({
            monitoringId: user.id,
            symptom: user.symptom,
            responses: symptomsForms[index].getFormData()[0].responses
          });
          }
        }
      }
    });
    if(submittedUsers.length === 0) {
      this.snackbar.open(this.translate.instant('FOLLOW_UP.fill_followup'))
    }
    if (errorMessage) {
      let errorMessageForSelectedUser =
        this.translate.instant(errorMessage) +
        ' ' +
        errorMessageForUser.toString();
      this.snackbar.open(errorMessageForSelectedUser);
    } else if (submittedUsers.length != 0) {
      this.apiLoading = true;
      this.apiService.updatenewFollowUp(submittedUsers).subscribe(
        (data: any) => {
          this.apiLoading = false;
          this.submitted = true;
          // this.isFormSubmitted = false;
        },
        error => {
          this.apiLoading = false;
          console.log('error occured while sending the data');
        }
      );
    }
  }

}
