<div [formGroup]="addressForm">


    <div mat-dialog-content>

        <h1 class="m-t-0 dialog-title">{{isEditName | translate}} {{'ADDRESS_COMMON.address' | translate}}</h1>
        <p class="sensory-info"><abbr class="indicator">*</abbr> {{'COMMON_WORDS.sensory_information' |translate}} </p>
        <!-- <div class="error" *ngIf="isAddError" aria-live="assertive" role="alert" role="alert">
            <div class="description">{{errorMsg1 | translate}} {{errorMsg2 | translate}} {{errorMsg3 | translate}} {{errorMsg4 | translate}} {{errorMsg5 | translate}} {{errorMsg6 | translate}}</div>
        </div>

        <div class="error" *ngIf="invalidZip" aria-live="assertive" role="alert" role="alert">
            <div class="description">{{'ADDRESS_COMMON.zip_count' | translate}}</div>
        </div>

        <div class="error" *ngIf="invalidMemberCount" aria-live="assertive" role="alert" role="alert">
            <div class="description">{{'ADDRESS_COMMON.household_count' | translate}}</div>
        </div>

        <div class="error" *ngIf="invalidCity" aria-live="assertive" role="alert" role="alert">
            <div class="description">{{'ADDRESS_COMMON.city_no_digit' | translate}}</div>
        </div>

        <div class="error" *ngIf="invalidState" aria-live="assertive" role="alert" role="alert">
            <div class="description">{{'ADDRESS_COMMON.state_no_digit' | translate}}</div>
        </div> -->

        <div class="row txt-small">
            <div class="col-sm-6">
                <mat-form-field *ngIf="isEdit" appearance="outline" class="input-size-sm">
                    <input matInput [(value)]="selectedTypeLabel[selectedType]" disabled>
                </mat-form-field>

                <mat-form-field *ngIf="!isEdit" appearance="outline" class="input-size-sm">
                    <mat-label>{{'ADDRESS_COMMON.type' | translate}} <span class="sr-only">{{'ADDRESS_COMMON.of_address' | translate}}</span></mat-label>
                    <mat-select class="full" formControlName="type" (selectionChange)="onTypeChange($event.value)" [(value)]="selectedType" required>
                        <mat-option *ngFor="let type of addressTypes" value={{type.value}}>
                            <ng-container *ngIf="type.isVisible">
                                {{type.label | translate}}
                            </ng-container>
                        </mat-option>
                    </mat-select>

                    <mat-error aria-live="assertive" role="alert"  *ngIf="hasError('type', 'required')">
                        {{'ADDRESS_COMMON.mandatory_type' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12">
                <mat-form-field appearance="outline" class="full">
                    <mat-label>{{'ADDRESS_COMMON.street_address_html' | translate}}</mat-label>
                    <input matInput formControlName="address" required/>
                    <mat-error  aria-live="assertive" role="alert" *ngIf="hasError('address', 'required')">
                        {{'ADDRESS_COMMON.mandatory_street_address_html' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field appearance="outline" class="full">
                    <mat-label>{{'ADDRESS_COMMON.city_html' | translate}}</mat-label>
                    <input matInput pattern="^[a-zA-Z ]*$" formControlName="city" required/>
                    <mat-error aria-live="assertive" role="alert" *ngIf="hasError('city', 'required')">
                        {{'ADDRESS_COMMON.mandatory_city_html' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field appearance="outline" class="full">
                    <mat-label>{{'ADDRESS_COMMON.state_html' | translate}}</mat-label>
                    <input type="text" pattern="^[a-zA-Z ]*$" attr.aria-label="{{'ADDRESS_COMMON.state_html' | translate}}" (onchange)="onStateChange($event.target.value)" matInput [matAutocomplete]="stateAutocomplete" formControlName="state" (ngModelChange)="doFilter($event)" required/>
                    <mat-autocomplete #stateAutocomplete="matAutocomplete" role="combobox">
                        <mat-option *ngFor="let state of filteredStates" [value]="state" role="option">
                            {{ state }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error aria-live="assertive" role="alert" *ngIf="hasError('state', 'required')">
                      {{'ADDRESS_COMMON.mandatory_state_html' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field appearance="outline" class="full">
                    <mat-label>{{'ADDRESS_COMMON.zip_html' | translate}}</mat-label>
                    <input  maxlength="5"  matInput formControlName="zip" minlength="5" required/>
                    <mat-error aria-live="assertive" role="alert" *ngIf="hasError('zip', 'required')">
                     {{'ADDRESS_COMMON.mandatory_zip_html' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field appearance="outline" class="full">
                    <mat-label>{{'ADDRESS_COMMON.country_html' | translate}}</mat-label>
                    <input matInput formControlName="country" required/>
                    <mat-error aria-live="assertive" role="alert" *ngIf="hasError('country', 'required')">
                        {{'ADDRESS_COMMON.mandatory_country_html' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12">
                <mat-form-field appearance="outline">
                    <mat-label>{{'ADDRESS_COMMON.no_of' | translate}})</mat-label>
                    <input matInput minlength="1" maxlength="2" required formControlName="total_members_in_household" />
                    <mat-error aria-live="assertive" role="alert" *ngIf="hasError('total_members_in_household', 'required')"> 
                    {{'ADDRESS_COMMON.mandatory_no_of' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12">
                <div class="p-b-md">
                    <mat-checkbox formControlName="emergencycheckbox" *ngIf="selectedType=='current'">{{'ADDRESS_COMMON.emergency_or_temp' | translate}}</mat-checkbox>
                </div>
            </div>

        </div>
    </div>
    <div class="footer-controls">
        <div class="secondary"></div>
        <div class="primary">
            <button mat-flat-button (click)="onNoClick()">{{'COMMON_WORDS.cancel' | translate}}</button>
            <button mat-flat-button color="primary" (click)="save()">{{'COMMON_WORDS.save' | translate}}</button>
        </div>

    </div>
</div>