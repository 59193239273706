<div>
  <h1 mat-dialog-title>{{'SMS_VERIFICATION.sms_verification' | translate}}</h1>
  <div mat-dialog-content>
    {{'SMS_VERIFICATION.sent_sms' | translate}}
     {{data?.phoneNumber}}
    <mat-form-field>
      <input matInput placeholder="Verification Code" #otp>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="float-right">
    <button mat-button (click)="onNoClick()">{{'COMMON_WORDS.cancel' | translate}}</button>
    <button mat-raised-button class="button-style" (click)="save()">{{'COMMON_WORDS.save' | translate}}</button>
  </div>
</div>
