import { Component, OnInit, Directive, AfterViewInit, Input} from '@angular/core';
import { SidenavServiceService } from 'src/app/services/sidenav-service.service';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, AfterViewInit {
  sidebarToggle = false;
  loggedIn = true;
  loading = false;
  selectedLanguage = 'en';
  langOptionDisplay = true;
  
  constructor(
    private sidenavService: SidenavServiceService,
    private apiService: ApiService,
    public router: Router,
    private localStorageService: LocalStorageService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
  }
 




  switchLanguage(event) {
    console.log(event.value);
    this.translate.use(event.value);
  }

  ngAfterViewInit() {
    this.sidenavService.changeToggleStatus.subscribe((isOpen: boolean) => {
      this.sidebarToggle = isOpen;
    });
  }

  onToggle(event: Event) {
    this.sidebarToggle = !this.sidebarToggle;
    this.sidenavService.toggle(this.sidebarToggle);
  }

  ngOnInit() {
    const currentUrl = this.router.url;
    if(currentUrl.includes('/follow-up')){
      this.langOptionDisplay = false;
    }
    this.localStorageService.get('user')
      ? (this.loggedIn = true)
      : (this.loggedIn = false);
  }

  goToDashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  logoutDone() {
    this.loading = false;
    this.localStorageService.clearAll();
    this.snackBar.open('Logged out');
    this.router.navigateByUrl('/login');
    window.location.reload();
  }
}
