import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './routes/auth/login/login.component';
import { SignupComponent } from './routes/auth/signup/signup.component';
import { ResetPasswordComponent } from './routes/auth/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './routes/auth/forgot-password/forgot-password.component';
import { PageNotFoundComponent } from './routes/page-not-found/page-not-found.component';
import { HomeComponent } from './routes/home/home.component';
import { IntakePageComponent } from './routes/home/intake-page/intake-page.component';
import { HelpfulResourcespageComponent } from './routes/home/helpful-resourcespage/helpful-resourcespage.component';

import { AddressPageComponent } from './routes/home/address-page/address-page.component';
import { GuardianInformationComponent } from './routes/home/guardian-information/guardian-information.component';
import { InstructionPageComponent } from './routes/home/instruction-page/instruction-page.component';
import { PassengerInformationComponent } from './routes/home/passenger-information/passenger-information.component';
import { SymptomsInformationComponent } from './routes/home/symptoms-information/symptoms-information.component';
import { TravelInformationComponent } from './routes/home/travel-information/travel-information.component';

import { ConsentComponent } from './routes/home/consent/consent.component';
import { MultipleFollowUpComponent } from './routes/home/multiple-follow-up/multiple-follow-up.component';

import { HelpOnWayComponent } from './routes/home/help-on-way/help-on-way.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
const routes: Routes = [
  { path: 'follow-up', component: MultipleFollowUpComponent },
  { path: 'intake-form', component: IntakePageComponent },
  { path: 'thankyou', component: ThankyouComponent },
  { path: '', component: HomeComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
