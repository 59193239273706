<a class="skip-link" role="link" tab-index="0" href="{{skipLinkURL}}">Skip to the main content</a>

<header role="banner">
    <app-toolbar></app-toolbar>
</header>
<main class="login-splash" role="main" id="main-content">
    <div class="loading-spinner-div" *ngIf="apiLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="container-lg">
        <div class="row">
            <div class="col-sm-7 intro-block">
                <div class="intro m-b-md">
                    <h1 class="splash-title">{{'FOLLOW_UP.chi_covid' | translate}} <br/> {{'FOLLOW_UP.symptom_follow_up' | translate}}</h1>
                    <div class="description" *ngIf="!submitted">{{'FOLLOW_UP.pls_answer' | translate}}</div>
                    <p class="sensory-info"><abbr class="indicator">*</abbr> {{'COMMON_WORDS.sensory_information' |translate}} </p>
                </div>
                <mat-accordion *ngIf="!submitted" class="example-headers-align">
                    <ng-container *ngFor="let traveler of allMonitoredUsers; let i = index">
                        <div class="p-b">
                            <mat-checkbox [(ngModel)]="traveler.isSelected">
                                <p><b>{{'FOLLOW_UP.submit_symptom_for' | translate}}:&nbsp;</b>{{ traveler.name | titlecase}}</p>
                            </mat-checkbox>
                            <mat-expansion-panel [disabled]="!traveler.isSelected" [expanded]="traveler.isSelected">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <p><b>{{'FOLLOW_UP.name' | translate}}:&nbsp;</b>{{ traveler.name | titlecase}}
                                        </p>
                                    </mat-panel-title>
                                    <mat-panel-description>

                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                
                                <app-follow-up #symptomsMultipleInformationComponent [userLang]="selectedLang"></app-follow-up>

                            </mat-expansion-panel>
                        </div>
                    </ng-container>
                </mat-accordion>
                <div class="p-y" *ngIf="allMonitoredUsers && allMonitoredUsers.length && !submitted">
                    <button class="m-b-lg" mat-button mat-flat-button color="primary" type="submit"
                        (click)="submitFollowUp()">{{'FOLLOW_UP.submit' | translate}}</button>
                </div>
                <mat-card *ngIf="submitted">
                    <div class="dark-panel p-large mb-large">
                        <p class="breakWord">{{'FOLLOW_UP.thanks' | translate}} <a class="txt-underline"
                                href="http://www.chicago.gov/coronavirus">www.chicago.gov/coronavirus</a>
                            {{'FOLLOW_UP.or_call' | translate}} <a class="txt-underline"
                                href="tel:+3127464835">312-746-4835</a>.</p>
                    </div>
                </mat-card>
            </div>
            <div *ngIf="!submitted" class="col-sm-5 image-panel">
                <img class="splash-img" src="../../../assets/illustrations/follow-up_illustration.svg" alt="">
            </div>
        </div>
    </div>
</main>
<div role="contentinfo">
    <app-footer></app-footer>
</div>