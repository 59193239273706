<!--
@author Gaurav Shah
@email gaurav.shah@mtxb2b.com
@create date 2019-09-14 02:32:11
@modify date 2019-09-14 02:32:32
@desc Toolbar Component.
-->

<div class="site-header">
    <div class="container-lg d-flex justify-content-between align-items-center">
        <div class="logo-lockup">
            <a href="/" class="d-flex" aria-label="Back to home">
                <img alt="Chicago icon" class="logo-img" src="../../../assets/logos/logo_chicago-icon.svg" />
                <div class="lockup">{{'TEMP_APP.CDPH' | translate}}</div>
            </a>
        </div>
        <div *ngIf="langOptionDisplay" class="language-block">
            <mat-form-field appearance="outline" size="small" class="select-language">
                <mat-label>{{'Toolbar_Language.select_lang' | translate}}</mat-label>
                <mat-select (selectionChange)="switchLanguage($event)" value="en">
                    <mat-option value="en">{{'Toolbar_Language.eng' | translate}}</mat-option>
                    <mat-option value="es">{{'Toolbar_Language.spanish' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>