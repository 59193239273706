<div [formGroup]="form" class="{{ formClass }}">

    <div class="card-header">
        <h2 class="card-title">{{'SIDEBAR.personalinfo' | translate}}</h2>
        <div class="step-count">{{'PERSONAL_INFO.step' | translate}} {{total_pages}}, {{'PERSONAL_INFO.reg_vacc' | translate}}</div>
        <p class="sensory-info"><abbr class="indicator">*</abbr> {{'COMMON_WORDS.sensory_information' |translate}} </p>
    </div>

    <div class="card-content">
        <div class="alert info content-fit">
            <div class="description">{{'PERSONAL_INFO.provide_personal' | translate}}.</div>
        </div>
        <!-- <div class="error" *ngIf="errortext" aria-live="assertive" role="alert" aria-atomic=”true”>
            <div class="description">{{errortext1 | translate}} {{errortext7 | translate}} {{errortext8 | translate}} {{errortext2 | translate}} {{errortext3 | translate}} {{errortext4 | translate}} {{errortext5 | translate}} {{errortext6 | translate}}</div>
        </div> -->

        <div *ngIf="form.errors">{{'PERSONAL_INFO.oops_error' | translate}}!</div>
        <div class="row input-group-small">

            <mat-form-field appearance="outline" class="col-sm-5">
                <mat-label>{{'PERSONAL_INFO.first_name' | translate}}</mat-label>
                <input matInput formControlName="firstName" required />
                <mat-error *ngIf="hasError('firstName', 'required')">{{'PERSONAL_INFO.first_name_req' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-sm-2">
                <mat-label>{{'PERSONAL_INFO.middle_name' | translate}}</mat-label>
                <input matInput formControlName="middleName" />
                <mat-error *ngIf="hasError('middleName', 'required')">{{'PERSONAL_INFO.middle_req' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-sm-5">
                <mat-label>{{'PERSONAL_INFO.last_name' | translate}}</mat-label>
                <input matInput formControlName="lastName" required />
                <mat-error *ngIf="hasError('lastName', 'required')">{{'PERSONAL_INFO.last_name_req' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-sm-5">
                <mat-label>{{'PERSONAL_INFO.date_of_birth' | translate}}<span class="sr-only">{{'PERSONAL_INFO.please_dob' | translate}}</span></mat-label>
                <input matInput [max]="dateToday" [matDatepicker]="picker" formControlName="dateOfBirth" (dateChange)="computeAge($event)" (dateInput)="computeAge($event)" />
                <!-- <mat-error *ngIf="hasError('dateOfBirth', 'required')">DOB is required</mat-error> -->
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker (dateChange)="computeAge($event)"></mat-datepicker>
            </mat-form-field>

            <div class="col-sm-2 rel">
                <div class="insulator"></div>
                <mat-form-field appearance="outline">
                    <mat-label>{{'PERSONAL_INFO.age' | translate}}</mat-label>
                    <input matInput formControlName="ageDisplay" readonly/>
                </mat-form-field>
            </div>

        </div>

        <div class="row mt-xs">
            <div class="col-sm-5 mat-form-field-wrapper option-based-elements">
                <fieldset>
                    <legend class="field-label">
                        {{'PERSONAL_INFO.sex_birth' | translate}}<span class="mat-placeholder-required mat-form-field-required-marker" aria-hidden="true">*</span>
                    </legend>
                    <mat-radio-group attr.aria-label="Select gender" formControlName="gender" (change)="checkGenderValue()" required>
                        <mat-radio-button value="M">{{'COMMON_WORDS.male' | translate}}</mat-radio-button>
                        <mat-radio-button value="F">{{'COMMON_WORDS.female' | translate}}</mat-radio-button>
                        <!-- <mat-radio-button value="X">Other</mat-radio-button> -->
                    </mat-radio-group>
                </fieldset>
                <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.gender.touched && hasError('gender', 'required')">{{'PERSONAL_INFO.gender_req' | translate}}</mat-label>
                <mat-label class="error-label" *ngIf="((formClass == 'submittedform') && hasError('gender', 'required'))">{{'PERSONAL_INFO.gender_req' | translate}}</mat-label>
            </div>

            <mat-form-field appearance="outline" class="col-sm-5">
                <mat-label>{{'PERSONAL_INFO.current_sex' | translate}}</mat-label>
                <input matInput formControlName="Other_Gender" />

            </mat-form-field>

            <div class="col-sm-5 mat-form-field-wrapper option-based-elements" *ngIf="showPregnancy();">
                <div class="field-label">
                    {{'PERSONAL_INFO.pregnant' | translate}}
                </div>
                <mat-radio-group attr.aria-label="{{'PERSONAL_INFO.pregnant' | translate}}" value="no" formControlName="pregnancy">
                    <mat-radio-button [value]="true">{{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                    <mat-radio-button [value]="false">{{'COMMON_WORDS.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <div class="card-header">
        <h3 class="card-title">{{'PERSONAL_INFO.contact_info' | translate}}</h3>
        <div class="description">{{'PERSONAL_INFO.mesg_data' | translate}}</div>
    </div>

    <div class="card-content">
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-3">
                        <mat-form-field appearance="outline">
                            <mat-label><span class="sr-only">{{'PERSONAL_INFO.primary_cell' | translate}}</span> {{'PERSONAL_INFO.prefix' | translate}}</mat-label>
                            <mat-select id="phoneext" formControlName="phoneext">
                                <mat-option *ngFor="let extension of countrycodes" [value]="extension">
                                    {{ extension }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-9 p-relative">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'PERSONAL_INFO.primary_cell' | translate}}</mat-label>
                            <input matInput (keypress)="validateOnlyNumber($event)" formControlName="phone" required maxlength="10" minlength="10" />
                            <mat-error *ngIf="hasError('phone', 'required')">{{'PERSONAL_INFO.pc_required' | translate}}</mat-error>
                            <mat-error *ngIf="hasError('phone', 'minlength')">{{'PERSONAL_INFO.phone_req' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-3">
                        <mat-form-field appearance="outline">
                            <mat-label><span class="sr-only">{{'PERSONAL_INFO.landline' | translate}}</span> {{'PERSONAL_INFO.prefix' | translate}}</mat-label>
                            <mat-select id="landlineext" formControlName="landlineext">
                                <mat-option *ngFor="let extension of countrycodes" [value]="extension">
                                    {{ extension }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-9">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'PERSONAL_INFO.landline' | translate}}</mat-label>
                            <input matInput (keypress)="validateOnlyNumber($event)" formControlName="landline" maxlength="10" minlength="10" />
                            <mat-error *ngIf="hasError('landline', 'minlength')">{{'PERSONAL_INFO.landline_req' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <mat-form-field appearance="outline" class="col-sm-6">
                <mat-label>{{'PERSONAL_INFO.email' | translate}}</mat-label>
                <input matInput formControlName="email" />
                <mat-error *ngIf="hasError('email', 'email')">{{'PERSONAL_INFO.invalid_email' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-sm-6">
                <mat-label>{{'PERSONAL_INFO.occupation' | translate}}</mat-label>
                <input matInput formControlName="occupation" />
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline" class="col-sm-6" required>
                <mat-label><span class="sr-only">{{'PERSONAL_INFO.lang_at_home' | translate}}</span>{{'PERSONAL_INFO.lang_at_home' | translate}} </mat-label>
                <mat-select formControlName="language">
                    <mat-option value="English">{{'PERSONAL_INFO.english' | translate}}</mat-option>
                    <mat-option value="Spanish">{{'PERSONAL_INFO.spanish' | translate}}</mat-option>
                </mat-select>
                <mat-error>{{'PERSONAL_INFO.field_req' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-sm-6">
                <mat-label>{{'PERSONAL_INFO.employer_school' | translate}}</mat-label>
                <input matInput formControlName="employer_or_school_name" />
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline" class="col-sm-6" required>
                <mat-label><span class="sr-only">{{'PERSONAL_INFO.what_is_your' | translate}}</span>{{'PERSONAL_INFO.race' | translate}}</mat-label>
                <mat-select formControlName="Race" multiple (selectionChange)="onRaceChange($event)" role="combobox" required>
                    <mat-option role="option" value="Black">{{'PERSONAL_INFO.black' | translate}}</mat-option>
                    <mat-option role="option" value="American Indian/Alaskan">{{'PERSONAL_INFO.american_indian' | translate}}</mat-option>
                    <mat-option role="option" value="Asian">{{'PERSONAL_INFO.asian' | translate}}</mat-option>
                    <mat-option role="option" value="Native Hawaiian/Pacific Islander">{{'PERSONAL_INFO.hawaiian' | translate}}</mat-option>
                    <mat-option role="option" value="White">{{'PERSONAL_INFO.white' | translate}}</mat-option>
                    <mat-option role="option" value="Other">{{'PERSONAL_INFO.other_race' | translate}}</mat-option>
                    <mat-option role="option" value="Unknown">{{'PERSONAL_INFO.unknown' | translate}}</mat-option>
                </mat-select>
                <mat-error>{{'PERSONAL_INFO.field_req' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-sm-6" required>
                <mat-label><span class="sr-only">{{'PERSONAL_INFO.what_is_your' | translate}}</span>{{'PERSONAL_INFO.ethnicity' | translate}}</mat-label>
                <mat-select formControlName="Ethnicity" role="combobox" required>
                    <mat-option role="option" value="Hispanic/Latino">{{'PERSONAL_INFO.hispanic_latino' | translate}}</mat-option>
                    <mat-option role="option" value="Not Hispanic/Latino">{{'PERSONAL_INFO.non_hispanic_latino' | translate}}</mat-option>
                    <mat-option role="option" value="Unknown">{{'PERSONAL_INFO.unknown' | translate}}</mat-option>
                </mat-select>
                <mat-error>{{'PERSONAL_INFO.field_req' | translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="mat-form-field-wrapper option-based-elements">
            <div class="field-label">
                {{'PERSONAL_INFO.is_healthcare_worker' | translate}}
            </div>
            <mat-radio-group attr.aria-label="{{'PERSONAL_INFO.is_healthcare_worker' | translate}}" formControlName="healthcareWorker">
                <mat-radio-button value="true">{{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                <mat-radio-button value="false">{{'COMMON_WORDS.no' | translate}}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="mat-form-field-wrapper option-based-elements">
            <div class="field-label">
                {{'PERSONAL_INFO.is_first_responder' | translate}}
            </div>
            <mat-radio-group attr.aria-label="{{'PERSONAL_INFO.is_first_responder' | translate}}" formControlName="firstResponder">
                <mat-radio-button value="true">{{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                <mat-radio-button value="false">{{'COMMON_WORDS.no' | translate}}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="mat-form-field-wrapper option-based-elements">
            <div class="field-label">
                {{'PERSONAL_INFO.is_essential_worker' | translate}} <span class="mat-placeholder-required mat-form-field-required-marker" aria-hidden="true">*</span>
            </div>
            <mat-radio-group attr.aria-label="{{'PERSONAL_INFO.is_essential_worker' | translate}}" formControlName="essentialEmployee" required>
                <mat-radio-button [value]="true">{{'COMMON_WORDS.yes' | translate}}</mat-radio-button>
                <mat-radio-button [value]="false">{{'COMMON_WORDS.no' | translate}}</mat-radio-button>
            </mat-radio-group>
            <div>
                <mat-label class="error-label" aria-live="assertive" role="alert" *ngIf="form.controls.essentialEmployee.touched && hasError('essentialEmployee', 'required')">{{'PERSONAL_INFO.is_essential_employee' | translate}}</mat-label>
                <!--<mat-label class="error-label" *ngIf="((formClass == 'submittedform') && hasError('essentialEmployee', 'required'))">Field is required</mat-label>-->
            </div>
        </div>

        <div class="mat-form-field-wrapper option-based-elements">
            <div class="field-label">
                {{'PERSONAL_INFO.following_industries' | translate}}
            </div>
            <mat-form-field appearance="outline" class="col-sm-6" >
                <mat-label><span class="sr-only">{{'PERSONAL_INFO.following_industries' | translate}}</span>{{'PERSONAL_INFO.select_an_option' | translate}} </mat-label>
                <mat-select role="combobox" formControlName="industry_one" attr.aria-label="{{'PERSONAL_INFO.following_industries' | translate}}">
                    <mat-option role="option" value="First responders">{{'PERSONAL_INFO.first_responders' | translate}}</mat-option>
                    <mat-option role="option" value="Corrections officers">{{'PERSONAL_INFO.corrections_officers' | translate}}</mat-option>
                    <mat-option role="option" value="Food and agricultural workers">{{'PERSONAL_INFO.food_and_agricultural' | translate}}</mat-option>
                    <mat-option role="option" value="U.S. Postal service">{{'PERSONAL_INFO.us_postal_service' | translate}}</mat-option>
                    <mat-option role="option" value="Manufacturing workers">{{'PERSONAL_INFO.manufacturing_workers' | translate}}</mat-option>
                    <mat-option role="option" value="Grocery store workers">{{'PERSONAL_INFO.grocery_store' | translate}}</mat-option>
                    <mat-option role="option" value="Public transit workers">{{'PERSONAL_INFO.public_transit_workers' | translate}}</mat-option>
                    <mat-option role="option" value="Education sector">{{'PERSONAL_INFO.education_sector' | translate}}</mat-option>
                    <mat-option role="option" value="Child care workers">{{'PERSONAL_INFO.child_care_workers' | translate}}</mat-option>
                </mat-select>
                <mat-error>{{'PERSONAL_INFO.field_req' | translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="mat-form-field-wrapper option-based-elements">
            <div class="field-label">
                {{'PERSONAL_INFO.following_industries' | translate}}
            </div>
            <mat-form-field appearance="outline" class="col-sm-6">
                <mat-label><span class="sr-only">{{'PERSONAL_INFO.following_industries' | translate}}</span>{{'PERSONAL_INFO.select_an_option' | translate}}</mat-label>
                <mat-select role="combobox" formControlName="industry_two" attr.aria-label="{{'PERSONAL_INFO.following_industries' | translate}}" >
                    <mat-option role="option" value="Transportation and logistics">{{'PERSONAL_INFO.transportation_and_logistics' | translate}}</mat-option>
                    <mat-option role="option" value="Water and wastewater">{{'PERSONAL_INFO.water_and_wastewater' | translate}}</mat-option>
                    <mat-option role="option" value="Food service">{{'PERSONAL_INFO.food_service' | translate}}</mat-option>
                    <mat-option role="option" value="Shelter and housing">{{'PERSONAL_INFO.shelter_and_housing' | translate}}</mat-option>
                    <mat-option role="option" value="Construction">{{'PERSONAL_INFO.construction' | translate}}</mat-option>
                    <mat-option role="option" value="Finance">{{'PERSONAL_INFO.finance' | translate}}</mat-option>
                    <mat-option role="option" value="Information technology and communications">{{'PERSONAL_INFO.information_technology_and_communications' | translate}}</mat-option>
                    <mat-option role="option" value="Energy">{{'PERSONAL_INFO.energy' | translate}}</mat-option>
                    <mat-option role="option" value="Legal">{{'PERSONAL_INFO.legal' | translate}}</mat-option>
                    <mat-option role="option" value="Media">{{'PERSONAL_INFO.media' | translate}}</mat-option>
                    <mat-option role="option" value="Public safety">{{'PERSONAL_INFO.public_safety' | translate}}</mat-option>
                    <mat-option role="option" value="Public health">{{'PERSONAL_INFO.public_health' | translate}}</mat-option>
                </mat-select>
                <mat-error>{{'PERSONAL_INFO.field_req' | translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="mat-form-field-wrapper option-based-elements">
            <div class="field-label">
                {{'PERSONAL_INFO.following_medical_1' | translate}}
            </div>
            <mat-form-field appearance="outline" class="col-sm-6" required>
                <mat-label><span class="sr-only">{{'PERSONAL_INFO.following_medical_1' | translate}}</span>{{'PERSONAL_INFO.select_an_option' | translate}}</mat-label>
                <mat-select formControlName="medical_condition_one" role="combobox" required attr.aria-label="{{'PERSONAL_INFO.following_medical_1' | translate}}">
                    <mat-option role="option" [value]="true">{{'COMMON_WORDS.yes' | translate}}</mat-option>
                    <mat-option role="option" [value]="false">{{'COMMON_WORDS.no' | translate}}</mat-option>
                </mat-select>
                <mat-error>{{'PERSONAL_INFO.field_req' | translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="mat-form-field-wrapper option-based-elements">
            <div class="field-label">
                {{'PERSONAL_INFO.following_medical_2' | translate}}
            </div>
            <mat-form-field appearance="outline" class="col-sm-6" required >
                <mat-label><span class="sr-only">{{'PERSONAL_INFO.following_medical_2' | translate}}</span>{{'PERSONAL_INFO.select_an_option' | translate}}</mat-label>
                <mat-select formControlName="medical_condition_two" role="combobox" required attr.aria-label="{{'PERSONAL_INFO.following_medical_2' | translate}}">
                    <mat-option role="option" [value]="true">{{'COMMON_WORDS.yes' | translate}}</mat-option>
                    <mat-option role="option" [value]="false">{{'COMMON_WORDS.no' | translate}}</mat-option>
                </mat-select>
                <mat-error>{{'PERSONAL_INFO.field_req' | translate}}</mat-error>
            </mat-form-field>
        </div>
        <!--<div class="option-based-elements mat-form-field-wrapper" *ngIf="areYouOther">
            <fieldset>
                <legend class="field-label" role="group" aria-label="Select occupation">Do you work, live or frequent any of the following places? <small class="d-block txt-regular">(Select all that apply)</small></legend>
                <div class="radio-group">
                    <mat-checkbox formControlName="schoolStudentK12">School (K-12/Grade/High school)</mat-checkbox>
                    <mat-checkbox formControlName="studentCollege">College/University</mat-checkbox>
                    <mat-checkbox formControlName="daycare">Day care</mat-checkbox>
                    <mat-checkbox formControlName="healthcare">Health care</mat-checkbox>
                    <mat-checkbox formControlName="corrections">Corrections</mat-checkbox>
                </div>
            </fieldset>
        </div>-->

    </div>

</div>