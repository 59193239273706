import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatStepper, MatSnackBar } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { DataService } from '../../../services/data.service';
import { PassengerInformationComponent } from '../passenger-information/passenger-information.component';
import { AddressPageComponent } from '../address-page/address-page.component';
import { GuardianInformationComponent } from '../guardian-information/guardian-information.component';
import { TravelInformationComponent } from '../travel-information/travel-information.component';
import { SymptomsInformationComponent } from '../symptoms-information/symptoms-information.component';
import { ConsentComponent } from '../consent/consent.component';
import { setPageTitle } from 'src/app/common/util';
import { log } from 'util';
import * as _ from 'lodash';
import { moment, momentToTimestamp } from 'src/app/common/timeutils';
import { MOMENT_INPUT_DATE_PROVIDERS, SQL_DATE } from 'src/app/common/constant';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaComponent } from 'ng-recaptcha';

import {
  DeviceDetectorService,
  DeviceDetectorModule
} from 'ngx-device-detector';
@Component({
  selector: 'app-intake-page',
  templateUrl: './intake-page.component.html',
  styleUrls: ['./intake-page.component.scss'],
  providers: [...MOMENT_INPUT_DATE_PROVIDERS]
})
export class IntakePageComponent implements OnInit {
  passengerData: any;
  passengerPhoneNumber: any;
  stepError: any = false;
  total_pages = 4;
  showGuardianInfo = false;
  mobilestep = 1;
  captchaKey = environment.recaptchaKey;
  captchaResponse = '';
  submitIntake = 'step4';
  selectedSymptomOption = [];

  @ViewChild('stepperPane', { static: false })
  stepperPane: MatStepper;

  @ViewChild('stepperContent', { static: false })
  stepperContent: MatStepper;

  @ViewChild('captchaRef', { static: true }) captchRef: RecaptchaComponent;

  @ViewChild('passengerInformationComponent', { static: false })
  passengerInformationComponent: PassengerInformationComponent;

  @ViewChild('addressPageComponent', { static: false })
  addressPageComponent: AddressPageComponent;

  @ViewChild('guardianInformationComponent', { static: false })
  guardianInformationComponent: GuardianInformationComponent;

  /*@ViewChild('travelInformationComponent', { static: false })
  travelInformationComponent: TravelInformationComponent;*/

  @ViewChild('symptomsInformationComponent', { static: false })
  symptomsInformationComponent: SymptomsInformationComponent;

  /*@ViewChild('consentComponent', { static: false })
  consentComponent: ConsentComponent;*/

  selectedIndex = 0;
  passengerInformation: FormGroup;
  addressInformation: FormGroup;
  // consentInformation: FormGroup;
  symptomsInformation: FormGroup;
  travelInformation: FormGroup;
  stepperSelectionTriggerEventFired = false;
  loading = false;
  loadingApplication = false;
  isEnableInstructionPageButton = true;
  addressInfo: {};
  steps = [
    'SIDEBAR.instructions',
    'SIDEBAR.personalinfo',
    'SIDEBAR.addressinfo',
    'SIDEBAR.symptoms'
  ];



  constructor(
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private router: Router,
    private apiService: ApiService,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private liveAnnouncer: LiveAnnouncer,
    private translate: TranslateService,
    public deviceDetector: DeviceDetectorService,
    private elRef: ElementRef
  ) {
    this.passengerInformation = this.formBuilder.group({});
    this.addressInformation = this.formBuilder.group({});
    this.symptomsInformation = this.formBuilder.group({});
    
  } 

  ngOnInit() {
    setPageTitle('Instructions');
    
    try {

      async () => {
        await this.loadData();
      };
    } catch (error) {
      this.snackBar.open(this.translate.instant('FOLLOW_UP.system_error'));
    }
  }

  onBeginInterview() { }

  onAddressUpdation(event) {
    this.addressInfo = event;
  }

  onStepChange(event: any) {

    this.stepError = false;
    if (event.selectedIndex === 3 && !this.addressInfo) {
      this.selectedIndex = 2;
    } else {
      this.selectedIndex = event.selectedIndex;
    }
  }

  stepperChanged(event: any, source: string) {
    this.stepError = false;

    if (this.stepperSelectionTriggerEventFired) {
      this.stepperSelectionTriggerEventFired = false;
      return;
    } else {
      this.stepperSelectionTriggerEventFired = true;
    }

    if ((source === 'content') && (this.isEnableInstructionPageButton)) {
      return;
    }
    window.scrollTo(0, 0);

    if (this.selectedIndex > event.selectedIndex) {
      this.selectedIndex = event.selectedIndex;
    }


    const index = event.selectedIndex;
    setPageTitle(this.translate.instant(this.steps[index]));
    this.stepperContent.selectedIndex = index;
    this.stepperPane.selectedIndex = index;
    if (source === 'content') {
      this.stepperPane.selectedIndex = index;
    } else {
      this.stepperContent.selectedIndex = index;
    }
  }

  checkMobileDOBValidation() {
    return new Promise((resolve, reject) => {
      const passengerFormValue = this.passengerInformation.value;
      // if (passengerFormValue.phone && passengerFormValue.dateOfBirth) {
      if (passengerFormValue.phone) {
        this.apiService
          .checkMobileDOB({
            mobileNumber: `${passengerFormValue.phoneext}${passengerFormValue.phone}`,
            dob: momentToTimestamp(moment(passengerFormValue.dateOfBirth), true),
            First_Name: passengerFormValue.firstName,
            Last_Name: passengerFormValue.lastName
          })
          .subscribe(data => {
            resolve(data['data']);
          });
      } else {
        resolve(false);
      }
    });
  }

  checkUncheckTermsCheckBox(event) {
    this.isEnableInstructionPageButton = !event.checked;
  }

  checkSymptomOption(event, val) {
    const arr = this.selectedSymptomOption;

    if (event.checked) {
      if (!_.includes(arr, val)) {
        arr.push(val);
      }
    } else {
      if (_.includes(arr, val)) {
        _.remove(arr, item => item === val);
      }
    }
    this.selectedSymptomOption = arr;
  }



  changeMobileNav(index) {
    this.mobilestep = index;
  }

  runBotDetection = function() {
    let documentDetectionKeys = [
      '__webdriver_evaluate',
      '__selenium_evaluate',
      '__webdriver_script_function',
      '__webdriver_script_func',
      '__webdriver_script_fn',
      '__fxdriver_evaluate',
      '__driver_unwrapped',
      '__webdriver_unwrapped',
      '__driver_evaluate',
      '__selenium_unwrapped',
      '__fxdriver_unwrapped'
    ];

    let windowDetectionKeys = [
      '_phantom',
      '__nightmare',
      '_selenium',
      'callPhantom',
      'callSelenium',
      '_Selenium_IDE_Recorder'
    ];

    for (const windowDetectionKey in windowDetectionKeys) {
      const windowDetectionKeyValue = windowDetectionKeys[windowDetectionKey];
      if (window[windowDetectionKeyValue]) {
        return true;
      }
    }
    for (const documentDetectionKey in documentDetectionKeys) {
      const documentDetectionKeyValue =
        documentDetectionKeys[documentDetectionKey];
      if (window['document'][documentDetectionKeyValue]) {
        return true;
      }
    }

    for (const documentKey in window['document']) {
      if (
        documentKey.match(/\$[a-z]dc_/) &&
        window['document'][documentKey]['cache_']
      ) {
        return true;
      }
    }

    if (
      window['external'] &&
      window['external'].toString() &&
      window['external'].toString()['indexOf']('Sequentum') != -1
    ) {
      return true;
    }

    if (window['document']['documentElement']['getAttribute']('selenium')) {
      return true;
    }
    if (window['document']['documentElement']['getAttribute']('webdriver')) {
      return true;
    }
    if (window['document']['documentElement']['getAttribute']('driver')) {
      return true;
    }

    return false;
  };

  checkStepValidation(seletedIndex: number) {
    const isAutomated = navigator.webdriver || this.runBotDetection();
    if (!isAutomated) {
      this.stepError = false;
      if (seletedIndex === 0) {
        const selectedSymptomOption = this.selectedSymptomOption;
        // if (_.includes(selectedSymptomOption, 'symptomOption1')) {
        // // q = 1;
        //   this.router.navigate(['/thankyou'], {queryParams: {q: 1}});
        // } else {
        //  this.stepperContent.next();
        //  this.mobilestep = 1;
        // }
        this.stepperContent.next();
        this.mobilestep = 1;
      } else if (seletedIndex === 1) {

        this.passengerInformationComponent.addFormClass();
        if (
          (this.passengerInformationComponent.form.controls.phone.errors &&
            this.passengerInformationComponent.form.controls.phone.errors
              .invalidNumber) ||
          (this.passengerInformationComponent.form.controls.alternateLandline
            .errors &&
            this.passengerInformationComponent.form.controls.alternateLandline
              .errors.invalidNumber)
        ) {
          this.stepError = 'Only numbers are allowed in phone number.';
        }
        // else if (
        //   this.passengerInformationComponent.form.controls.gender.errors &&
        //   this.passengerInformationComponent.form.controls.gender.errors.required
        // ) {
        //   if (this.stepError === false) {
        //     this.stepError = '';
        //   }
        //   this.stepError = this.stepError + 'Gender is required. ';
        // } else {
        //   //this.stepError = 'Please review the below errors';
        // }

        if (this.passengerInformationComponent.form.status == 'INVALID') {
          this.stepperContent.next();
        } else {
          this.passengerPhoneNumber = this.passengerInformationComponent.form.controls.phone.value;
          const enteredage = moment().diff(
            moment(
              this.passengerInformationComponent.form.controls.dateOfBirth
                .value
            ),
            'years'
          );

          // if (!isUnique) {
          //   this.snackBar.open(this.translate.instant('SYMPTOMS_PAGE.primary_exists'));
          // }
          
          this.mobilestep = 2;
          if (enteredage >= 18) {
            this.steps = [
              'SIDEBAR.instructions',
              'SIDEBAR.personalinfo',
              'SIDEBAR.addressinfo',
              'SIDEBAR.symptoms'         
            ];
            this.showGuardianInfo = false;
            this.total_pages = 4;
          } else if (this.passengerInformationComponent.form.controls.dateOfBirth.value != '') {
            this.steps = [
              'SIDEBAR.instructions',
              'SIDEBAR.personalinfo',
              'SIDEBAR.addressinfo',
              'Guardian Information',
              'SIDEBAR.symptoms'
            ];
            this.total_pages = 5;
            this.showGuardianInfo = true;
          }
          // this.checkMobileDOBValidation().then(isUnique => {

          // });
          this.stepperContent.next();
        }
      } else if (seletedIndex === 2) {
        this.stepError = '';

        if (
          this.addressPageComponent.form.controls.checkbox.status === 'INVALID'
        ) {
          this.stepError = this.addressPageComponent.form.controls.checkbox.errors.error;
          
          const matCheckbox = this.elRef.nativeElement.querySelector('[formcontrolname="checkbox"]');
          const invalidControl = matCheckbox.querySelector('input');
          matCheckbox.focus();
          invalidControl.focus();
        } else {
          this.mobilestep = 3;
          this.stepperContent.next();
        }
      } else if (seletedIndex === 3) {
        const age = this.passengerInformationComponent.form.controls.ageDisplay
          .value;
        if (
          age < 18 &&
          this.guardianInformationComponent.guardians.length === 0
        ) {
          this.stepError = 'Please add at least one guardian\'s information';
        } else {
          this.mobilestep = 4;
          this.stepperContent.next();
        }
      } else if (seletedIndex == 4) {

        if (this.symptomsInformationComponent.form.status != 'INVALID') {
          this.submitIntakeRequest();
        } else {
          this.symptomsInformationComponent.addFormClass();
          console.log('this.symptomsInformationComponent.form',this.symptomsInformationComponent.form);
          console.log('error occured in submitting form !',this.symptomsInformationComponent.form.status)
        }
      }
    } else {
      alert('Something went wrong, please refresh browser and try again !');
    }

  }
  done() {
    // this.router.navigateByUrl('/manage-application');
  }

  toggleLoading(toggle: boolean) {
    this.loadingApplication = toggle;
  }


  async redirectToThankYou(Symptom) {
    const selectedSymptomOption = this.selectedSymptomOption;
    let q = 1;
    if (_.includes(selectedSymptomOption, 'symptomOption1')) {
      q = 4;
    } else if (
      _.includes(selectedSymptomOption, 'symptomOption2') &&
      _.includes(selectedSymptomOption, 'symptomOption3') &&
      _.includes(selectedSymptomOption, 'symptomOption4') &&
      Symptom) {
      q = 2;
    } else if (
      _.includes(selectedSymptomOption, 'symptomOption2') &&
      _.includes(selectedSymptomOption, 'symptomOption3') &&
      _.includes(selectedSymptomOption, 'symptomOption4') &&
      !Symptom) {
      q = 3;
    } else if (
      _.includes(selectedSymptomOption, 'symptomOption2') &&
      _.includes(selectedSymptomOption, 'symptomOption4') &&
      Symptom) {
      q = 4;
    } else if (
      _.includes(selectedSymptomOption, 'symptomOption2') &&
      _.includes(selectedSymptomOption, 'symptomOption4') &&
      !Symptom) {
      q = 3;
    } else if (
      _.includes(selectedSymptomOption, 'symptomOption2') &&
      _.includes(selectedSymptomOption, 'symptomOption3') &&
      Symptom) {
      q = 4;
    } else if (
      _.includes(selectedSymptomOption, 'symptomOption2') &&
      _.includes(selectedSymptomOption, 'symptomOption3') &&
      !Symptom) {
      q = 3;
    } else if (_.includes(selectedSymptomOption, 'symptomOption2') && Symptom) {
      q = 2;
    } else if (_.includes(selectedSymptomOption, 'symptomOption2') && !Symptom) {
      q = 3;
    } else if (_.includes(selectedSymptomOption, 'symptomOption3') && Symptom) {
      q = 4;
    } else if (_.includes(selectedSymptomOption, 'symptomOption3') && !Symptom) {
      q = 5;
    } else if (_.includes(selectedSymptomOption, 'symptomOption4') && Symptom) {
      q = 6;
    } else if (_.includes(selectedSymptomOption, 'symptomOption4') && !Symptom) {
      q = 7;
    }
    this.router.navigate(['/thankyou'], {queryParams: {q}});
  }

  async submitIntakeRequest() {
    this.passengerData = {};
    this.passengerData = this.passengerInformationComponent.getData();
    this.passengerData.source = 'Self Reported';
    // this.passengerData.Disposition = 'testing';
    this.passengerData.addresses = this.addressPageComponent.getData();
    this.passengerData.is_permanent_address_same_as_current = this.addressPageComponent.checkPP;
    this.passengerData.contains_location = this.addressPageComponent.containsLocation;
    this.passengerData.guardians = this.guardianInformationComponent ? this.guardianInformationComponent.getData() : [];
    if (moment(this.symptomsInformationComponent.getData().onset_date).isValid()) {
      this.passengerData.onset_date = moment(
        this.symptomsInformationComponent.getData().onset_date
      ).format(SQL_DATE);
    }
    this.passengerData.register_vaccine = this.symptomsInformationComponent.getData().register_vaccine;
    this.passengerData.follow_Ups = this.symptomsInformationComponent.getData().followupData;
    this.passengerData.pre_conditions = this.symptomsInformationComponent.getData().preconditions;

    const age = moment().diff(moment(this.passengerData.dob), 'years');
    this.passengerData.age = age;
    // Checking symptoms
    this.passengerData.contact_with_confirmed_case =
      this.passengerData.pre_conditions[1].answer == 'yes';

    if (
      _.find(this.passengerData.follow_Ups[0].responses, { answer: 'yes', question: 'Fever ?' }) ||
      _.find(this.passengerData.follow_Ups[0].responses, { answer: 'true', question: 'Fever ?' }) ||
      _.find(this.passengerData.follow_Ups[0].responses, { answer: 'yes', question: 'Cough ?' }) ||
      _.find(this.passengerData.follow_Ups[0].responses, { answer: 'true', question: 'Cough ?' }) ||
      _.find(this.passengerData.follow_Ups[0].responses, { answer: 'yes', question: 'Difficulty breathing/Shortness of breath ?' }) ||
      _.find(this.passengerData.follow_Ups[0].responses, { answer: 'true', question: 'Difficulty breathing/Shortness of breath ?' }) ||
      _.find(this.passengerData.follow_Ups[0].responses, { answer: 'yes', question: 'Sore throat ?' }) ||
      _.find(this.passengerData.follow_Ups[0].responses, { answer: 'true', question: 'Sore throat ?' })
    ) {
      this.passengerData.symptom = true;
    } else {
      this.passengerData.symptom = false;
    }
    if (this.passengerData.contact_with_confirmed_case) {
      this.passengerData.symptom = true;
    }
    this.loadingApplication = true;
    const dob = moment(this.passengerData.dob).format(SQL_DATE);
    const end_date = moment(
      this.passengerData.MonitoringStartDate
    ).add(14, 'days').format(SQL_DATE);
    const disposition = 'Monitoring';
    const consent_stage = 'Y';
    const county = _.get(
      this,
      'passengerData.addressInformation.0.county',
      ' '
    );
    const consent = {
      value: true
    };

    // this.passengerData.County = locationaddress[0].county;

    const payloadData = {
      ...this.passengerData,
      dob,
      end_date,
      disposition,
      consent,
      consent_stage,
      county
    };
    // Explicitly setting Engaged as true.
    payloadData['engaged'] = true;

    try {
      // save passenger information
      const intakeAuthCaptcha = this.captchaResponse;

      const { data } = (await this.apiService.savePassengerInformation(
        payloadData,
        intakeAuthCaptcha
      )) as any;

      let messageType = 'INTAKE_FOLLOWUP_SYMPTOMATIC';
      if (payloadData.Symptom === false) {
        messageType = 'INTAKE_FOLLOWUP_NON_SYMPTOMATIC';
      }
      if (payloadData.register_vaccine === true && payloadData.Symptom === false) {
        messageType = 'VACCINE_REGISTRATION';
      }

      this.loadingApplication = false;
      this.submitIntake = 'step4';

      this.redirectToThankYou(payloadData.Symptom);

    } catch (error) {
      this.loadingApplication = false;
      console.log('catch_error', error);
      this.submitIntake = 'step4';
      if (error && error.error && error.error.error) {
        alert(error.error.error);
      } else if (error && error.error && error.error.message) {
        alert(error.error.message);
      } else {
        alert('error while submitting the form!');
      }
    }
  }

  async loadData() {
    try {
      // @ts-ignore
      const { data: configData } = await this.apiService.getConfigdata();
      this.localStorageService.set('countyTypes', configData['countyTypes']);
      this.localStorageService.set('timezone', configData['timezone']);
      this.localStorageService.set('genderTypes', configData['genderTypes']);
      this.localStorageService.set('raceTypes', configData['raceTypes']);
      this.localStorageService.set(
        'ethnicityTypes',
        configData['ethnicityTypes']
      );
      this.localStorageService.set('usStateTypes', configData['usStateTypes']);
      this.localStorageService.set(
        'testResultTypes',
        configData['testResultTypes']
      );
      this.localStorageService.set(
        'whichRelayTypes',
        configData['whichRelayTypes']
      );
      this.localStorageService.set(
        'transportStatusTypes',
        configData['transportStatusTypes']
      );
      this.localStorageService.set(
        'locateRelationTypes',
        configData['locateRelationTypes']
      );
      this.localStorageService.set(
        'monitoringStatusTypes',
        configData['monitoringStatusTypes']
      );
      this.localStorageService.set(
        'exposureCategoryTypes',
        configData['exposureCategoryTypes']
      );
      this.localStorageService.set(
        'simpleDateFormat',
        configData['simpleDateFormat']
      );
      this.localStorageService.set(
        'simpleTimeFormat',
        configData['simpleTimeFormat']
      );
      this.localStorageService.set(
        'simpleDatetimeFormat',
        configData['simpleDatetimeFormat']
      );
      this.localStorageService.set(
        'shortDatetimeFormat',
        configData['shortDatetimeFormat']
      );
      this.localStorageService.set(
        'messageDatetimeFormat',
        configData['messageDatetimeFormat']
      );
      this.localStorageService.set(
        'dispositionTypes',
        configData['dispositionTypes']
      );
      this.localStorageService.set(
        'guardianTypes',
        configData['guardianTypes']
      );
      this.localStorageService.set('booleanTypes', configData['booleanTypes']);
      this.localStorageService.set('addressTypes', configData['addressTypes']);
    } catch (error) {
      this.snackBar.open(this.translate.instant('FOLLOW_UP.system_error'));
    }
  }


  captchaSubmit(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (this.submitIntake == 'step4') {
      this.checkStepValidation(4);
    }
  }

  stpe4Submit() {
    this.captchRef.execute();

  }

  getAriaLabelForStepper(index) {
    let stepLabel = this.translate.instant(this.steps[index]);
    if(this.stepperContent) {
      const currentStep = this.stepperContent.steps["_results"][index];
      const selectedIndex = this.stepperContent.selectedIndex;
      if(index === selectedIndex) {
        stepLabel = `${stepLabel}, Current Tab`;
      }
      if(currentStep && currentStep.completed) {
        stepLabel = `${stepLabel}, Completed`;
      } else {
        stepLabel = `${stepLabel}`;
      }
    }
    return stepLabel;
  }

}
