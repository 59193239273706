/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./information-timeline.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./information-timeline.component";
var styles_InformationTimelineComponent = [i0.styles];
var RenderType_InformationTimelineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformationTimelineComponent, data: {} });
export { RenderType_InformationTimelineComponent as RenderType_InformationTimelineComponent };
export function View_InformationTimelineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "page-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container-lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "h1", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("TIMELINE.chi_cdph")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("TIMELINE.registration")); _ck(_v, 6, 0, currVal_1); }); }
export function View_InformationTimelineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-information-timeline", [], null, null, null, View_InformationTimelineComponent_0, RenderType_InformationTimelineComponent)), i1.ɵdid(1, 114688, null, 0, i3.InformationTimelineComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InformationTimelineComponentNgFactory = i1.ɵccf("app-information-timeline", i3.InformationTimelineComponent, View_InformationTimelineComponent_Host_0, { headerTitle: "headerTitle" }, {}, []);
export { InformationTimelineComponentNgFactory as InformationTimelineComponentNgFactory };
