import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { setPageTitle } from 'src/app/common/util';
@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {

  params = 1;

  constructor(
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = _.get(params, 'q');
      // if (!this.params) {
      //   this.router.navigateByUrl('/');
      // }
    });
  }

  ngOnInit() {
    setPageTitle('Confirmation Page')
  }


  goMainContent(event) {
    event.target.blur();
    document.getElementById('main-content-div').scroll();
    document.getElementById('main-content-div').focus();
  }

}
